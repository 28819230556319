import React, { FunctionComponent } from 'react';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const containerStyle = {
  width: '74%',
  margin: 0,
  padding: 0,
};

const inputStyle = {
  width: '100%',
  '& MuiOutlinedInput-root': {
    borderRadius: 6,
    border: 'none',
    padding: 0,
    margin: 0,
    height: 0,
  },
  '& .MuiOutlinedInput-root-MuiSelect-root': {},
};

interface Props {
  className: string;
  label: string;
  value: any;
  onChange: any;
}

const DateTimePickerInput: FunctionComponent<Props> = (props) => {
  let inputBackgroundColor: string;

  if (props.className === 'read_only_input') {
    inputBackgroundColor = '#dddddd';
  } else {
    inputBackgroundColor = '#f4f2ff';
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label={props.label}
        onChange={props.onChange}
        renderInput={(inputProps: any) => (
          <FormControl sx={{ ...containerStyle }}>
            <TextField
              {...inputProps}
              sx={{ ...inputStyle, backgroundColor: inputBackgroundColor }}
            />
          </FormControl>
        )}
        value={props.value}
      />
    </LocalizationProvider>
  );
};

export default DateTimePickerInput;
