import React, { FunctionComponent, MouseEventHandler } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { getValue } from "../../utils/utils";
import Button from "../UI/button/Button";
import "./Modal.css";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxWidth: 400,
  bgcolor: "background.paper",
  borderRadius: 5,
  border: "",
  boxShadow: 24,
  p: 4,
};

interface Action {
  httpMethod: string;
  type: string;
  url: string;
  key: string;
  header_action: boolean;
  row_action: boolean;
  name?: string;
}

interface Props {
  action: Action | null;
  actionMethod: string | undefined;
  changedData: any;
  modalState: boolean;
  actionFunction: MouseEventHandler<HTMLButtonElement>;
  onClose: MouseEventHandler<HTMLButtonElement>;
}

const ModalUI: FunctionComponent<Props> = (props) => {
  const actionDescription = (actionMethod: string | undefined) => {
    switch (actionMethod) {
      case "create":
        return "CREATE method will create a new object data inside the database";
      case "PUT":
        return "PUT request method creates a new resource or replaces a representation of the target resource with the request payload";
      case "PATCH":
        return "PATCH is a method to modify resources where the client sends partial data that is to be updated without modifying the entire data";
      case "DELETE":
        return "DELETE method delete the object data from the database";
      case "cancel":
        return "CANCEL method just remove the data but dont delete from the database";
      default:
        return '';
    }
  };

  const renderChangedData = (changedData: any): any => Object.entries(changedData).map((field: any) => {
      if (typeof field[1] === "object" && !(field[1] instanceof Date)) {
        return (
          <div>
            <p className="key_text">{`${getValue(field[0])}: {`}</p>
            {renderChangedData(field[1])}
            <p className="key_text">{`}`}</p>
          </div>
        );
      }

      return (
        <div className="property_container">
          <p className="key_text">{`${getValue(field[0])}:`}</p>
          {Array.isArray(field[1]) ? (
            <p className="value_text">{`[${getValue(field[1])}]`}</p>
          ) : (
            <p className="value_text">{`${getValue(field[1])}`}</p>
          )}
        </div>
      );
    });

  const getText = () => {
    // action types that does not send any data to the backend
    const NON_DATA_ACTIONS = ["cancel", "delete", "button_action"];
    // action types that send data to the backend
    const DATA_ACTIONS = ["create", "update"];

    /* 
    if it is a data action, it should send data to the backend, 
    so check if there is any changed data to display in the modal
    */
    if (
      props.action &&
      DATA_ACTIONS.includes(props.action.type) &&
      props.changedData &&
      Object.keys(props.changedData).length === 0
    ) {
      return `Nothing to ${props.actionMethod?.toUpperCase()}`;
    } if (
      props.action &&
      DATA_ACTIONS.includes(props.action.type) &&
      props.changedData &&
      Object.keys(props.changedData).length !== 0
    ) {
      return `${props.actionMethod?.toUpperCase()}`;
    }

    // if it its an action type that does not send any data, then display a confirmation
    if (props.action && NON_DATA_ACTIONS.includes(props.action.type)) {
      return `Are you sure you want to ${props.action?.name} the resource?`;
    }
    
    return null;
  };

  return (
    <div>
      <Modal onClose={props.onClose} open={props.modalState}>
        <Box sx={style}>
          <p className="modal_title">{getText()}</p>
          <div className="objdata_container">
            {renderChangedData(props.changedData)}
          </div>
          <div className="modal_button_container">
            {props.changedData &&
            Object.keys(props.changedData).length === 0 ? (
              <Button onClick={props.onClose} text="OK" type="add" />
            ) : (
              <>
                <Button onClick={props.onClose} text="cancel" type="delete" />
                <Button
                  onClick={props.actionFunction}
                  text="Confirm"
                  type="add"
                />
              </>
            )}
          </div>
          <p className="action_description">
            {actionDescription(props.actionMethod)}
          </p>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalUI;
