import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import { v4 as uuidv4 } from 'uuid';

import DetailField from '../../components/detailField/DetailField';
import ModalUI from '../../components/modal/ModalUI';
import AlertError from '../../components/UI/AlertError/AlertError';
import Loader from '../../components/UI/loader/Loader';
import { create } from '../../store/actions/actions';
import { getFields } from '../../store/actions/options';
import { getActionObj } from '../../utils/utils';

import './CreateView.css';

interface Action {
  httpMethod: string;
  type: string;
  url: string;
  key: string;
  json?: boolean;
  header_action: boolean;
  row_action: boolean;
}

interface Choice {
  value: string;
  display_name: string;
}

interface Field {
  type: string;
  required: boolean;
  read_only: boolean;
  label: string;
  create: boolean;
  max_length?: number;
  choices?: Choice[];
  children?: ChildrenObj;
}

interface ChildrenObj {
  [key: string]: Field;
}

interface changedDataObj {
  [key: string]: any;
}

interface FieldObj {
  [key: string]: Field;
}

interface SchemaProps {
  fields: FieldObj;
  singular_name: string;
  table_name: string;
  backend_url: string;
  actions: Action[];
}

const CreateView: FunctionComponent<SchemaProps> = (props) => {
  const [changedData, setChangedData] = useState<changedDataObj>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const createAction = getActionObj(props.actions, 'POST', 'create');
  const retrieveAction = getActionObj(props.actions, 'GET', 'retrieve');
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const idemKey: string = uuidv4();
  const { ms } = useParams();
  let ms_url: string;
  if (!ms) {
    navigate('/');
  } else {
    ms_url = ms;
  }

  // create
  const createReducer = useSelector((state: any) => state.create);
  const {
    data: elementCreated, // when create return the new object with the other properties like ID, etc.
    error: errorCreate,
    loading: loadingCreate,
    success: successCreate,
  } = createReducer;

  useEffect(() => {
    dispatch(getFields(props.backend_url));
    if (errorCreate || successCreate) {
      dispatch({ type: 'CREATE_RESET' });
    }
  }, []);

  // if a nested field (object) is required to create (POST), so create an empty object by default
  const checkNestedObjectState = (fieldFromSchema: any, key: any) => {
    if (fieldFromSchema.required) {
      if (!changedData[key] || changedData[key] === undefined) {
        setChangedData({
          ...changedData,
          [key]: {},
        });
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const transformToJson = (data: any) => {
    const new_lines_characters = data.replace(/\n/g, ' ');
    const white_space = new_lines_characters.replace(/\s+/g, '');
    const stringify = JSON.stringify(white_space);
    const jsonObj = JSON.parse(stringify);
    return jsonObj;
  };

  const createObj = () => {
    if (changedData) {
      if (createAction.json) {
        const jsonObj = transformToJson(changedData.json_text);
        dispatch(create(ms_url, createAction.url, jsonObj, idemKey));
      } else {
        dispatch(create(ms_url, createAction.url, changedData, idemKey));
      }
      setOpenModal(false);
    }
  };

  const getFieldObj = (fieldKey: any) => ({ ...props.fields[fieldKey] });

  const getFieldSubObj = (propertyName: any, fieldKey: any) => {
    const nestedProperty = getFieldObj(propertyName);
    if (nestedProperty.children) {
      return { ...nestedProperty.children[fieldKey] };
    }
    return null;
  };

  // get the onChange for each particular field, incluiding children fields in nested fields
  const getOnChangeValue = (
    nestedFieldName: string,
    type: string,
    key: string
  ) => {
    switch (type) {
      case 'string' || 'number':
        if (changedData[key] || changedData[key] === '') {
          return changedData[key];
        }
        return '';
      case 'json':
        // already stringify
        if (changedData[key] || changedData[key] === '') {
          return changedData[key];
        }
        return '{}';
      case 'choice':
        if (changedData[key]) {
          return changedData[key];
        }
        return '';
      case 'multiple_choice':
        if (changedData[key]) {
          return changedData[key];
        }
        return [];
      case 'nested_field':
        if (changedData[key]) {
          // if the user remove the value from a nested field, then make the nested object empty
          if (changedData[key][nestedFieldName] === '') {
            delete changedData[key][nestedFieldName];
            return null;
          }
          return changedData[key][nestedFieldName];
        }
        return null;
      default:
        if (changedData[key] || changedData[key] === '') {
          return changedData[key];
        }
        return '';
    }
  };

  const fieldMap = (fields: any) => {
    if (fields === undefined || fields === null) {
      return <p>ERROR UNDEFINED</p>;
    }
    return Object.keys(fields).map((key: string) => {
      const fieldFromSchema: Field = getFieldObj(key);
      const { type } = fieldFromSchema;
      const { create: isCreateField } = fieldFromSchema;

      if (isCreateField && type === 'json') {
        // in changedData state the json will be saved in a json_text property
        return (
          <DetailField
            choices={fieldFromSchema.choices}
            detailViewPage={false}
            field={key}
            isNestedField={false}
            key={key}
            label={fieldFromSchema.label}
            max_length={fieldFromSchema.max_length}
            nestedFieldPropertyName={null}
            read_only={fieldFromSchema.read_only}
            required={fieldFromSchema.required}
            setState={setChangedData}
            state={changedData}
            type={fieldFromSchema.type}
            value={getOnChangeValue('', type, key)}
          />
        );
      }

      if (isCreateField && !(type === 'nested_field')) {
        return (
          <DetailField
            choices={fieldFromSchema.choices}
            detailViewPage={false}
            field={key}
            isNestedField={false}
            key={key}
            label={fieldFromSchema.label}
            max_length={fieldFromSchema.max_length}
            nestedFieldPropertyName={null}
            read_only={fieldFromSchema.read_only}
            required={fieldFromSchema.required}
            setState={setChangedData}
            state={changedData}
            type={fieldFromSchema.type}
            value={getOnChangeValue('', type, key)}
          />
        );
      }
      // FOR NESTED PROPERTIES
      if (type === 'nested_field' && fieldFromSchema.children) {
        return (
          <div className="sub_container">
            <Divider sx={{ color: '#000' }} />
            {Object.keys(fieldFromSchema.children).map(
              (nested_field: any, index: number) => {
                // get the nested fields (children)
                const nestedFieldObj = getFieldSubObj(key, nested_field);
                checkNestedObjectState(fieldFromSchema, key);
                // return all the nested fields
                return nestedFieldObj?.create ? (
                  <>
                    {index === 0 && (
                      <p className="sub_title">{fieldFromSchema.label}</p>
                    )}
                    <DetailField
                      choices={nestedFieldObj.choices}
                      detailViewPage={false}
                      field={key}
                      isNestedField
                      key={key}
                      label={nestedFieldObj.label}
                      nestedFieldPropertyName={nested_field}
                      read_only={nestedFieldObj.read_only}
                      required={nestedFieldObj.required}
                      setState={setChangedData}
                      state={changedData}
                      type={nestedFieldObj.type}
                      value={getOnChangeValue(
                        nested_field,
                        fieldFromSchema.type,
                        key
                      )}
                    />
                  </>
                ) : null;
              }
            )}
            <Divider sx={{ color: '#000' }} />
          </div>
        );
      }

      return null;
    });
  };

  return (
    <div className="create_view">
      {loadingCreate && <Loader />}
      {errorCreate && <AlertError errorData={errorCreate} />}
      {successCreate && <Alert severity="success">Success create!</Alert>}
      {openModal && (
        <ModalUI
          action={null}
          actionFunction={createObj}
          actionMethod="create"
          changedData={{ ...changedData }}
          modalState={openModal}
          onClose={handleCloseModal}
        />
      )}
      <div className="top_container">
        <button
          className="icon_button_detailview"
          onClick={() => navigate(`../`)}
        >
          <ArrowBackIosIcon sx={{ fontSize: 20, color: '#6E6893' }} />
          <p className="go_back_text">{`Go back to ${props.table_name}`}</p>
        </button>
        <div className="title_top_container">
          <p className="title_top">
            {`CREATE A ${props.singular_name.toUpperCase()}`}
          </p>
          <div className="field_button_container">
            {successCreate && (
              <button
                className="field_create_button"
                onClick={() => {
                  const key = elementCreated[retrieveAction.key];
                  navigate(`../${key}`, { replace: true });
                }}
              >
                EDIT
              </button>
            )}
          </div>
        </div>
      </div>
      <Divider sx={{ color: '#000' }} />
      <div className="scroll_container">
        <div className="field_flex_column">
          {fieldMap(props.fields)}
          {!successCreate && (
            <div className="field_button_container">
              <button
                className="field_create_button"
                onClick={() => setOpenModal(true)}
              >
                CREATE
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateView;
