import React, { FunctionComponent } from "react";

import NoItems from "../../svg/noItems.svg";
import FilterSvg from "../../svg/search.svg";

import "./FilterWarning.css";

interface Props {
  filter: boolean;
  text: string;
}

const FilterWarning: FunctionComponent<Props> = (props) => {
  let svg;

  if (props.filter) {
    svg = FilterSvg;
  } else {
    svg = NoItems;
  }

  return (
    <div className="filter_warning_component">
      <div className="img_container">
        <img alt="search" className="filter_warning_img" src={svg} />
      </div>
      <h3 className="filter_warning_text">{props.text}</h3>
    </div>
  );
};

export default FilterWarning;
