import React, { FunctionComponent } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import './FilterDropDown.css';

interface Action {
  httpMethod: string;
  type: string;
  url: string;
  end_url?: string;
  key: string;
  header_action: boolean;
  row_action: boolean;
}

interface Filters {
  field: string;
  type: string;
  action?: Action;
}

interface Props {
  state: any;
  onChange: any;
  filters: Filters[];
}

const FilterDropDown: FunctionComponent<Props> = (props) => (
  <div>
    <FormControl
      size="small"
      sx={{
        m: 1,
        minWidth: 100,
        justifyContent: 'center',
        textAlign: 'center',
        height: 30,
      }}
    >
      <InputLabel className="MuiInputLabel-root" id="demo-select-small">
        Filter by
      </InputLabel>
      <Select
        autoWidth
        label="Filter By"
        onChange={props.onChange}
        sx={{ height: 30, backgroundColor: '#f4f2ff' }}
        value={props.state ? props.state : props.filters[0].field}
      >
        {props.filters.map((filterObj) => (
          <MenuItem key={filterObj.field} value={filterObj.field}>
            {filterObj.field}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

export default FilterDropDown;
