export const LIST_REQUEST = 'LIST_REQUEST';
export const LIST_SUCCESS = 'LIST_SUCCESS';
export const LIST_FAIL = 'LIST_FAIL';

export const LIST_DEPOSITS_REQUEST = 'LIST_DEPOSITS_REQUEST';
export const LIST_DEPOSITS_SUCCESS = 'LIST_DEPOSITS_SUCCESS';
export const LIST_DEPOSITS_FAIL = 'LIST_DEPOSITS_FAIL';

export const LIST_BALANCES_REQUEST = 'LIST_BALANCES_REQUEST';
export const LIST_BALANCES_SUCCESS = 'LIST_BALANCES_SUCCESS';
export const LIST_BALANCES_FAIL = 'LIST_BALANCES_FAIL';

export const GET_BY_ID_REQUEST = 'GET_BY_ID_REQUEST';
export const GET_BY_ID_SUCCESS = 'GET_BY_ID_SUCCESS';
export const GET_BY_ID_FAIL = 'GET_BY_ID_FAIL';

export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAIL = 'UPDATE_FAIL';
export const UPDATE_RESET = 'UPDATE_RESET';

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';
export const CREATE_RESET = 'CREATE_RESET';

export const CANCEL_REQUEST = 'CANCEL_REQUEST';
export const CANCEL_SUCCESS = 'CANCEL_SUCCESS';
export const CANCEL_FAIL = 'CANCEL_FAIL';

export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAIL = 'DELETE_FAIL';

export const FIELD_ACTION_REQUEST = 'FIELD_ACTION_REQUEST';
export const FIELD_ACTION_SUCCESS = 'FIELD_ACTION_SUCCESS';
export const FIELD_ACTION_FAIL = 'FIELD_ACTION_FAIL';
export const FIELD_ACTION_RESET = 'FIELD_ACTION_RESET';

export const BUTTON_ACTION_REQUEST = 'BUTTON_ACTION_REQUEST';
export const BUTTON_ACTION_SUCCESS = 'BUTTON_ACTION_SUCCESS';
export const BUTTON_ACTION_FAIL = 'BUTTON_ACTION_FAIL';
export const BUTTON_ACTION_RESET = 'BUTTON_ACTION_RESET';
