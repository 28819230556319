import { GET_TABLE_SUCCESS, GET_TABLES_SUCCESS } from '../../constants/tables';
import { BROKER_METADATA, BROKER_TABLES } from '../../data/broker';
import { CARBON_METADATA, CARBON_TABLES } from '../../data/carbon';
import { COMPUTE_METADATA, COMPUTE_TABLES } from '../../data/compute';
import { MOBILE_METADATA, MOBILE_TABLES } from '../../data/mobile';

// ----------------------- GET MICROSERVICES ------------------------------
export const getBrokerMicroserviceTables = () => async (dispatch: any) => {
  // fetch all the data for the product
  dispatch({
    type: GET_TABLES_SUCCESS,
    payload: { tables: BROKER_TABLES, meta_data: BROKER_METADATA },
  });
};

export const getComputeTables = () => async (dispatch: any) => {
  // fetch all the data for the product
  dispatch({
    type: GET_TABLES_SUCCESS,
    payload: { tables: COMPUTE_TABLES, meta_data: COMPUTE_METADATA },
  });
};

export const getCarbonTables = () => async (dispatch: any) => {
  // fetch all the data for the product
  dispatch({
    type: GET_TABLES_SUCCESS,
    payload: { tables: CARBON_TABLES, meta_data: CARBON_METADATA },
  });
};

export const getMobileTables = () => async (dispatch: any) => {
  // fetch all the data for the product
  dispatch({
    type: GET_TABLES_SUCCESS,
    payload: { tables: MOBILE_TABLES, meta_data: MOBILE_METADATA },
  });
};

// ----------------------- GET PARTICULAR TABLE ------------------------------
export const getTableFromSchema =
  (tableName: string) => async (dispatch: any) => {
    // fetch all the data for the product
    dispatch({
      type: GET_TABLE_SUCCESS,
      payload: tableName, // "identifiers" for example
    });
  };
