import axios from 'axios';

import { LIST_FAIL, LIST_REQUEST, LIST_SUCCESS } from '../../constants/actions';

const BACKEND_DOMAIN: string | undefined = process.env.REACT_APP_BACKEND_DOMAIN;

// for deposits, orders, balances and transactions
export const listBy =
  (backend_url: string, id: string, end_url: string) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: LIST_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config: any = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_DOMAIN}/${backend_url}${id}${end_url}`,
        config
      );

      dispatch({
        type: LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: LIST_FAIL,
        payload: error.response,
      });
    }
  };
