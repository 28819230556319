import React, { FunctionComponent } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import './SideBar.css';

interface Props {
  tables: any[];
}

const SideBar: FunctionComponent<Props> = (props) => {
  const { ms } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const path: string = location.pathname;
  let ms_url = '';

  if (!ms) {
    navigate('/');
  } else {
    ms_url = ms;
  }

  return (
    <Drawer
      sx={{
        width: 260,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 260,
          boxSizing: 'border-box',
          height: '91.5%',
          position: 'absolute',
        },
      }}
      variant="permanent"
    >
      <Box
        sx={{
          overflow: 'auto',
          padding: 2,
        }}
      >
        <div className="title_sidebar_container">
          <button className="go_back_container" onClick={() => navigate(`/`)}>
            <ArrowBackIosIcon sx={{ fontSize: 20, color: '#6640ff' }} />
            <p className="go_back_p">Go back to microservices </p>
          </button>
          <p className="title_sidebar" onClick={() => navigate('')}>{`${
            ms_url[0].toUpperCase() + ms_url.substring(1)
          } Microservice`}</p>
        </div>
        <List>
          {props.tables.map((table) => (
            <ListItem disablePadding key={table.title}>
              <NavLink
                className="navLink"
                to={table.path.replace(/\s+/g, '-').toLowerCase()}
              >
                <ListItemButton
                  selected={path === `/${ms_url}/dashboard/${table.path}`}
                  sx={{
                    borderRadius: 2,
                    [`& .Mui-selected`]: {
                      backgroundColor: 'rgba(102, 64, 255, 1)',
                    },
                  }}
                >
                  <ListItemText
                    primary={table.title}
                    sx={{
                      color:
                        path === `/dashboard/${table.path}`
                          ? '#6640FF'
                          : '#000',
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideBar;
