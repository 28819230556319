import React, { FunctionComponent } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const containerStyle = {
  width: '74%',
  margin: 0,
  padding: 0,
};

const inputStyle = {
  width: '100%',
  padding: 2.5,
  height: 20,
  '& MuiOutlinedInput-root': {
    borderRadius: 6,
    border: 'none',
    padding: 0,
    margin: 0,
    height: 0,
  },
  '& .MuiOutlinedInput-root-MuiSelect-root': {},
};

interface Choice {
  value: string;
  display_name: string;
}

interface Props {
  className: string;
  onChange: any;
  value: any;
  choices: Choice[];
}

const SelectInput: FunctionComponent<Props> = (props) => {
  let inputBackgroundColor;

  if (props.className === 'read_only_input') {
    inputBackgroundColor = '#dddddd';
  } else {
    inputBackgroundColor = '#f4f2ff';
  }

  return (
    <FormControl size='small' sx={containerStyle}>
      <InputLabel id='demo-select-small'>Select</InputLabel>
      <Select
        label='Select'
        onChange={props.onChange}
        sx={{ ...inputStyle, backgroundColor: inputBackgroundColor }}
        value={props.value}
      >
        <MenuItem value='None'>
          <em>None</em>
        </MenuItem>
        {props.choices.map((choice: Choice) => (
          <MenuItem key={choice.value} value={choice.value}>
            {choice.display_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
