import React, { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import './Button.css';

interface Props {
  text: string;
  type: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon?: ReactNode | undefined;
}

const Button: FunctionComponent<Props> = (props) => {
  let buttonStyle = '';

  if (props.type === 'add') {
    buttonStyle = 'add_button';
  } else if (props.type === 'delete') {
    buttonStyle = 'delete_button';
  } else if (props.type === 'filter') {
    buttonStyle = 'filter_button';
  }

  return (
    <div className='button_component'>
      <button className={buttonStyle} onClick={props.onClick}>
        {props.icon && <div className='icon_container'>{props.icon}</div>}
        {props.text}
      </button>
    </div>
  );
};

export default Button;
