import React, { FunctionComponent } from 'react';
import './Loader.css';

const Spinner: FunctionComponent = () => (
    <div className='loaderContainer'>
      <div className='lds-ellipsis'>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );

export default Spinner;
