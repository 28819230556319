interface Action {
  json?: boolean;
  httpMethod: string;
  type: string;
  url: string;
  end_url?: string;
  key: string;
  header_action: boolean;
  row_action: boolean;
}

export const getActionObj = (
  actions: Action[],
  method: string,
  type: string
): Action => {
  const [action] = actions.filter((act: any) => {
    if (act.httpMethod === method && act.type === type) {
      return act;
    }
    return null;
  });
  return action;
};

export const hasTheAction = (
  actions: Action[],
  method: string,
  type: string
): boolean => {
  const action = actions.find(
    (act: Action) => act.httpMethod === method && act.type === type
  );
  if (action) {
    return true;
  }
  return false;
};

/* RETURN EXAMPLE
      create: true
      label: "Is passport"
      read_only: false
      required: false
      type: "boolean"
      */
export const getFieldObj = (fields: any, fieldKey: any) => ({
  ...fields[fieldKey],
});

export const isRowOrHeaderAction = (
  actions: Action[],
  actionType: string, // header_action or row_action
  action: string
) => {
  const hasRowAction = actions.find(
    (actObj: any) => actObj.type === action && actObj[actionType]
  );
  if (hasRowAction) {
    return true;
  }
  return false;
};

// remove "_" and capitalize
export const humanize = (str: string): string => {
  const frags = str.split('_');
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};

// get the value to represent them as a string or number in the table
export const getValue = (
  value: string | number | boolean | null | undefined | any[]
): string | number | null | any[] => {
  if (typeof value === 'boolean') {
    if (value) return 'True';
    return 'False';
  }
  if (value === null) {
    return null;
  }
  if (value === 'null' || value === 'Null') {
    return null;
  }
  if (value === undefined) {
    return 'undefined';
  }
  if (value === '') {
    return 'undefined';
  }
  if (!value) {
    return 'undefined';
  }
  return value;
};
