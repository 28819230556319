/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import { v4 as uuidv4 } from 'uuid';

import DetailField from '../../components/detailField/DetailField';
import FieldAction from '../../components/fieldAction/FieldAction';
import ModalUI from '../../components/modal/ModalUI';
import ObjectCard from '../../components/objectCard/ObjectCard';
import AlertError from '../../components/UI/AlertError/AlertError';
import Loader from '../../components/UI/loader/Loader';
import {
  buttonAction,
  cancelById,
  deleteById,
  getById,
  update,
} from '../../store/actions/actions';
import { getActionObj, getFieldObj } from '../../utils/utils';
import './DetailView.css';

interface Action {
  httpMethod: string;
  type: string;
  url: string;
  key: string;
  header_action: boolean;
  row_action: boolean;
  name?: string;
  end_url?: string;
}

interface Choice {
  value: string;
  display_name: string;
}

interface Field {
  type: string;
  required: boolean;
  read_only: boolean;
  label: string;
  create: boolean;
  max_length?: number;
  choices?: Choice[];
  children?: ChildrenObj;
}

interface ChildrenObj {
  [key: string]: Field;
}

interface ChangedData {
  [key: string]: any;
}

interface FieldObj {
  [key: string]: Field;
}

interface SchemaProps {
  fields: FieldObj;
  singular_name: string;
  table_name: string;
  actions: Action[];
}

const DetailView: FunctionComponent<SchemaProps> = (props) => {
  const [changedData, setChangedData] = useState<ChangedData>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [actionObj, setActionObj] = useState<any>({});

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { ms, key } = useParams();
  let ms_url: string;
  if (!ms) {
    navigate('/');
  } else {
    ms_url = ms;
  }

  const BUTTON_ACTIONS = props.actions.filter(
    (act: any) => act.type === 'button_action'
  );

  const UPDATE_ACTIONS = props.actions.filter(
    (act: any) => act.type === 'update'
  );

  const FIELD_ACTIONS = props.actions.filter(
    (act: any) => act.type === 'action'
  );

  const retrieveAction = getActionObj(props.actions, 'GET', 'retrieve');

  // get by ID
  const elementById = useSelector((state: any) => state.elementById);
  const { data, loading: loadingGet, error: errorGet } = elementById;

  // update
  const updateReducer = useSelector((state: any) => state.update);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = updateReducer;

  // cancel
  const cancelReducer = useSelector((state: any) => state.cancel);
  const {
    error: errorCancel,
    loading: loadingCancel,
    success: successCancel,
  } = cancelReducer;

  // delete
  const deleteReducer = useSelector((state: any) => state.delete);
  const {
    error: errorDelete,
    loading: loadingDelete,
    success: successDelete,
  } = deleteReducer;

  const buttonActionReducer = useSelector((state: any) => state.buttonAction);
  const {
    error: errorButtonAction,
    loading: loadingButtonAction,
    success: successButtonAction,
  } = buttonActionReducer;

  const fieldActionReducer = useSelector((state: any) => state.fieldAction);
  const { error: errorFieldAction, success: successFieldAction } =
    fieldActionReducer;

  useEffect(() => {
    if ((key && !data) || (data && data.id !== key)) {
      dispatch(getById(ms_url, retrieveAction.url, key));
    }
    if (successButtonAction || successFieldAction) {
      dispatch(getById(ms_url, retrieveAction.url, key));
    }
  }, [dispatch, key, successButtonAction, successFieldAction]);

  // manage render after actions
  useEffect(() => {
    if (successUpdate || errorUpdate) {
      dispatch({ type: 'UPDATE_RESET' });
      setChangedData({});
    }

    if (successDelete || errorDelete) {
      dispatch({ type: 'ERROR_RESET' });
    }

    if (successButtonAction || errorButtonAction) {
      dispatch({ type: 'BUTTON_ACTION_RESET' });
    }
    if (successFieldAction || errorFieldAction) {
      dispatch({ type: 'FIELD_ACTION_RESET' });
    }
  }, []);

  const handleGoBackNavigation = () => navigate(`../`);

  const transformToJson = (responseData: any) => {
    const new_lines_characters = responseData.replace(/\n/g, ' ');
    const white_space = new_lines_characters.replace(/\s+/g, '');
    const stringify = JSON.stringify(white_space);
    const jsonObj = JSON.parse(stringify);
    return jsonObj;
  };

  const updateObj = (method: string) => {
    // method could be PATCH or PUT
    const action = getActionObj(props.actions, method, 'update');
    if (changedData && key && method) {
      if (action.json) {
        const jsonObj = transformToJson(changedData.json_text);
        dispatch(update(ms_url, action.url, key, jsonObj, method));
      } else {
        dispatch(update(ms_url, action.url, key, changedData, method));
      }
      setOpenModal(false);
    }
  };

  const cancelObj = () => {
    const action = getActionObj(props.actions, 'POST', 'cancel');
    if (key) {
      dispatch(cancelById(ms_url, action.url, key, action.end_url));
      setOpenModal(false);
      handleGoBackNavigation()
    }
  };

  const deleteObj = () => {
    const action = getActionObj(props.actions, 'DELETE', 'delete');
    if (key) {
      dispatch(deleteById(ms_url, action.url, key));
      setOpenModal(false);
      handleGoBackNavigation()
    }
  };

  const handleButtonAction = () => {
    const action = getActionObj(props.actions, 'POST', 'button_action');
    if (key && action.end_url) {
      dispatch(
        buttonAction(ms_url, action.url, key, action.end_url, action.httpMethod)
      );
      setOpenModal(false);
    }
  };

  // check by method or type
  const checkAction = (method: string | undefined, type: string) => {
    if (type === 'button_actions') {
      return handleButtonAction();
    }
    if (type === 'cancel') {
      return cancelObj();
    }
    switch (method) {
      case 'PATCH':
        return updateObj(method);
      case 'PUT':
        return updateObj(method);
      case 'DELETE':
        return deleteObj();
      default:
        return null;
    }
  };

  const getChangedData = (action: Action) => {
    if (
      action.type === 'delete' ||
      action.type === 'cancel' ||
      action.type === 'button_action'
    ) {
      return false;
    }
    return { ...changedData };
  };

  const getFieldSubObj = (propertyName: any, fieldKey: any) => {
    const nestedProperty = getFieldObj(props.fields, propertyName);
    return { ...nestedProperty.children[fieldKey] };
  };

  // get on change value for each field from the state or return the response value
  const getOnChangeValue = (
    nestedFieldName: string,
    type: string,
    property: string
  ) => {
    switch (type) {
      case 'json':
        // if the user change something the data will be saved as json_text
        if (changedData[property]) {
          return changedData[property];
        }
        return JSON.stringify(data, undefined, 4);
      case 'multiple_choice':
        if (changedData[property]) {
          return changedData[property];
        }
        return [...data[property]];
      case 'nested_field':
        if (changedData[property]) {
          // if the user remove the value from a nested field, then make the nested object empty
          if (changedData[property][nestedFieldName] === '') {
            delete changedData[property][nestedFieldName];
            return null;
          }
          return changedData[property][nestedFieldName];
        }
        return data[property][nestedFieldName];

      default:
        if (changedData[property]) {
          return changedData[property];
        }
        return data[property];
    }
  };

  const objMap = (obj: object) => {
    const FIELDS = ['string', 'number', 'boolean', 'datetime', 'textarea'];

    if (obj === undefined) {
      return <p>ERROR</p>;
    }

    // FOR JSON INPUTS
    const jsonFormat: Field = getFieldObj(props.fields, 'json_text');
    if (jsonFormat && jsonFormat.type === 'json') {
      return (
        <DetailField
          choices={jsonFormat.choices}
          detailViewPage
          field="json_text"
          isNestedField={false}
          key="json_text"
          label={jsonFormat.label}
          nestedFieldPropertyName={null}
          read_only={jsonFormat.read_only}
          required={jsonFormat.required}
          setState={setChangedData}
          state={changedData}
          type={jsonFormat.type}
          value={getOnChangeValue('', jsonFormat.type, 'json_text')}
        />
      );
    }

    return Object.keys(obj).map((propertyKey: string) => {
      const fieldFromSchema: Field = getFieldObj(props.fields, propertyKey);
      const { type } = fieldFromSchema;
      // FOR NORMAL FIELDS
      if (
        !(data[propertyKey] instanceof Array) &&
        FIELDS.includes(fieldFromSchema.type)
      ) {
        return (
          <DetailField
            choices={fieldFromSchema.choices}
            detailViewPage
            field={propertyKey}
            isNestedField={false}
            key={propertyKey}
            label={fieldFromSchema.label}
            nestedFieldPropertyName={null}
            read_only={fieldFromSchema.read_only}
            required={fieldFromSchema.required}
            setState={setChangedData}
            state={changedData}
            type={type}
            value={getOnChangeValue('', type, propertyKey)}
          />
        );
      }

      // MULTIPLE CHOICE
      if (type === 'multiple_choice' || type === 'choice') {
        return (
          <DetailField
            choices={fieldFromSchema.choices}
            detailViewPage
            field={propertyKey}
            isNestedField={false}
            key={propertyKey}
            label={fieldFromSchema.label}
            nestedFieldPropertyName={null}
            read_only={fieldFromSchema.read_only}
            required={fieldFromSchema.required}
            setState={setChangedData}
            state={changedData}
            type={type}
            value={getOnChangeValue('', type, propertyKey)}
          />
        );
      }

      // FOR NESTED OBJECT PROPERTIES
      if (type === 'nested_field' && fieldFromSchema.children) {
        return (
          <div className="sub_container" key={uuidv4()}>
            <Divider sx={{ color: '#000' }} />
            {Object.keys(fieldFromSchema.children).map(
              (nested_field: any, index: number) => {
                const nestedFieldObj = getFieldSubObj(
                  propertyKey,
                  nested_field
                );
                return (
                  <>
                    {index === 0 && (
                      <p className="sub_title" key={uuidv4()}>
                        {fieldFromSchema.label}
                      </p>
                    )}
                    <DetailField
                      choices={nestedFieldObj.choices}
                      detailViewPage
                      field={propertyKey}
                      isNestedField
                      key={propertyKey}
                      label={nestedFieldObj.label}
                      nestedFieldPropertyName={nested_field}
                      read_only={nestedFieldObj.read_only}
                      required={nestedFieldObj.required}
                      setState={setChangedData}
                      state={changedData}
                      type={nestedFieldObj.type}
                      value={getOnChangeValue(
                        nested_field,
                        fieldFromSchema.type,
                        propertyKey
                      )}
                    />
                  </>
                );
              }
            )}
            <Divider sx={{ color: '#000' }} />
          </div>
        );
      }

      // FOR NESTED SERIALIZERS
      if (type === 'nested_object') {
        // Some nested object responses from the backend are just an object and other an array of objects
        if (
          !data[propertyKey] ||
          data[propertyKey] === null ||
          Object.keys(data[propertyKey]).length === 0
        ) {
          return (
            <div className="sub_container" key={propertyKey}>
              <Divider sx={{ color: '#000' }} />
              <p className="sub_title">{fieldFromSchema.label}</p>
              <p>Empty</p>
            </div>
          );
        }

        if (data[propertyKey] instanceof Array) {
          return (
            <div className="sub_container" key={propertyKey}>
              <Divider sx={{ color: '#000' }} />
              <p className="sub_title">{fieldFromSchema.label}</p>
              {data[propertyKey].map((nestedObj: any) => (
                <ObjectCard
                  data={nestedObj}
                  fieldFromSchema={fieldFromSchema}
                  key={propertyKey}
                />
              ))}
            </div>
          );
        }
        return (
          <div className="sub_container" key={propertyKey}>
            <Divider sx={{ color: '#000' }} />
            <p className="sub_title">{fieldFromSchema.label}</p>
            {[data[propertyKey]].map((nestedObj: any) => (
              <ObjectCard
                data={nestedObj}
                fieldFromSchema={fieldFromSchema}
                key={propertyKey}
              />
            ))}
          </div>
        );
      }

      return null;
    });
  };

  return (
    <div className="detailview_page">
      {errorGet && <AlertError errorData={errorGet} />}
      {errorUpdate && <AlertError errorData={errorUpdate} />}
      {successUpdate && <Alert severity="success">Success update!</Alert>}
      {errorCancel && <AlertError errorData={errorCancel} />}
      {successCancel && <Alert severity="success">Success cancel!</Alert>}
      {errorDelete && <AlertError errorData={errorDelete} />}
      {errorButtonAction && <AlertError errorData={errorButtonAction} />}
      {successDelete && <Alert severity="success">Success delete!</Alert>}
      {successButtonAction && <Alert severity="success">Success!</Alert>}
      {loadingGet && <Loader />}
      {loadingUpdate && <Loader />}
      {loadingCancel && <Loader />}
      {loadingDelete && <Loader />}
      {loadingButtonAction && <Loader />}
      {openModal && (
        <ModalUI
          action={actionObj}
          actionFunction={() =>
            checkAction(actionObj.httpMethod, actionObj.type)
          }
          actionMethod={actionObj.httpMethod}
          changedData={getChangedData(actionObj)}
          modalState={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
      <div className="top_container">
        <button
          className="icon_button_detailview"
          onClick={handleGoBackNavigation}
        >
          <ArrowBackIosIcon sx={{ fontSize: 20, color: '#6E6893' }} />
          <p className="go_back_text">{`Go back to ${props.table_name}`}</p>
        </button>
        <p className="title_top">
          {`${props.singular_name.toUpperCase()} : ${key}`}
        </p>
      </div>
      <Divider sx={{ color: '#000' }} />
      <div className="scroll_container">
        <div className="field_flex_column">
          {objMap(data)}
          <div className="actions_button_container">
            {BUTTON_ACTIONS.map((action: Action) => (
              <button
                className="button_action"
                key={uuidv4()}
                onClick={() => {
                  setActionObj(action);
                  setOpenModal(true);
                }}
              >
                {`${action.name?.toUpperCase()}`}
              </button>
            ))}
            {UPDATE_ACTIONS.map((action: Action) => (
              <button
                className="action_update_button"
                key={uuidv4()}
                onClick={() => {
                  setActionObj(action);
                  setOpenModal(true);
                }}
              >
                {`${action.httpMethod.toUpperCase()} UPDATE`}
              </button>
            ))}
          </div>
        </div>
        {FIELD_ACTIONS.length > 0 && (
          <FieldAction FIELD_ACTIONS={FIELD_ACTIONS} id={key} />
        )}
        {props.actions.find(
          (a: Action) => a.type === 'cancel' || a.type === 'delete'
        ) && (
          <>
            <Divider sx={{ color: '#000' }} />
            <div className="dangerzone_container">
              <p className="dangerzone_title">DANGER ZONE</p>
              <div className="dangerzone_card_container">
                {props.actions.map((action: Action) => {
                  if (action.type === 'delete') {
                    return (
                      <div className="dangerzone_card">
                        <div className="dangerzone_column">
                          <p className="dangerzone_card_title">
                            Delete this data structure
                          </p>
                          <p className="dangerzone_card_description">
                            Unix-like operating systems identify a user by a
                            value called a user identifier, often access.{' '}
                          </p>
                        </div>
                        <button
                          className="dangerzone_button"
                          onClick={() => {
                            setOpenModal(true);
                            setActionObj(action);
                          }}
                        >
                          DELETE
                        </button>
                      </div>
                    );
                  }

                  if (action.type === 'cancel') {
                    return (
                      <div className="dangerzone_card">
                        <div className="dangerzone_column">
                          <p className="dangerzone_card_title">
                            Cancel this data structure
                          </p>
                          <p className="dangerzone_card_description">
                            Unix-like operating systems identify a user by a
                            value called a user identifier, often access.{' '}
                          </p>
                        </div>
                        <button
                          className="dangerzone_button"
                          onClick={() => {
                            setOpenModal(true);
                            setActionObj(action);
                          }}
                        >
                          CANCEL
                        </button>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DetailView;
