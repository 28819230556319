import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import './PageNotFound.css';

const PageNotFound: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div className="not_found_page">
      <div className="not_found_page_card">
        <div className="not_found_page_title_container">
          <p>Error 404: Page not found</p>
        </div>
        <div className="not_found_page_text_container">
          <p>
            Apparently you are trying to access a page that does not exist. If
            you think this is something that should not happen, please contact
            the IT manager
          </p>
        </div>
        <button
          className="not_found_button_container"
          onClick={() => navigate('/')}
        >
          <p>Go to the home page</p>
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
