import {
  GET_FIELDS_FAIL,
  GET_FIELDS_REQUEST,
  GET_FIELDS_SUCCESS,
} from '../../constants/options';

export const getFieldsReducer = (state = { data: [] }, action: any) => {
  switch (action.type) {
    case GET_FIELDS_REQUEST:
      return {
        loading: true,
        data: [],
      };
    case GET_FIELDS_SUCCESS:
      return {
        loading: false,
        data: action.payload.actions.POST,
      };
    case GET_FIELDS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
