import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import ProtectedRoute from './auth/protectedRoute';
import Backdrop from './components/backdrop/Backdrop';
import Dashboard from './pages/dashboard/Dashboard';
import Home from './pages/home/Home';
import PageNotFound from './pages/404/PageNotFound';

const App: FunctionComponent = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Backdrop />;
  }

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
        path="/"
      />
      <Route
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
        path=":ms/dashboard/*"
      />
      <Route element={<PageNotFound />} path="*" />
    </Routes>
  );
};

export default App;
