import {
  GET_TABLES_FAIL,
  GET_TABLES_REQUEST,
  GET_TABLES_SUCCESS,
} from '../../constants/tables';

export const schemaReducer = (
  state = { schemaTables: [], meta_data: {} },
  action: any
) => {
  switch (action.type) {
    case GET_TABLES_REQUEST:
      return {
        loading: true,
        schemaTables: [],
      };
    case GET_TABLES_SUCCESS:
      return {
        schemaTables: [...action.payload.tables],
        meta_data: { ...action.payload.meta_data },
      };
    case GET_TABLES_FAIL:
      return {
        loading: false,
        schemaTables: [],
      };

    default:
      return state;
  }
};
