import carbon_svg from '../svg/carbon.svg';

export const CARBON_METADATA = {
  image: carbon_svg,
  name: 'carbon',
  title: 'Carbon Microservice',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc consectetur, libero sit amet bibendum ultrices, odio nunc luctus lacus, ut commodo mauris eros eu velit. Cras mi ante, ullamcorper eget elit vel, varius efficitur est. Donec ut nibh sem. Quisque condimentum pulvinar quam non scelerisque. Etiam hendrerit odio in leo cursus sollicitudin. Nam fringilla, mi eu finibus luctus, ligula lectus bibendum neque, sed consectetur nulla magna non elit. ',
};

export const CARBON_TABLES = [
  {
    name: 'users',
    title: 'Users',
    singular_name: 'user',
    path: 'users',
    backend_url: 'userauth/users/',
    table_columns: ['id', 'email', 'auth0_app_id', 'is_staff'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'user',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'userauth/users/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'userauth/users/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'add',
        default_value: 'Group name',
        input_key: 'group_name',
        input_label: 'Add to group',
        url: 'userauth/users/',
        end_url: 'actions/add-to-group/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'remove',
        default_value: 'Group name',
        input_key: 'group_name',
        input_label: 'Remove from group',
        url: 'userauth/users/',
        end_url: 'actions/remove-from-group/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Id',
        create: false,
        max_length: 255,
      },
      auth0_id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Auth0 id',
        create: false,
        max_length: 255,
      },
      auth0_app_id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Auth0 app id',
        create: false,
        max_length: 255,
      },
      first_name: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Name',
        create: false,
        max_length: 255,
      },
      last_name: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Lastname',
        create: false,
        max_length: 255,
      },
      email: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Email',
        create: false,
        max_length: 255,
      },
      is_superuser: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is superuser',
        create: false,
        max_length: 255,
      },
      is_staff: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is staff',
        create: false,
        max_length: 255,
      },
      is_active: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is active',
        create: false,
        max_length: 255,
      },
      is_approved: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is approved',
        create: false,
        max_length: 255,
      },
      date_joined: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Date joined',
        create: false,
        max_length: 255,
      },
      groups: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Groups',
        table_name: 'groups',
        singular_name: 'group',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'groups/',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
        },
      },
      permissions: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Permissions',
        table_name: 'users',
        singular_name: 'Permission',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: false,
            url: '',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
          content_type: {
            type: 'string',
            label: 'Content type',
            pk: false,
            url: '',
          },
        },
      },
    },
  },
  {
    name: 'groups',
    title: 'Groups',
    singular_name: 'group',
    path: 'groups',
    backend_url: 'userauth/groups/',
    table_columns: ['id', 'name', 'undefined', 'undefined'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'group',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'userauth/groups/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'userauth/groups/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'add',
        default_value: 'User ID',
        input_key: 'user_id',
        input_label: 'Add user',
        url: 'userauth/groups/',
        end_url: 'actions/add-user/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'remove',
        default_value: 'User ID',
        input_key: 'user_id',
        input_label: 'Remove user',
        url: 'userauth/groups/',
        end_url: 'actions/remove-user/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Id',
        create: false,
        max_length: 255,
      },
      name: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Name',
        create: false,
        max_length: 255,
      },
      permissions: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Permissions',
        table_name: 'groups',
        singular_name: 'Permission',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: false,
            url: '',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
          content_type: {
            type: 'string',
            label: 'Content type',
            pk: false,
            url: '',
          },
        },
      },
    },
  },
  {
    name: 'accounts',
    title: 'Accounts',
    singular_name: 'account',
    path: 'accounts',
    backend_url: 'internal/accounts/',
    table_columns: ['id', 'null', 'name', 'null'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'internal/accounts/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'internal/accounts/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'internal/accounts/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'internal/accounts/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'internal/accounts/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Id',
      },
      name: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Name',
        max_length: 255,
      },
      created_at: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Created at',
      },
    },
  },
  {
    name: 'projects',
    title: 'Projects',
    singular_name: 'project',
    path: 'projects',
    backend_url: 'internal/projects/',
    table_columns: ['id', 'name', 'symbol', 'registry_id'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'internal/projects/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'internal/projects/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'internal/projects/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'internal/projects/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'internal/projects/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Id',
      },
      symbol: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Symbol',
        max_length: 255,
      },
      name: {
        type: 'string',
        required: false,
        read_only: false,
        create: true,
        label: 'Name',
        max_length: 255,
      },
      registry_id: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Registry id',
        max_length: 255,
      },
      description: {
        type: 'textarea',
        required: false,
        read_only: false,
        create: true,
        label: 'Description',
        max_length: 2500,
      },
    },
  },
  {
    name: 'instruments',
    title: 'Instruments',
    singular_name: 'instrument',
    path: 'instruments',
    backend_url: 'internal/instruments/',
    table_columns: ['id', 'null', 'symbol', 'null'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'internal/instruments/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'internal/instruments/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'internal/instruments/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'internal/instruments/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'internal/instruments/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Id',
      },
      symbol: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Symbol',
        max_length: 255,
      },
      description: {
        type: 'textarea',
        required: false,
        read_only: false,
        create: true,
        label: 'Description',
        max_length: 5000,
      },
    },
  },
  {
    name: 'prices',
    title: 'Prices',
    singular_name: 'price',
    path: 'prices',
    backend_url: 'internal/prices/',
    table_columns: ['id', 'instrument_id', 'value', 'currency'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'internal/prices/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'internal/prices/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'internal/prices/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'internal/prices/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'internal/prices/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Id',
      },
      project_id: {
        type: 'string',
        required: false,
        read_only: false,
        create: true,
        label: 'Project id',
      },
      instrument_id: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Instrument id',
      },
      value: {
        type: 'number',
        required: true,
        read_only: false,
        create: true,
        label: 'Value',
      },
      currency: {
        type: 'choice',
        required: true,
        read_only: false,
        create: true,
        label: 'Currency',
        choices: [
          {
            value: 'GBP',
            display_name: 'Pound Sterling',
          },
          {
            value: 'EUR',
            display_name: 'Euro',
          },
          {
            value: 'USD',
            display_name: 'US Dollar',
          },
          {
            value: 'AED',
            display_name: 'UAE Dirham',
          },
          {
            value: 'AFN',
            display_name: 'Afghani',
          },
          {
            value: 'ALL',
            display_name: 'Lek',
          },
          {
            value: 'AMD',
            display_name: 'Armenian Dram',
          },
          {
            value: 'ANG',
            display_name: 'Netherlands Antillean Guilder',
          },
          {
            value: 'AOA',
            display_name: 'Kwanza',
          },
          {
            value: 'ARS',
            display_name: 'Argentine Peso',
          },
          {
            value: 'AUD',
            display_name: 'Australian Dollar',
          },
          {
            value: 'AWG',
            display_name: 'Aruban Florin',
          },
          {
            value: 'AZN',
            display_name: 'Azerbaijanian Manat',
          },
          {
            value: 'BAM',
            display_name: 'Convertible Mark',
          },
          {
            value: 'BBD',
            display_name: 'Barbados Dollar',
          },
          {
            value: 'BDT',
            display_name: 'Taka',
          },
          {
            value: 'BGN',
            display_name: 'Bulgarian Lev',
          },
          {
            value: 'BHD',
            display_name: 'Bahraini Dinar',
          },
          {
            value: 'BIF',
            display_name: 'Burundi Franc',
          },
          {
            value: 'BMD',
            display_name: 'Bermudian Dollar',
          },
          {
            value: 'BND',
            display_name: 'Brunei Dollar',
          },
          {
            value: 'BOB',
            display_name: 'Boliviano',
          },
          {
            value: 'BRL',
            display_name: 'Brazilian Real',
          },
          {
            value: 'BSD',
            display_name: 'Bahamian Dollar',
          },
          {
            value: 'BTN',
            display_name: 'Ngultrum',
          },
          {
            value: 'BWP',
            display_name: 'Pula',
          },
          {
            value: 'BYN',
            display_name: 'Belarusian Ruble',
          },
          {
            value: 'BZD',
            display_name: 'Belize Dollar',
          },
          {
            value: 'CAD',
            display_name: 'Canadian Dollar',
          },
          {
            value: 'CDF',
            display_name: 'Congolese Franc',
          },
          {
            value: 'CHF',
            display_name: 'Swiss Franc',
          },
          {
            value: 'CLP',
            display_name: 'Chilean Peso',
          },
          {
            value: 'CNY',
            display_name: 'Yuan Renminbi',
          },
          {
            value: 'COP',
            display_name: 'Colombian Peso',
          },
          {
            value: 'CRC',
            display_name: 'Costa Rican Colon',
          },
          {
            value: 'CUC',
            display_name: 'Peso Convertible',
          },
          {
            value: 'CUP',
            display_name: 'Cuban Peso',
          },
          {
            value: 'CVE',
            display_name: 'Cabo Verde Escudo',
          },
          {
            value: 'CZK',
            display_name: 'Czech Koruna',
          },
          {
            value: 'DJF',
            display_name: 'Djibouti Franc',
          },
          {
            value: 'DKK',
            display_name: 'Danish Krone',
          },
          {
            value: 'DOP',
            display_name: 'Dominican Peso',
          },
          {
            value: 'DZD',
            display_name: 'Algerian Dinar',
          },
          {
            value: 'EGP',
            display_name: 'Egyptian Pound',
          },
          {
            value: 'ERN',
            display_name: 'Nakfa',
          },
          {
            value: 'ETB',
            display_name: 'Ethiopian Birr',
          },
          {
            value: 'FJD',
            display_name: 'Fiji Dollar',
          },
          {
            value: 'FKP',
            display_name: 'Falkland Islands Pound',
          },
          {
            value: 'GEL',
            display_name: 'Lari',
          },
          {
            value: 'GHS',
            display_name: 'Ghana Cedi',
          },
          {
            value: 'GIP',
            display_name: 'Gibraltar Pound',
          },
          {
            value: 'GMD',
            display_name: 'Dalasi',
          },
          {
            value: 'GNF',
            display_name: 'Guinea Franc',
          },
          {
            value: 'GTQ',
            display_name: 'Quetzal',
          },
          {
            value: 'GYD',
            display_name: 'Guyana Dollar',
          },
          {
            value: 'HKD',
            display_name: 'Hong Kong Dollar',
          },
          {
            value: 'HNL',
            display_name: 'Lempira',
          },
          {
            value: 'HRK',
            display_name: 'Kuna',
          },
          {
            value: 'HTG',
            display_name: 'Gourde',
          },
          {
            value: 'HUF',
            display_name: 'Forint',
          },
          {
            value: 'IDR',
            display_name: 'Rupiah',
          },
          {
            value: 'ILS',
            display_name: 'New Israeli Sheqel',
          },
          {
            value: 'INR',
            display_name: 'Indian Rupee',
          },
          {
            value: 'IQD',
            display_name: 'Iraqi Dinar',
          },
          {
            value: 'IRR',
            display_name: 'Iranian Rial',
          },
          {
            value: 'ISK',
            display_name: 'Iceland Krona',
          },
          {
            value: 'JMD',
            display_name: 'Jamaican Dollar',
          },
          {
            value: 'JOD',
            display_name: 'Jordanian Dinar',
          },
          {
            value: 'JPY',
            display_name: 'Yen',
          },
          {
            value: 'KES',
            display_name: 'Kenyan Shilling',
          },
          {
            value: 'KGS',
            display_name: 'Som',
          },
          {
            value: 'KHR',
            display_name: 'Riel',
          },
          {
            value: 'KMF',
            display_name: 'Comoro Franc',
          },
          {
            value: 'KPW',
            display_name: 'North Korean Won',
          },
          {
            value: 'KRW',
            display_name: 'Won',
          },
          {
            value: 'KWD',
            display_name: 'Kuwaiti Dinar',
          },
          {
            value: 'KYD',
            display_name: 'Cayman Islands Dollar',
          },
          {
            value: 'KZT',
            display_name: 'Tenge',
          },
          {
            value: 'LAK',
            display_name: 'Kip',
          },
          {
            value: 'LBP',
            display_name: 'Lebanese Pound',
          },
          {
            value: 'LKR',
            display_name: 'Sri Lanka Rupee',
          },
          {
            value: 'LRD',
            display_name: 'Liberian Dollar',
          },
          {
            value: 'LSL',
            display_name: 'Loti',
          },
          {
            value: 'LYD',
            display_name: 'Libyan Dinar',
          },
          {
            value: 'MAD',
            display_name: 'Moroccan Dirham',
          },
          {
            value: 'MDL',
            display_name: 'Moldovan Leu',
          },
          {
            value: 'MGA',
            display_name: 'Malagasy Ariary',
          },
          {
            value: 'MKD',
            display_name: 'Denar',
          },
          {
            value: 'MMK',
            display_name: 'Kyat',
          },
          {
            value: 'MNT',
            display_name: 'Tugrik',
          },
          {
            value: 'MOP',
            display_name: 'Pataca',
          },
          {
            value: 'MRO',
            display_name: 'Ouguiya',
          },
          {
            value: 'MUR',
            display_name: 'Mauritius Rupee',
          },
          {
            value: 'MVR',
            display_name: 'Rufiyaa',
          },
          {
            value: 'MWK',
            display_name: 'Malawi Kwacha',
          },
          {
            value: 'MXN',
            display_name: 'Mexican Peso',
          },
          {
            value: 'MYR',
            display_name: 'Malaysian Ringgit',
          },
          {
            value: 'MZN',
            display_name: 'Mozambique Metical',
          },
          {
            value: 'NAD',
            display_name: 'Namibia Dollar',
          },
          {
            value: 'NGN',
            display_name: 'Naira',
          },
          {
            value: 'NIO',
            display_name: 'Cordoba Oro',
          },
          {
            value: 'NOK',
            display_name: 'Norwegian Krone',
          },
          {
            value: 'NPR',
            display_name: 'Nepalese Rupee',
          },
          {
            value: 'NZD',
            display_name: 'New Zealand Dollar',
          },
          {
            value: 'OMR',
            display_name: 'Rial Omani',
          },
          {
            value: 'PAB',
            display_name: 'Balboa',
          },
          {
            value: 'PEN',
            display_name: 'Sol',
          },
          {
            value: 'PGK',
            display_name: 'Kina',
          },
          {
            value: 'PHP',
            display_name: 'Philippine Peso',
          },
          {
            value: 'PKR',
            display_name: 'Pakistan Rupee',
          },
          {
            value: 'PLN',
            display_name: 'Zloty',
          },
          {
            value: 'PYG',
            display_name: 'Guarani',
          },
          {
            value: 'QAR',
            display_name: 'Qatari Rial',
          },
          {
            value: 'RON',
            display_name: 'Romanian Leu',
          },
          {
            value: 'RSD',
            display_name: 'Serbian Dinar',
          },
          {
            value: 'RUB',
            display_name: 'Russian Ruble',
          },
          {
            value: 'RWF',
            display_name: 'Rwanda Franc',
          },
          {
            value: 'SAR',
            display_name: 'Saudi Riyal',
          },
          {
            value: 'SBD',
            display_name: 'Solomon Islands Dollar',
          },
          {
            value: 'SCR',
            display_name: 'Seychelles Rupee',
          },
          {
            value: 'SDG',
            display_name: 'Sudanese Pound',
          },
          {
            value: 'SEK',
            display_name: 'Swedish Krona',
          },
          {
            value: 'SGD',
            display_name: 'Singapore Dollar',
          },
          {
            value: 'SHP',
            display_name: 'Saint Helena Pound',
          },
          {
            value: 'SLL',
            display_name: 'Leone',
          },
          {
            value: 'SOS',
            display_name: 'Somali Shilling',
          },
          {
            value: 'SRD',
            display_name: 'Surinam Dollar',
          },
          {
            value: 'SSP',
            display_name: 'South Sudanese Pound',
          },
          {
            value: 'STD',
            display_name: 'Dobra',
          },
          {
            value: 'SVC',
            display_name: 'El Salvador Colon',
          },
          {
            value: 'SYP',
            display_name: 'Syrian Pound',
          },
          {
            value: 'SZL',
            display_name: 'Lilangeni',
          },
          {
            value: 'THB',
            display_name: 'Baht',
          },
          {
            value: 'TJS',
            display_name: 'Somoni',
          },
          {
            value: 'TMT',
            display_name: 'Turkmenistan New Manat',
          },
          {
            value: 'TND',
            display_name: 'Tunisian Dinar',
          },
          {
            value: 'TOP',
            display_name: 'Pa’anga',
          },
          {
            value: 'TRY',
            display_name: 'Turkish Lira',
          },
          {
            value: 'TTD',
            display_name: 'Trinidad and Tobago Dollar',
          },
          {
            value: 'TWD',
            display_name: 'New Taiwan Dollar',
          },
          {
            value: 'TZS',
            display_name: 'Tanzanian Shilling',
          },
          {
            value: 'UAH',
            display_name: 'Hryvnia',
          },
          {
            value: 'UGX',
            display_name: 'Uganda Shilling',
          },
          {
            value: 'UYU',
            display_name: 'Peso Uruguayo',
          },
          {
            value: 'UZS',
            display_name: 'Uzbekistan Sum',
          },
          {
            value: 'VEF',
            display_name: 'Bolívar',
          },
          {
            value: 'VND',
            display_name: 'Dong',
          },
          {
            value: 'VUV',
            display_name: 'Vatu',
          },
          {
            value: 'WST',
            display_name: 'Tala',
          },
          {
            value: 'XAF',
            display_name: 'CFA Franc BEAC',
          },
          {
            value: 'XAG',
            display_name: 'Silver',
          },
          {
            value: 'XAU',
            display_name: 'Gold',
          },
          {
            value: 'XBA',
            display_name: 'Bond Markets Unit European Composite Unit (EURCO)',
          },
          {
            value: 'XBB',
            display_name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
          },
          {
            value: 'XBC',
            display_name:
              'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
          },
          {
            value: 'XBD',
            display_name:
              'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
          },
          {
            value: 'XCD',
            display_name: 'East Caribbean Dollar',
          },
          {
            value: 'XDR',
            display_name: 'SDR (Special Drawing Right)',
          },
          {
            value: 'XOF',
            display_name: 'CFA Franc BCEAO',
          },
          {
            value: 'XPD',
            display_name: 'Palladium',
          },
          {
            value: 'XPF',
            display_name: 'CFP Franc',
          },
          {
            value: 'XPT',
            display_name: 'Platinum',
          },
          {
            value: 'XSU',
            display_name: 'Sucre',
          },
          {
            value: 'XTS',
            display_name: 'Codes specifically reserved for testing purposes',
          },
          {
            value: 'XUA',
            display_name: 'ADB Unit of Account',
          },
          {
            value: 'XXX',
            display_name:
              'The codes assigned for transactions where no currency is involved',
          },
          {
            value: 'YER',
            display_name: 'Yemeni Rial',
          },
          {
            value: 'ZAR',
            display_name: 'Rand',
          },
          {
            value: 'ZMW',
            display_name: 'Zambian Kwacha',
          },
          {
            value: 'ZWL',
            display_name: 'Zimbabwe Dollar',
          },
        ],
      },
      valid_from: {
        type: 'datetime',
        required: true,
        read_only: false,
        create: true,
        label: 'Valid from',
      },
      valid_until: {
        type: 'datetime',
        required: true,
        read_only: false,
        create: true,
        label: 'Valid until',
      },
    },
  },
  {
    name: 'orders',
    title: 'Orders',
    singular_name: 'order',
    path: 'orders',
    backend_url: 'internal/orders/',
    table_columns: ['id', 'volume', 'total_cost', 'status'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        name: 'list',
        type: 'list',
        url: 'internal/orders/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'internal/orders/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Id',
      },
      instrument: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Instrument',
        table_name: 'Instruments',
        singular_name: 'Instrument',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'instruments/',
          },
          symbol: {
            type: 'string',
            label: 'Symbol',
            pk: false,
            url: '',
          },
          description: {
            type: 'string',
            label: 'Description',
            pk: false,
            url: '',
          },
        },
      },
      account_id: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Account id',
      },
      currency: {
        type: 'choice',
        required: false,
        read_only: true,
        create: false,
        label: 'Currency',
        choices: [
          {
            value: 'GBP',
            display_name: 'Pound Sterling',
          },
          {
            value: 'EUR',
            display_name: 'Euro',
          },
          {
            value: 'USD',
            display_name: 'US Dollar',
          },
          {
            value: 'AED',
            display_name: 'UAE Dirham',
          },
          {
            value: 'AFN',
            display_name: 'Afghani',
          },
          {
            value: 'ALL',
            display_name: 'Lek',
          },
          {
            value: 'AMD',
            display_name: 'Armenian Dram',
          },
          {
            value: 'ANG',
            display_name: 'Netherlands Antillean Guilder',
          },
          {
            value: 'AOA',
            display_name: 'Kwanza',
          },
          {
            value: 'ARS',
            display_name: 'Argentine Peso',
          },
          {
            value: 'AUD',
            display_name: 'Australian Dollar',
          },
          {
            value: 'AWG',
            display_name: 'Aruban Florin',
          },
          {
            value: 'AZN',
            display_name: 'Azerbaijanian Manat',
          },
          {
            value: 'BAM',
            display_name: 'Convertible Mark',
          },
          {
            value: 'BBD',
            display_name: 'Barbados Dollar',
          },
          {
            value: 'BDT',
            display_name: 'Taka',
          },
          {
            value: 'BGN',
            display_name: 'Bulgarian Lev',
          },
          {
            value: 'BHD',
            display_name: 'Bahraini Dinar',
          },
          {
            value: 'BIF',
            display_name: 'Burundi Franc',
          },
          {
            value: 'BMD',
            display_name: 'Bermudian Dollar',
          },
          {
            value: 'BND',
            display_name: 'Brunei Dollar',
          },
          {
            value: 'BOB',
            display_name: 'Boliviano',
          },
          {
            value: 'BRL',
            display_name: 'Brazilian Real',
          },
          {
            value: 'BSD',
            display_name: 'Bahamian Dollar',
          },
          {
            value: 'BTN',
            display_name: 'Ngultrum',
          },
          {
            value: 'BWP',
            display_name: 'Pula',
          },
          {
            value: 'BYN',
            display_name: 'Belarusian Ruble',
          },
          {
            value: 'BZD',
            display_name: 'Belize Dollar',
          },
          {
            value: 'CAD',
            display_name: 'Canadian Dollar',
          },
          {
            value: 'CDF',
            display_name: 'Congolese Franc',
          },
          {
            value: 'CHF',
            display_name: 'Swiss Franc',
          },
          {
            value: 'CLP',
            display_name: 'Chilean Peso',
          },
          {
            value: 'CNY',
            display_name: 'Yuan Renminbi',
          },
          {
            value: 'COP',
            display_name: 'Colombian Peso',
          },
          {
            value: 'CRC',
            display_name: 'Costa Rican Colon',
          },
          {
            value: 'CUC',
            display_name: 'Peso Convertible',
          },
          {
            value: 'CUP',
            display_name: 'Cuban Peso',
          },
          {
            value: 'CVE',
            display_name: 'Cabo Verde Escudo',
          },
          {
            value: 'CZK',
            display_name: 'Czech Koruna',
          },
          {
            value: 'DJF',
            display_name: 'Djibouti Franc',
          },
          {
            value: 'DKK',
            display_name: 'Danish Krone',
          },
          {
            value: 'DOP',
            display_name: 'Dominican Peso',
          },
          {
            value: 'DZD',
            display_name: 'Algerian Dinar',
          },
          {
            value: 'EGP',
            display_name: 'Egyptian Pound',
          },
          {
            value: 'ERN',
            display_name: 'Nakfa',
          },
          {
            value: 'ETB',
            display_name: 'Ethiopian Birr',
          },
          {
            value: 'FJD',
            display_name: 'Fiji Dollar',
          },
          {
            value: 'FKP',
            display_name: 'Falkland Islands Pound',
          },
          {
            value: 'GEL',
            display_name: 'Lari',
          },
          {
            value: 'GHS',
            display_name: 'Ghana Cedi',
          },
          {
            value: 'GIP',
            display_name: 'Gibraltar Pound',
          },
          {
            value: 'GMD',
            display_name: 'Dalasi',
          },
          {
            value: 'GNF',
            display_name: 'Guinea Franc',
          },
          {
            value: 'GTQ',
            display_name: 'Quetzal',
          },
          {
            value: 'GYD',
            display_name: 'Guyana Dollar',
          },
          {
            value: 'HKD',
            display_name: 'Hong Kong Dollar',
          },
          {
            value: 'HNL',
            display_name: 'Lempira',
          },
          {
            value: 'HRK',
            display_name: 'Kuna',
          },
          {
            value: 'HTG',
            display_name: 'Gourde',
          },
          {
            value: 'HUF',
            display_name: 'Forint',
          },
          {
            value: 'IDR',
            display_name: 'Rupiah',
          },
          {
            value: 'ILS',
            display_name: 'New Israeli Sheqel',
          },
          {
            value: 'INR',
            display_name: 'Indian Rupee',
          },
          {
            value: 'IQD',
            display_name: 'Iraqi Dinar',
          },
          {
            value: 'IRR',
            display_name: 'Iranian Rial',
          },
          {
            value: 'ISK',
            display_name: 'Iceland Krona',
          },
          {
            value: 'JMD',
            display_name: 'Jamaican Dollar',
          },
          {
            value: 'JOD',
            display_name: 'Jordanian Dinar',
          },
          {
            value: 'JPY',
            display_name: 'Yen',
          },
          {
            value: 'KES',
            display_name: 'Kenyan Shilling',
          },
          {
            value: 'KGS',
            display_name: 'Som',
          },
          {
            value: 'KHR',
            display_name: 'Riel',
          },
          {
            value: 'KMF',
            display_name: 'Comoro Franc',
          },
          {
            value: 'KPW',
            display_name: 'North Korean Won',
          },
          {
            value: 'KRW',
            display_name: 'Won',
          },
          {
            value: 'KWD',
            display_name: 'Kuwaiti Dinar',
          },
          {
            value: 'KYD',
            display_name: 'Cayman Islands Dollar',
          },
          {
            value: 'KZT',
            display_name: 'Tenge',
          },
          {
            value: 'LAK',
            display_name: 'Kip',
          },
          {
            value: 'LBP',
            display_name: 'Lebanese Pound',
          },
          {
            value: 'LKR',
            display_name: 'Sri Lanka Rupee',
          },
          {
            value: 'LRD',
            display_name: 'Liberian Dollar',
          },
          {
            value: 'LSL',
            display_name: 'Loti',
          },
          {
            value: 'LYD',
            display_name: 'Libyan Dinar',
          },
          {
            value: 'MAD',
            display_name: 'Moroccan Dirham',
          },
          {
            value: 'MDL',
            display_name: 'Moldovan Leu',
          },
          {
            value: 'MGA',
            display_name: 'Malagasy Ariary',
          },
          {
            value: 'MKD',
            display_name: 'Denar',
          },
          {
            value: 'MMK',
            display_name: 'Kyat',
          },
          {
            value: 'MNT',
            display_name: 'Tugrik',
          },
          {
            value: 'MOP',
            display_name: 'Pataca',
          },
          {
            value: 'MRO',
            display_name: 'Ouguiya',
          },
          {
            value: 'MUR',
            display_name: 'Mauritius Rupee',
          },
          {
            value: 'MVR',
            display_name: 'Rufiyaa',
          },
          {
            value: 'MWK',
            display_name: 'Malawi Kwacha',
          },
          {
            value: 'MXN',
            display_name: 'Mexican Peso',
          },
          {
            value: 'MYR',
            display_name: 'Malaysian Ringgit',
          },
          {
            value: 'MZN',
            display_name: 'Mozambique Metical',
          },
          {
            value: 'NAD',
            display_name: 'Namibia Dollar',
          },
          {
            value: 'NGN',
            display_name: 'Naira',
          },
          {
            value: 'NIO',
            display_name: 'Cordoba Oro',
          },
          {
            value: 'NOK',
            display_name: 'Norwegian Krone',
          },
          {
            value: 'NPR',
            display_name: 'Nepalese Rupee',
          },
          {
            value: 'NZD',
            display_name: 'New Zealand Dollar',
          },
          {
            value: 'OMR',
            display_name: 'Rial Omani',
          },
          {
            value: 'PAB',
            display_name: 'Balboa',
          },
          {
            value: 'PEN',
            display_name: 'Sol',
          },
          {
            value: 'PGK',
            display_name: 'Kina',
          },
          {
            value: 'PHP',
            display_name: 'Philippine Peso',
          },
          {
            value: 'PKR',
            display_name: 'Pakistan Rupee',
          },
          {
            value: 'PLN',
            display_name: 'Zloty',
          },
          {
            value: 'PYG',
            display_name: 'Guarani',
          },
          {
            value: 'QAR',
            display_name: 'Qatari Rial',
          },
          {
            value: 'RON',
            display_name: 'Romanian Leu',
          },
          {
            value: 'RSD',
            display_name: 'Serbian Dinar',
          },
          {
            value: 'RUB',
            display_name: 'Russian Ruble',
          },
          {
            value: 'RWF',
            display_name: 'Rwanda Franc',
          },
          {
            value: 'SAR',
            display_name: 'Saudi Riyal',
          },
          {
            value: 'SBD',
            display_name: 'Solomon Islands Dollar',
          },
          {
            value: 'SCR',
            display_name: 'Seychelles Rupee',
          },
          {
            value: 'SDG',
            display_name: 'Sudanese Pound',
          },
          {
            value: 'SEK',
            display_name: 'Swedish Krona',
          },
          {
            value: 'SGD',
            display_name: 'Singapore Dollar',
          },
          {
            value: 'SHP',
            display_name: 'Saint Helena Pound',
          },
          {
            value: 'SLL',
            display_name: 'Leone',
          },
          {
            value: 'SOS',
            display_name: 'Somali Shilling',
          },
          {
            value: 'SRD',
            display_name: 'Surinam Dollar',
          },
          {
            value: 'SSP',
            display_name: 'South Sudanese Pound',
          },
          {
            value: 'STD',
            display_name: 'Dobra',
          },
          {
            value: 'SVC',
            display_name: 'El Salvador Colon',
          },
          {
            value: 'SYP',
            display_name: 'Syrian Pound',
          },
          {
            value: 'SZL',
            display_name: 'Lilangeni',
          },
          {
            value: 'THB',
            display_name: 'Baht',
          },
          {
            value: 'TJS',
            display_name: 'Somoni',
          },
          {
            value: 'TMT',
            display_name: 'Turkmenistan New Manat',
          },
          {
            value: 'TND',
            display_name: 'Tunisian Dinar',
          },
          {
            value: 'TOP',
            display_name: 'Pa’anga',
          },
          {
            value: 'TRY',
            display_name: 'Turkish Lira',
          },
          {
            value: 'TTD',
            display_name: 'Trinidad and Tobago Dollar',
          },
          {
            value: 'TWD',
            display_name: 'New Taiwan Dollar',
          },
          {
            value: 'TZS',
            display_name: 'Tanzanian Shilling',
          },
          {
            value: 'UAH',
            display_name: 'Hryvnia',
          },
          {
            value: 'UGX',
            display_name: 'Uganda Shilling',
          },
          {
            value: 'UYU',
            display_name: 'Peso Uruguayo',
          },
          {
            value: 'UZS',
            display_name: 'Uzbekistan Sum',
          },
          {
            value: 'VEF',
            display_name: 'Bolívar',
          },
          {
            value: 'VND',
            display_name: 'Dong',
          },
          {
            value: 'VUV',
            display_name: 'Vatu',
          },
          {
            value: 'WST',
            display_name: 'Tala',
          },
          {
            value: 'XAF',
            display_name: 'CFA Franc BEAC',
          },
          {
            value: 'XAG',
            display_name: 'Silver',
          },
          {
            value: 'XAU',
            display_name: 'Gold',
          },
          {
            value: 'XBA',
            display_name: 'Bond Markets Unit European Composite Unit (EURCO)',
          },
          {
            value: 'XBB',
            display_name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
          },
          {
            value: 'XBC',
            display_name:
              'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
          },
          {
            value: 'XBD',
            display_name:
              'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
          },
          {
            value: 'XCD',
            display_name: 'East Caribbean Dollar',
          },
          {
            value: 'XDR',
            display_name: 'SDR (Special Drawing Right)',
          },
          {
            value: 'XOF',
            display_name: 'CFA Franc BCEAO',
          },
          {
            value: 'XPD',
            display_name: 'Palladium',
          },
          {
            value: 'XPF',
            display_name: 'CFP Franc',
          },
          {
            value: 'XPT',
            display_name: 'Platinum',
          },
          {
            value: 'XSU',
            display_name: 'Sucre',
          },
          {
            value: 'XTS',
            display_name: 'Codes specifically reserved for testing purposes',
          },
          {
            value: 'XUA',
            display_name: 'ADB Unit of Account',
          },
          {
            value: 'XXX',
            display_name:
              'The codes assigned for transactions where no currency is involved',
          },
          {
            value: 'YER',
            display_name: 'Yemeni Rial',
          },
          {
            value: 'ZAR',
            display_name: 'Rand',
          },
          {
            value: 'ZMW',
            display_name: 'Zambian Kwacha',
          },
          {
            value: 'ZWL',
            display_name: 'Zimbabwe Dollar',
          },
        ],
      },
      volume: {
        type: 'number',
        required: false,
        read_only: true,
        create: false,
        label: 'Volume',
      },
      total_cost: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Total cost',
      },
      created_at: {
        type: 'datetime',
        required: false,
        read_only: true,
        create: false,
        label: 'Created at',
      },
      executed_at: {
        type: 'datetime',
        required: false,
        read_only: true,
        create: false,
        label: 'Executed at',
      },
      closed_at: {
        type: 'datetime',
        required: false,
        read_only: true,
        create: false,
        label: 'Closed at',
      },
      status: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Status',
      },
    },
  },
];
