import { FunctionComponent, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { RedirectLoginOptions, useAuth0, User } from '@auth0/auth0-react';
import { getToken, login } from '../store/actions/user';

interface propsChildren {
  children: ReactNode;
}

interface useAuth {
  isAuthenticated: boolean;
  loginWithRedirect(options?: RedirectLoginOptions | undefined): Promise<void>;
}

const ProtectedRoute: FunctionComponent<propsChildren> = ({
  children,
}): any => {
  const dispatch: any = useDispatch();
  const {
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
    error,
  } = useAuth0<useAuth>();
  const { user } = useAuth0<User>();

  const userFromStorage = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') || '')
    : null;

  const userTokenFromStorage = localStorage.getItem('token')
    ? JSON.parse(localStorage.getItem('token') || '')
    : null;

  if (isAuthenticated && user && !userFromStorage) {
    dispatch(login(user));
  }

  if (isAuthenticated && user && !userTokenFromStorage) {
    dispatch(getToken(getAccessTokenSilently));
  }

  /* 
  if the user is not authenticated due to an error or because they don't have permissions, 
  logout the user to clear the error and then redirect back to Auth0
  */
  if (!isAuthenticated && error) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    logout({});
    return null;
  }

  return isAuthenticated
    ? children
    : loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
};

export default ProtectedRoute;
