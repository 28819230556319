import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import { useAuth0 } from '@auth0/auth0-react';
import { logoutUser } from '../../../store/actions/user';

import './AlertError.css';

interface Props {
  errorData: any;
}

const AlertError: FunctionComponent<Props> = (props) => {
  const dispatch: any = useDispatch();
  const { logout } = useAuth0();

  useEffect(() => {
    if (
      props.errorData.response &&
      props.errorData?.response?.status === 401 &&
      props.errorData?.response?.data?.detail === 'Token has expired.'
    ) {
      dispatch(logoutUser());
      logout({ returnTo: window.location.origin });
    }
  }, [dispatch]);

  if (
    (props.errorData.name === 'AxiosError' && !props.errorData.response.data) ||
    props.errorData.response.headers['content-type'] === 'text/html'
  ) {
    return (
      <Alert severity="error">
        <div className="alert_container">
          <p className="error_response_field">{props.errorData.message}</p>
          <p className="error_response">{props.errorData.code}</p>
        </div>
      </Alert>
    );
  }

  const objMap = (errObj: any): any =>
    Object.keys(errObj).map((key: any) => (
      <>
        <p className="error_response_field" key={key}>
          {key === '0' ? '->' : key}
        </p>
        {typeof errObj[key] === 'object' ? (
          objMap(errObj[key])
        ) : (
          <p className="error_response">{errObj[key]}</p>
        )}
      </>
    ));

  return (
    <Alert severity="error">
      <div className="alert_container">
        {objMap(props.errorData.response.data)}
      </div>
    </Alert>
  );
};

export default AlertError;
