import React, { FunctionComponent, MouseEventHandler } from 'react';

import { Button, CardActionArea, CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

interface Props {
  image: any;
  title: string;
  description: string;
  button: string;
  onClick: MouseEventHandler;
}

const MicroserviceCard: FunctionComponent<Props> = (props) => (
  <Card sx={{ maxWidth: 345, borderRadius: 4 }}>
    <CardActionArea onClick={props.onClick}>
      <CardMedia
        alt="database explorer product"
        component="img"
        height="140"
        image={props.image}
        sx={{ objectFit: 'unset', padding: 1 }}
      />
      <CardContent>
        <Typography component="div" gutterBottom variant="h5">
          {props.title}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {props.description}
        </Typography>
      </CardContent>
    </CardActionArea>
    <CardActions>
      <Button color="primary" onClick={props.onClick} size="small">
        {props.button}
      </Button>
    </CardActions>
  </Card>
);

export default MicroserviceCard;
