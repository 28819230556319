import React, { FunctionComponent, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { deepOrange } from '@mui/material/colors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Logo from '../../images/logo-purple.png';
import { logoutUser } from '../../store/actions/user';

import './Header.css';

interface UserProps {
  user: any;
}

const Header: FunctionComponent<UserProps> = (props) => {
  const dispatch: any = useDispatch();
  const { logout } = useAuth0();

  const [username] = useState<string | undefined>(props.user.name);
  const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
  const open = Boolean(openMenu);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenu(event.currentTarget);
  };

  // close the menu
  const handleClose = () => {
    setOpenMenu(null);
  };

  // log out the user and return them to the auth0 login screen
  const handleLogout = () => {
    dispatch(logoutUser());
    logout({ returnTo: window.location.origin });
    setOpenMenu(null);
  };

  return (
    <div className="container">
      <div className="imageDiv">
        <a href="/">
          <img alt="logo" className="image" src={Logo} />
        </a>
      </div>
      <div>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
        >
          <Avatar
            alt="stratiphy user administrator"
            src={props.user.picture}
            sx={{ bgcolor: deepOrange[500] }}
          />
          <Button
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            id="button"
            onClick={handleClick}
            sx={{ color: '#000' }}
            variant="text"
          >
            {username}
          </Button>
          <Menu
            anchorEl={openMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            aria-labelledby="demo-positioned-button"
            id="demo-positioned-menu"
            onClose={handleClose}
            open={open}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Stack>
      </div>
    </div>
  );
};

export default Header;
