import React, { FunctionComponent, MouseEventHandler } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import EditIcon from "@mui/icons-material/Edit";
import "./TableRow.css";

interface Props {
  column_1: string | number | null | any[];
  column_2: string | number | null | any[];
  column_3: string | number | null | any[];
  column_4: string | number | null | any[];
  onEdit: MouseEventHandler<HTMLButtonElement>;
  onDelete: MouseEventHandler<HTMLButtonElement>;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onViewMore: MouseEventHandler<HTMLButtonElement>;
  hasOnEditButton: boolean;
  hasOnDeleteButton: boolean;
  hasOnCancelButton: boolean;
}

const TableRow: FunctionComponent<Props> = (props) => (
    <div className="row_container">
      <div className="row_field">
        <p className="row_field_text">{props.column_1}</p>
      </div>
      <div className="row_field">
        <p className="row_field_text">{props.column_2}</p>
      </div>
      <div className="row_field">
        <p className="row_field_text">{props.column_3}</p>
      </div>
      <div className="row_field">
        <p className="row_field_text">{props.column_4}</p>
      </div>
      <div className="row_actions">
        {props.hasOnEditButton && (
          <button className="icon_button" onClick={props.onEdit}>
            <EditIcon sx={{ color: "#" }} />
          </button>
        )}
        {props.hasOnDeleteButton && (
          <button className="icon_button" onClick={props.onDelete}>
            <DeleteIcon sx={{ color: "#" }} />
          </button>
        )}
        {props.hasOnCancelButton && (
          <button className="icon_button" onClick={props.onCancel}>
            <DoDisturbOnIcon sx={{ color: "#" }} />
          </button>
        )}
        <button className="icon_button" onClick={props.onViewMore}>
          <p className="row_propertie_text">View More</p>
        </button>
      </div>
    </div>
  );

export default TableRow;
