import axios from 'axios';

import {
  GET_FIELDS_FAIL,
  GET_FIELDS_REQUEST,
  GET_FIELDS_SUCCESS,
} from '../../constants/options';

const BACKEND_DOMAIN: string | undefined = process.env.REACT_APP_BACKEND_DOMAIN;

export const getFields =
  (backend_url: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: GET_FIELDS_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config: any = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.options(
        `${BACKEND_DOMAIN}/${backend_url}`,
        config
      );

      dispatch({
        type: GET_FIELDS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_FIELDS_FAIL,
        payload: `${error.name} ${error.message}`,
      });
    }
  };
