import { audience, scope } from '../../auth/provider';
import {
  GET_TOKEN_FAIL,
  GET_TOKEN_REQUEST,
  GET_TOKEN_SUCCESS,
  USER_LOGIN,
  USER_LOGOUT,
} from '../../constants/user';

export const login = (user: object) => async (dispatch: any) => {
  dispatch({ type: USER_LOGIN, payload: user });
  localStorage.setItem('user', JSON.stringify(user));
};

export const getToken =
  (getAccessTokenSilently: Function) => async (dispatch: any) => {
    try {
      dispatch({ type: GET_TOKEN_REQUEST });
      const token = await getAccessTokenSilently({
        audience,
        scope,
      });
      dispatch({ type: GET_TOKEN_SUCCESS, payload: token });
      localStorage.setItem('token', JSON.stringify(token));
    } catch (error) {
      dispatch({ type: GET_TOKEN_FAIL, payload: error });
    }
  };

export const logoutUser = () => (dispatch: any) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  dispatch({ type: USER_LOGOUT });
};
