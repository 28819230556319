import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

import { fieldAction } from '../../store/actions/actions';
import Loader from '../UI/loader/Loader';

import './FieldAction.css';

interface Props {
  FIELD_ACTIONS: any[];
  id: string | undefined;
}

const FieldAction: FunctionComponent<Props> = (props) => {
  const [field, setField] = useState<any>({});
  const [errorIndex, setErrorIndex] = useState<number>();
  const [successIndex, setSuccessIndex] = useState<number>();
  const [touchedIndex, setTouchedIndex] = useState<number>();
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();
  const { ms } = useParams();
  let ms_url: string;
  if (!ms) {
    navigate('/');
  } else {
    ms_url = ms;
  }

  const fieldActionReducer = useSelector((state: any) => state.fieldAction);
  const { success, loading, error } = fieldActionReducer;

  useEffect(() => {
    setField({});
  }, []);

  const handleAction = (action: any, index: number) => {
    if (props.id && Object.keys(field).length !== 0) {
      dispatch(
        fieldAction(
          ms_url,
          action.url,
          props.id,
          action.end_url,
          action.httpMethod,
          field
        )
      );
      setSuccessIndex(index);
      setErrorIndex(index);
    }
  };

  const handleOnChange = (e: any, input_key: string, index: number) => {
    setTouchedIndex(index);
    e.preventDefault();
    setField({
      [input_key]: e.target.value,
    });
  };

  return (
    <>
      <Divider sx={{ color: '#000' }} />
      <div className="field_action">
        <p className="field_action_title">ACTIONS</p>
        {props.FIELD_ACTIONS.map((actionObj: any, index: number) => (
          <div className="field_action_container" key={actionObj.name}>
            <div className="field_text_container">
              <TextField
                label={actionObj.default_value}
                onChange={(e) => handleOnChange(e, actionObj.input_key, index)}
                size="small"
                sx={{
                  color: 'white',
                  width: '95%',
                  marginRight: '3%',
                  backgroundColor: '#fff',
                  borderRadius: 2,
                }}
                value={
                  touchedIndex === index && field[actionObj.input_key]
                    ? field[actionObj.input_key]
                    : ''
                }
              />
              {error && errorIndex === index ? (
                <p className="action_error_text">{error.message}</p>
              ) : success && successIndex === index ? (
                <p className="action_success_text">Success</p>
              ) : (
                <p className="action_field_description">
                  {actionObj.description}
                </p>
              )}
            </div>
            <div className="field_action_button_container">
              {loading && successIndex === index ? (
                <Loader />
              ) : (
                <button
                  className={
                    success && successIndex === index
                      ? 'detail_view_button_success'
                      : 'detail_view_button'
                  }
                  onClick={() => handleAction(actionObj, index)}
                >
                  {actionObj.input_label}
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FieldAction;
