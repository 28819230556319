import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Alert from '@mui/material/Alert';

import FilterWarning from '../../components/filterWarning/FilterWarning';
import ModalUI from '../../components/modal/ModalUI';
import TableColumn from '../../components/tableColumn/TableColumn';
import TableHeaderBar from '../../components/tableHeaderBar/TableHeaderBar';
import TableRow from '../../components/tableRow/TableRow';
import AlertError from '../../components/UI/AlertError/AlertError';
import Loader from '../../components/UI/loader/Loader';
import {
  cancelById,
  deleteById,
  listPage,
  listTableViews,
} from '../../store/actions/actions';
import { getActionObj, getValue } from '../../utils/utils';
import './TableView.css';

interface Action {
  httpMethod: string;
  type: string;
  url: string;
  end_url?: string;
  key: string;
  header_action: boolean;
  row_action: boolean;
}

interface Filters {
  field: string;
  type: string;
  action?: Action;
}

interface SchemaProps {
  table_singular_name: string;
  table_columns: string[];
  filters: Filters[];
  actions: Action[];
}

const TableView: FunctionComponent<SchemaProps> = (props) => {
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [currentPageUrl, setCurrentPageUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [actionObj, setActionObj] = useState<any>();
  const [element, setElement] = useState('');
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { ms } = useParams();
  let ms_url: string;
  if (!ms) {
    navigate('/');
  } else {
    ms_url = ms;
  }

  const LIST_ACTION = getActionObj(props.actions, 'GET', 'list');
  const DELETE_ACTION = getActionObj(props.actions, 'DELETE', 'delete');
  const CANCEL_ACTION = getActionObj(props.actions, 'POST', 'cancel');

  // LIST ELEMENTS
  const listElements = useSelector((state: any) => state.list);
  const { data, loading: loadingList, error: errorList } = listElements;

  // DELETE AS QUICK ACTION
  const deleteElement = useSelector((state: any) => state.delete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = deleteElement;

  // CANCEL AS QUICK ACTION
  const cancelElement = useSelector((state: any) => state.cancel);
  const {
    loading: loadingCancel,
    error: errorCancel,
    success: successCancel,
  } = cancelElement;

  const createReducer = useSelector((state: any) => state.create);
  const { success: successCreate } = createReducer;

  const updateReducer = useSelector((state: any) => state.update);
  const { success: successUpdate } = updateReducer;

  useEffect(() => {
    // If the user selected to turn the page
    if (currentPageUrl) {
      dispatch(listPage(currentPageUrl));
    } else if (LIST_ACTION.url) {
      dispatch(listTableViews(ms_url, LIST_ACTION.url));
    }
  }, [dispatch, LIST_ACTION.url, currentPageUrl]);

  useEffect(() => {
    if (data) {
      const pages = data.count / 10;
      setTotalPages(Math.ceil(pages));
    }
  }, [data]);

  useEffect(() => {
    if (successCreate || successUpdate) {
      dispatch(listTableViews(ms_url, LIST_ACTION.url));
    }
    if (successDelete || errorDelete) {
      dispatch({ type: 'DELETE_RESET' });
      dispatch(listTableViews(ms_url, LIST_ACTION.url));
    }
    if (successCancel || errorCancel) {
      dispatch({ type: 'CANCEL_RESET' });
      dispatch(listTableViews(ms_url, LIST_ACTION.url));
    }
  }, [
    LIST_ACTION.url,
    dispatch,
    errorCancel,
    errorDelete,
    successCancel,
    successCreate,
    successDelete,
    successUpdate,
  ]);

  useEffect(() => {
    if (successDelete) {
      dispatch(listTableViews(ms_url, LIST_ACTION.url));
    }
    if (successCancel) {
      dispatch(listTableViews(ms_url, LIST_ACTION.url));
    }
  }, [dispatch, LIST_ACTION.url, successCancel, successDelete]);

  const hasTheAction = (rowOrTable: string, action: string) => {
    const hasRowAction = props.actions.find(
      (actObj: any) => actObj.type === action && actObj[rowOrTable]
    );
    if (hasRowAction) return true;
    return false;
  };

  // TABLE HEADER BAR
  const handleCreate = () => {
    navigate('create', { replace: false });
  };

  // TABLE ROW FUNCTIONS
  const handleViewMore = (obj: any) => {
    const action = getActionObj(props.actions, 'GET', 'retrieve');
    if (!action) return null;
    const key = obj[action.key];
    navigate(`${key}`, { replace: false });
    return null;
  };

  const handleDelete = (elementObj: any) => {
    const action = getActionObj(props.actions, 'DELETE', 'delete');
    if (!action || !action.key) return null;
    dispatch(deleteById(ms_url, action.url, elementObj[action.key]));
    setOpenModal(false);
    return null;
  };

  const handleCancel = (elementObj: any) => {
    const action = getActionObj(props.actions, 'POST', 'cancel');
    dispatch(
      cancelById(ms_url, action.url, elementObj[action.key], action.end_url)
    );
    setOpenModal(false);
  };

  const nextPage = () => {
    if (data.next) {
      setCurrentPageUrl(data.next);
      dispatch(listPage(data.next));
      setPageNumber((prevState) => prevState + 1);
    }
  };

  const previousPage = () => {
    if (data.previous) {
      setCurrentPageUrl(data.previous);
      dispatch(listPage(data.previous));
      setPageNumber((prevState) => prevState - 1);
    }
  };

  const checkAction = (type: string) => {
    switch (type) {
      case 'delete':
        return handleDelete(element);
      case 'cancel':
        return handleCancel(element);
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        {openModal && (
          <ModalUI
            action={actionObj}
            actionFunction={() => checkAction(actionObj.type)}
            actionMethod={actionObj.httpMethod}
            changedData={false}
            modalState={openModal}
            onClose={() => setOpenModal(false)}
          />
        )}
        <TableHeaderBar
          filters={props.filters}
          hasAddButton={hasTheAction('header_action', 'create')}
          hasDeleteButton={hasTheAction('header_action', 'delete')}
          onCreate={handleCreate}
          tableName={props.table_singular_name}
        />
        <div className="crudTable">
          {data && data.results && data.results.length === 0 && (
            <FilterWarning filter={false} text="No items found" />
          )}
          {successDelete && <Alert severity="success">Object Deleted!</Alert>}
          {successCancel && <Alert severity="success">Object Cancelled!</Alert>}
          {errorDelete && <AlertError errorData={errorDelete} />}
          {errorCancel && <AlertError errorData={errorCancel} />}
          {errorList && <AlertError errorData={errorList} />}
          {data && data.results && data.results.length > 0 && (
            <div className="columns_container">
              {props.table_columns &&
                props.table_columns.map((column: string) => (
                  <TableColumn
                    columnName={column.replace(/_/g, ' ').toUpperCase()}
                    key={column}
                  />
                ))}
              <TableColumn columnName="ACTIONS" />
            </div>
          )}
          <div className="tables_container">
            {loadingList && <Loader />}
            {loadingDelete && <Loader />}
            {loadingCancel && <Loader />}
            {data &&
              data.results &&
              data.results.map((obj: any, index: number) => (
                <TableRow
                  column_1={getValue(obj[props.table_columns[0]])}
                  column_2={getValue(obj[props.table_columns[1]])}
                  column_3={getValue(obj[props.table_columns[2]])}
                  column_4={getValue(obj[props.table_columns[3]])}
                  hasOnCancelButton={hasTheAction('row_action', 'cancel')}
                  hasOnDeleteButton={hasTheAction('row_action', 'delete')}
                  hasOnEditButton={hasTheAction('row_action', 'update')}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onCancel={() => {
                    setActionObj(CANCEL_ACTION);
                    setElement(obj);
                    setOpenModal(true);
                  }}
                  onDelete={() => {
                    setActionObj(DELETE_ACTION);
                    setElement(obj);
                    setOpenModal(true);
                  }}
                  onEdit={() => handleViewMore(obj)}
                  onViewMore={() => handleViewMore(obj)}
                />
              ))}
          </div>
          <div className="pages_manager_container">
            <div className="pages_manager_row_container">
              <div className="pages_manager_property">
                <p className="pages_manager_text">Rows per pages</p>
              </div>
              <div className="pages_manager_property">
                <p className="pages_manager_text">{`1-10 of ${
                  data && data.count ? data.count : '0'
                }`}</p>
              </div>
              <div className="pages_manager_property">
                <p className="pages_manager_text">{`Page ${pageNumber} of ${totalPages}`}</p>
              </div>
              <div className="pages_manager_property">
                <button className="icon_button" onClick={previousPage}>
                  <ArrowBackIosNewIcon
                    sx={{ fontSize: 15, color: '#6E6893' }}
                  />
                </button>
              </div>
              <div className="pages_manager_property">
                <button className="icon_button" onClick={nextPage}>
                  <ArrowForwardIosIcon
                    sx={{ fontSize: 15, color: '#6E6893' }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default TableView;
