import {
  BUTTON_ACTION_FAIL,
  BUTTON_ACTION_REQUEST,
  BUTTON_ACTION_RESET,
  BUTTON_ACTION_SUCCESS,
  CANCEL_FAIL,
  CANCEL_REQUEST,
  CANCEL_SUCCESS,
  CREATE_FAIL,
  CREATE_REQUEST,
  CREATE_RESET,
  CREATE_SUCCESS,
  DELETE_FAIL,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FIELD_ACTION_FAIL,
  FIELD_ACTION_REQUEST,
  FIELD_ACTION_RESET,
  FIELD_ACTION_SUCCESS,
  GET_BY_ID_FAIL,
  GET_BY_ID_REQUEST,
  GET_BY_ID_SUCCESS,
  LIST_FAIL,
  LIST_REQUEST,
  LIST_SUCCESS,
  UPDATE_FAIL,
  UPDATE_REQUEST,
  UPDATE_RESET,
  UPDATE_SUCCESS,
} from '../../constants/actions';

export const listReducer = (state = { data: [] }, action: any) => {
  switch (action.type) {
    case LIST_REQUEST:
      return {
        loading: true,
        data: [],
      };
    case LIST_SUCCESS:
      return {
        data: action.payload,
      };
    case LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case 'LIST_RESET':
      return {};
    default:
      return state;
  }
};

export const elementByIdReducer = (state = { data: [] }, action: any) => {
  switch (action.type) {
    case GET_BY_ID_REQUEST:
      return {
        loading: true,
        data: [],
      };
    case GET_BY_ID_SUCCESS:
      return {
        data: action.payload,
      };
    case GET_BY_ID_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateReducer = (state = { data: [] }, action: any) => {
  switch (action.type) {
    case UPDATE_REQUEST:
      return {
        loading: true,
        data: [],
      };
    case UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const createReducer = (state = { data: [] }, action: any) => {
  switch (action.type) {
    case CREATE_REQUEST:
      return {
        loading: true,
        data: [],
      };
    case CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case CREATE_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteReducer = (state = { data: [] }, action: any) => {
  switch (action.type) {
    case DELETE_REQUEST:
      return {
        loading: true,
        data: [],
      };
    case DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case DELETE_FAIL:
      return { loading: false, error: action.payload };

    case 'DELETE_RESET':
      return {};

    default:
      return state;
  }
};

export const cancelReducer = (state = { data: [] }, action: any) => {
  switch (action.type) {
    case CANCEL_REQUEST:
      return {
        loading: true,
        data: [],
      };
    case CANCEL_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case CANCEL_FAIL:
      return { loading: false, error: action.payload };

    case 'CANCEL_RESET':
      return {};

    default:
      return state;
  }
};

export const fieldActionReducer = (state = { data: [] }, action: any) => {
  switch (action.type) {
    case FIELD_ACTION_REQUEST:
      return {
        loading: true,
        data: [],
      };
    case FIELD_ACTION_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case FIELD_ACTION_FAIL:
      return { loading: false, error: action.payload };
    case FIELD_ACTION_RESET:
      return {};
    default:
      return state;
  }
};

export const buttonActionReducer = (state = { data: [] }, action: any) => {
  switch (action.type) {
    case BUTTON_ACTION_REQUEST:
      return {
        loading: true,
        data: [],
      };
    case BUTTON_ACTION_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case BUTTON_ACTION_FAIL:
      return { loading: false, error: action.payload };

    case BUTTON_ACTION_RESET:
      return {};

    default:
      return state;
  }
};
