import axios from 'axios';

import {
  BUTTON_ACTION_FAIL,
  BUTTON_ACTION_REQUEST,
  BUTTON_ACTION_SUCCESS,
  CANCEL_FAIL,
  CANCEL_REQUEST,
  CANCEL_SUCCESS,
  CREATE_FAIL,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAIL,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FIELD_ACTION_FAIL,
  FIELD_ACTION_REQUEST,
  FIELD_ACTION_SUCCESS,
  GET_BY_ID_FAIL,
  GET_BY_ID_REQUEST,
  GET_BY_ID_SUCCESS,
  LIST_FAIL,
  LIST_REQUEST,
  LIST_SUCCESS,
  UPDATE_FAIL,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from '../../constants/actions';

const BACKEND_DOMAIN: string | undefined = process.env.REACT_APP_BACKEND_DOMAIN;

export const listTableViews =
  (ms: string, backend_url: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: LIST_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config: any = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_DOMAIN}/${ms}/${backend_url}`,
        config
      );

      dispatch({
        type: LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: LIST_FAIL,
        payload: error,
      });
    }
  };

export const listPage =
  (page_url: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: LIST_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config: any = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(`${page_url}`, config);

      dispatch({
        type: LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: LIST_FAIL,
        payload: error,
      });
    }
  };

export const getById =
  (ms: string, backend_url: string, id: string | undefined) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: GET_BY_ID_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_DOMAIN}/${ms}/${backend_url}${id}/`,
        config
      );

      dispatch({
        type: GET_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_BY_ID_FAIL,
        payload: error.response,
      });
    }
  };

// update could be PATCH or PUT
export const update =
  (
    ms: string,
    backend_url: string,
    id: string,
    dataChanged: object,
    method: string
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: UPDATE_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      const { data } = await axios({
        method,
        url: `${BACKEND_DOMAIN}/${ms}/${backend_url}${id}/`,
        headers: config,
        data: dataChanged,
      });

      dispatch({
        type: UPDATE_SUCCESS,
        payload: data,
      });

      dispatch({
        type: GET_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_FAIL,
        payload: error,
      });
    }
  };

export const create =
  (ms: string, backend_url: string, dataObj: object, idemKey: string) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: CREATE_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config = {
        'Content-Type': 'application/json',
        'Idempotency-Key': idemKey,
        Authorization: `Bearer ${token}`,
      };

      const { data } = await axios({
        method: 'POST',
        url: `${BACKEND_DOMAIN}/${ms}/${backend_url}`,
        headers: config,
        data: dataObj,
      });

      dispatch({
        type: CREATE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: CREATE_FAIL,
        payload: error,
      });
    }
  };

export const cancelById =
  (ms: string, backend_url: string, id: string, end_url: string | undefined) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: CANCEL_REQUEST });

      const {
        userToken: { token },
      } = getState();

      let URL;
      if (end_url) {
        URL = `${BACKEND_DOMAIN}/${ms}/${backend_url}${id}/${end_url}/`;
      } else {
        URL = `${BACKEND_DOMAIN}/${ms}/${backend_url}${id}/cancel/`;
      }

      const config = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      };

      const { data } = await axios({
        method: 'POST',
        url: URL,
        headers: config,
      });

      dispatch({
        type: CANCEL_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: CANCEL_FAIL,
        payload: error,
      });
    }
  };

export const deleteById =
  (ms: string, backend_url: string, id: string) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: DELETE_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.delete(
        `${BACKEND_DOMAIN}/${ms}/${backend_url}${id}/`,
        config
      );

      dispatch({
        type: DELETE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: DELETE_FAIL,
        payload: error,
      });
    }
  };

// ----------------------- EXTRA ACTIONS (FOR SPECIFIC TABLES)------------------------------

export const listDeposits =
  (ms: string, backend_url: string, bank_account_id: string | undefined) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: LIST_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_DOMAIN}/${ms}/${backend_url}?bank_account_id=${bank_account_id}`,
        config
      );

      dispatch({
        type: LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: LIST_FAIL,
        payload: error,
      });
    }
  };

export const listBalances =
  (ms: string, portfolio_id: string, backend_url: string, type: string) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: LIST_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_DOMAIN}/${ms}/${backend_url}/${portfolio_id}/balances/${type}/`,
        config
      );

      dispatch({
        type: LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: LIST_FAIL,
        payload: error,
      });
    }
  };

// ----------------------- SPECIAL ACTIONS ------------------------------

export const fieldAction =
  (
    ms: string,
    url: string,
    id: string,
    end_url: string,
    httpMethod: string,
    dataObj: any
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: FIELD_ACTION_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      const { data } = await axios({
        method: httpMethod,
        url: `${BACKEND_DOMAIN}/${ms}/${url}${id}/${end_url}`,
        headers: config,
        data: dataObj || {},
      });

      dispatch({
        type: FIELD_ACTION_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: FIELD_ACTION_FAIL,
        payload: error,
      });
    }
  };

export const buttonAction =
  (ms: string, url: string, id: string, end_url: string, httpMethod: string) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: BUTTON_ACTION_REQUEST });

      const {
        userToken: { token },
      } = getState();

      const config = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      const { data } = await axios({
        method: httpMethod,
        url: `${BACKEND_DOMAIN}/${ms}/${url}${id}/${end_url}`,
        headers: config,
      });

      dispatch({
        type: BUTTON_ACTION_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BUTTON_ACTION_FAIL,
        payload: error,
      });
    }
  };
