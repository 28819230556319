import {
  GET_TOKEN_FAIL,
  GET_TOKEN_REQUEST,
  GET_TOKEN_SUCCESS,
  USER_LOGIN,
  USER_LOGOUT,
} from '../../constants/user';

export const userLoginReducer = (state = {}, action: any) => {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, userInfo: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userTokenReducer = (state = {}, action: any) => {
  switch (action.type) {
    case GET_TOKEN_REQUEST:
      return { success: false };
    case GET_TOKEN_SUCCESS:
      return { success: true, token: action.payload };
    case GET_TOKEN_FAIL:
      return { error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
