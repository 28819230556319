import React, { FunctionComponent, MouseEventHandler, useState } from 'react';
import { useDispatch } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { SelectChangeEvent } from '@mui/material/Select/Select';

import { listBy } from '../../store/actions/filters';
import FilterDropDown from '../filterDropDown/FilterDropDown';
import Button from '../UI/button/Button';

import './TableHeaderBar.css';

interface Action {
  httpMethod: string;
  type: string;
  url: string;
  end_url?: string;
  key: string;
  header_action: boolean;
  row_action: boolean;
}

interface Filters {
  field: string;
  type: string;
  action?: Action;
}

interface Props {
  hasDeleteButton: boolean;
  hasAddButton: boolean;
  tableName: string;
  filters: Filters[];
  onCreate: MouseEventHandler<HTMLButtonElement>;
}

const TableHeaderBar: FunctionComponent<Props> = (props) => {
  const [search, setSearch] = useState<any>('');
  const [filterSelected, setFilterSelected] = useState<string>(
    props.filters[0].field
  );
  const dispatch = useDispatch<any>();

  const getFilterAction = (filters: Filters[], fieldName: string) => {
    const filterObj = filters.find((filter) => filter.field === fieldName);
    return filterObj?.action;
  };

  const onDeleteButton = () => {
    // TODO: implement delete multiple items
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleFilterSelectOnChange = (event: SelectChangeEvent) => {
    setFilterSelected(event.target.value);
  };

  const handleFilterAction = () => {
    const action = getFilterAction(props.filters, filterSelected);
    if (action?.end_url) {
      dispatch(listBy(action.url, search, action.end_url));
    } else if (action?.url) {
      dispatch(listBy(action.url, search, ''));
    }
  };

  return (
    <div className="search_component">
      <div className="search_container">
        <div className="search_filter_container">
          <div className="search_bar_container">
            <FilterDropDown
              filters={props.filters}
              onChange={handleFilterSelectOnChange}
              state={filterSelected}
            />
            <div className="search_bar">
              <input
                className="input_search"
                name="search"
                onChange={handleSearch}
                type="text"
                value={search}
              />
            </div>
            <Button
              icon={<FilterAltIcon />}
              onClick={handleFilterAction}
              text=""
              type="filter"
            />
          </div>
        </div>
        <div className="buttons_container">
          {props.hasAddButton && (
            <Button
              icon={<AddIcon />}
              onClick={props.onCreate}
              text={`Add new ${props.tableName}`}
              type="add"
            />
          )}
          {props.hasDeleteButton && (
            <Button
              icon={<DeleteIcon />}
              onClick={onDeleteButton}
              text="Delete"
              type="delete"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TableHeaderBar;
