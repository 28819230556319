import React, { FunctionComponent } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const containerStyle = {
  width: '74%',
  margin: 0,
  padding: 0,
};

const inputStyle = {
  width: '100%',
  padding: 2.5,
  height: 20,
  '& MuiOutlinedInput-root': {
    borderRadius: 6,
    border: 'none',
  },
  '& .MuiOutlinedInput-root-MuiSelect-root': {},
};

interface Choice {
  value: string;
  display_name: string;
}

interface Props {
  className: string;
  onChange: any;
  value: any[];
  choices: Choice[];
}

const MultipleSelectInput: FunctionComponent<Props> = (props) => {
  let inputBackgroundColor;

  if (props.className === 'read_only_input') {
    inputBackgroundColor = '#dddddd';
  } else {
    inputBackgroundColor = '#f4f2ff';
  }

  return (
    <FormControl sx={containerStyle}>
      <InputLabel id='demo-select-small'>Select</InputLabel>
      <Select
        input={<OutlinedInput label='Tag' />}
        MenuProps={MenuProps}
        multiple
        onChange={props.onChange}
        renderValue={(selected) => selected.join(', ')}
        sx={{ ...inputStyle, backgroundColor: inputBackgroundColor }}
        value={props.value}
      >
        <MenuItem value='None'>
          <em>None</em>
        </MenuItem>
        {props.choices.map((choice: Choice) => (
          <MenuItem key={choice.value} value={choice.value}>
            <Checkbox checked={props.value.indexOf(choice.value) > -1} />
            <ListItemText primary={choice.display_name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default MultipleSelectInput;
