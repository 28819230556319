import React, {
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import DateTimePickerInput from '../UI/DateTimePickerInput';
import JsonInput from '../UI/jsonInput/JsonInput';
import MultipleSelectInput from '../UI/multiSelect/MultiSelectInput';
import SelectInput from '../UI/select/SelectInput';
import SwitchInput from '../UI/switch/SwitchInput';
import TextAreaInput from '../UI/TextAreaInput';
import './DetailField.css';

interface Choice {
  value: string;
  display_name: string;
}

interface Props {
  type: string;
  required: boolean;
  read_only: boolean;
  max_length?: number;
  label: string;
  choices: Choice[] | undefined;
  field: string;
  value: any;
  state: any;
  setState: SetStateAction<any>;
  detailViewPage: boolean;
  isNestedField: boolean;
  nestedFieldPropertyName: any;
}

const DetailField: FunctionComponent<Props> = (props) => {
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [required, setRequired] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const [empty, setEmpty] = useState<boolean>(false);
  const [maxLength, setMaxLength] = useState<boolean>(false);

  useEffect(() => {
    const checks = () => {
      if (props.read_only && props.detailViewPage) {
        setReadOnly(true);
        return;
      }
      if (props.required) {
        setRequired(true);
      }
    };
    checks();
  }, [props.detailViewPage, props.read_only, props.required]);

  // BOOLEAN
  const handleSwitchChange = (e: any, isNestedField: boolean) => {
    if (readOnly && !isNestedField) return;

    if (!readOnly && isNestedField) {
      props.setState({
        ...props.state,
        [props.field]: {
          ...props.state[props.field],
          [props.nestedFieldPropertyName]: e.target.checked,
        },
      });
    }

    if (!readOnly && !isNestedField) {
      props.setState({ ...props.state, [props.field]: e.target.checked });
    }

    setTouched(true);
  };

  // STRING
  const handleStringChange = (e: any, isNestedField: boolean) => {
    if (props.max_length && e.target.value.length > props.max_length) {
      setMaxLength(true);
      return;
    }

    if (!readOnly && !isNestedField) {
      props.setState({ ...props.state, [props.field]: e.target.value });
    }

    if (!readOnly && isNestedField) {
      props.setState({
        ...props.state,
        [props.field]: {
          ...props.state[props.field],
          [props.nestedFieldPropertyName]: e.target.value,
        },
      });
    }

    if (e.target.value.trim().length === 0 || e.target.value === '') {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
    setTouched(true);
  };

  // DATE
  const handleDateTimeChange = (date: Date, isNestedField: boolean) => {
    if (!readOnly && !isNestedField) {
      props.setState({
        ...props.state,
        [props.field]: date,
      });
    }

    if (!readOnly && isNestedField) {
      props.setState({
        ...props.state,
        [props.field]: {
          ...props.state[props.field],
          [props.nestedFieldPropertyName]: date,
        },
      });
    }
    setTouched(true);
  };

  // NUMBER
  const handleNumberChange = (e: any, isNestedField: boolean) => {
    if (props.max_length && e.target.value.length > props.max_length) {
      setMaxLength(true);
      return;
    }
    if (!readOnly && !isNestedField) {
      props.setState({ ...props.state, [props.field]: e.target.value });
    }

    if (!readOnly && isNestedField) {
      props.setState({
        ...props.state,
        [props.field]: {
          ...props.state[props.field],
          [props.nestedFieldPropertyName]: e.target.value,
        },
      });
    }

    if (e.target.value.trim().length === 0) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
    setTouched(true);
  };

  // SELECT
  const handleSelectChange = (e: any, isNestedField: boolean) => {
    // if the field is not read only and not a nested field, so add the new value to the main object to POST
    if (!readOnly && !isNestedField) {
      // For repeated values
      if (props.value && props.value.includes(e.target.value)) return;
      props.setState({ ...props.state, [props.field]: e.target.value });
    }

    // not readonly but nested field, so add the value to an object inside the main object (nested)
    if (!readOnly && isNestedField) {
      props.setState({
        ...props.state,
        [props.field]: {
          ...props.state[props.field],
          [props.nestedFieldPropertyName]: e.target.value,
        },
      });
    }

    // Check if the user leave the field empty
    if (e.target.value.trim().length === 0 || e.target.value === 'None') {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
    setTouched(true);
  };

  // MULTI SELECT
  const handleMultiSelectChange = (e: any, isNestedField: boolean) => {
    const { value } = e.target;
    const isThereNone = value.find((val: any) => val === 'None');
    if (isThereNone) {
      props.setState({ ...props.state, [props.field]: [] });
    } else {
      if (!readOnly && isNestedField) {
        props.setState({
          ...props.state,
          [props.field]: {
            ...props.state[props.field],
            [props.nestedFieldPropertyName]: [...value],
          },
        });
      }
      if (!readOnly && !isNestedField) {
        if (props.value.includes(e.target.value)) return;
        props.setState({ ...props.state, [props.field]: [...value] });
      }

      if (e.target.value.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    }
    setTouched(true);
  };

  const getInput = () => {
    switch (props.type) {
      case 'boolean':
        return (
          <SwitchInput
            checked={props.value}
            onChange={(e: any) => handleSwitchChange(e, props.isNestedField)}
          />
        );
      case 'string':
        return (
          <input
            className={readOnly ? 'read_only_input' : 'field_input'}
            onChange={(e: any) => handleStringChange(e, props.isNestedField)}
            value={props.value}
          />
        );
      case 'textarea':
        return (
          <TextAreaInput
            className={readOnly ? 'read_only_input' : 'field_input'}
            onChange={(e: any) => handleStringChange(e, props.isNestedField)}
            value={props.value}
          />
        );
      case 'datetime':
        return (
          <DateTimePickerInput
            className={readOnly ? 'read_only_input' : 'field_input'}
            label=""
            onChange={(date: Date) =>
              handleDateTimeChange(date, props.isNestedField)
            }
            value={props.value}
          />
        );
      case 'number':
        return (
          <input
            className={readOnly ? 'read_only_input' : 'field_input'}
            onChange={(e: any) => handleNumberChange(e, props.isNestedField)}
            value={props.value}
          />
        );
      case 'choice':
        return (
          <SelectInput
            choices={props.choices ? props.choices : []}
            className={readOnly ? 'read_only_input' : 'field_input'}
            onChange={(e: any) => handleSelectChange(e, props.isNestedField)}
            value={props.value}
          />
        );
      case 'multiple_choice':
        return (
          <MultipleSelectInput
            choices={props.choices ? props.choices : []}
            className={readOnly ? 'read_only_input' : 'field_input'}
            onChange={(e: any) =>
              handleMultiSelectChange(e, props.isNestedField)
            }
            value={props.value ? [...props.value] : []}
          />
        );
      case 'json':
        return (
          <JsonInput
            className={readOnly ? 'read_only_input' : 'field_input'}
            onChange={(e: any) => handleStringChange(e, props.isNestedField)}
            value={props.value}
          />
        );
      default:
        return (
          <input
            className={readOnly ? 'read_only_input' : 'input_field'}
            onChange={(e) => handleStringChange(e, props.isNestedField)}
            value={props.value}
          />
        );
    }
  };

  return (
    <div className="field_component">
      <div className="field_container_title">
        <p className="field_title">{props.label}</p>
      </div>

      <div className="field_container_input">
        <p className="field_text">{`${props.label} ${required ? '*' : ''}`}</p>
        {getInput()}
        {readOnly && touched && (
          <p className="read_only_text">This field is for read only</p>
        )}
        {empty && required && touched && (
          <p className="read_only_text">This field is required</p>
        )}
        {maxLength && !readOnly && (
          <p className="read_only_text">
            `The max length is ${props.max_length}`{' '}
          </p>
        )}
        <p className="field_description">
          Field description - Unix-like operating systems identify a user by a
          value called a user identifier, often abbreviated to user ID or UID.
          The UID, along with the group identifier and other access control
          criteria, is used to determine which system resources a user can
          access.{' '}
        </p>
      </div>
    </div>
  );
};

export default DetailField;
