import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import {
  getBrokerMicroserviceTables,
  getCarbonTables,
  getComputeTables,
  getMobileTables,
} from '../../store/actions/tables';

import './Dashboard.css';
import FilterWarning from '../../components/filterWarning/FilterWarning';
import Header from '../../components/header/Header';
import SideBar from '../../components/sideBar/SideBar';
import CreateView from '../createView/CreateView';
import DetailView from '../detailView/DetailView';
import TableView from '../tableView/TableView';
import Welcome from '../welcome/Welcome';

const Dashboard: FunctionComponent = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { ms } = useParams();

  const schema = useSelector((state: any) => state.schema);
  const { schemaTables, meta_data } = schema;

  const user = useSelector((state: any) => state.user);

  const callTable = () => {
    switch (ms) {
      case 'broker':
        return dispatch(getBrokerMicroserviceTables());
      case 'compute':
        return dispatch(getComputeTables());
      case 'carbon':
        return dispatch(getCarbonTables());
      case 'mobile':
        return dispatch(getMobileTables());
      default:
        return navigate('/');
    }
  };

  useEffect(() => {
    callTable();
    if (schemaTables.length === 0) {
      callTable();
    }
  }, [user]);

  return (
    <div className="dashboardPage">
      <Header user={user.userInfo} />
      <div className="flex-row">
        <SideBar data-testid="sidebar" tables={schemaTables} />
        <div className="flex-column">
          <Routes data-testid="routes">
            <Route
              element={
                <FilterWarning filter={false} text="404 page not found" />
              }
              path="*"
            />
            <Route
              element={
                <Welcome
                  description={meta_data.description}
                  image={meta_data.image}
                  title={meta_data.title}
                />
              }
              path="/"
            />
            {schemaTables &&
              schemaTables.map((table: any) => (
                <Route
                  element={
                    <TableView
                      actions={[...table.actions]}
                      filters={[...table.filters]}
                      table_columns={table.table_columns}
                      table_singular_name={table.singular_name}
                    />
                  }
                  key={table.name}
                  path={`${table.path}`}
                >
                  <Route
                    element={
                      <DetailView
                        actions={[...table.actions]}
                        fields={table.fields}
                        singular_name={table.singular_name}
                        table_name={table.name}
                      />
                    }
                    path=":key/*"
                  />

                  <Route
                    element={
                      <CreateView
                        actions={table.actions}
                        backend_url={table.backend_url}
                        fields={table.fields}
                        singular_name={table.singular_name}
                        table_name={table.name}
                      />
                    }
                    path="create"
                  />
                </Route>
              ))}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
