import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './Home.css';
import Grid from '@mui/material/Grid';

import Header from '../../components/header/Header';
import MicroserviceCard from '../../components/microserviceCard/MicroserviceCard';
import broker_svg from '../../svg/broker.svg';
import carbon_svg from '../../svg/carbon.svg';
import compute_svg from '../../svg/compute.svg';
import mobile_svg from '../../svg/mobile.svg';

const Home: FunctionComponent = () => {
  const navigate = useNavigate();

  const userReducer = useSelector((state: any) => state.user);

  const handleMicroservice = (ms: string) => {
    navigate(`${ms}/dashboard/`);
  };

  return (
    <div className="homePage">
      <Header data-testid="header" user={userReducer.userInfo} />
      <div className="productContainer">
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="center"
          spacing={1}
        >
          <Grid item>
            <MicroserviceCard
              button="View"
              data-testid="microServiceCard"
              description="Multi-engine database IDE with smart SQL editor and various
                           instruments. Works with any relational DB in a smart way. Try free
                           now! Cross-platform. Table data editor."
              image={broker_svg}
              onClick={() => handleMicroservice('broker')}
              title="Broker Microservice"
            />
          </Grid>
          <Grid item>
            <MicroserviceCard
              button="View"
              data-testid="microServiceCard"
              description="Multi-engine database IDE with smart SQL editor and various
                           instruments. Works with any relational DB in a smart way. Try free
                           now! Cross-platform. Table data editor."
              image={compute_svg}
              onClick={() => handleMicroservice('compute')}
              title="Compute Microservice"
            />
          </Grid>
          <Grid item>
            <MicroserviceCard
              button="View"
              data-testid="microServiceCard"
              description="Multi-engine database IDE with smart SQL editor and various
                           instruments. Works with any relational DB in a smart way. Try free
                           now! Cross-platform. Table data editor."
              image={carbon_svg}
              onClick={() => handleMicroservice('carbon')}
              title="Carbon Microservice"
            />
          </Grid>
          <Grid item>
            <MicroserviceCard
              button="View"
              data-testid="microServiceCard"
              description="Multi-engine database IDE with smart SQL editor and various
                           instruments. Works with any relational DB in a smart way. Try free
                           now! Cross-platform. Table data editor."
              image={mobile_svg}
              onClick={() => handleMicroservice('mobile')}
              title="Mobile Microservice"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Home;
