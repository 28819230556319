import compute_svg from '../svg/compute.svg';

export const COMPUTE_METADATA = {
  image: compute_svg,
  name: 'compute',
  title: 'Compute Microservice',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc consectetur, libero sit amet bibendum ultrices, odio nunc luctus lacus, ut commodo mauris eros eu velit. Cras mi ante, ullamcorper eget elit vel, varius efficitur est. Donec ut nibh sem. Quisque condimentum pulvinar quam non scelerisque. Etiam hendrerit odio in leo cursus sollicitudin. Nam fringilla, mi eu finibus luctus, ligula lectus bibendum neque, sed consectetur nulla magna non elit. ',
};

export const COMPUTE_TABLES = [
  {
    name: 'users',
    title: 'Users',
    singular_name: 'user',
    path: 'users',
    backend_url: 'userauth/users/',
    table_columns: ['id', 'email', 'auth0_app_id', 'is_staff'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'user',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'userauth/users/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'userauth/users/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'add',
        default_value: 'Group name',
        input_key: 'group_name',
        input_label: 'Add to group',
        url: 'userauth/users/',
        end_url: 'actions/add-to-group/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'remove',
        default_value: 'Group name',
        input_key: 'group_name',
        input_label: 'Remove from group',
        url: 'userauth/users/',
        end_url: 'actions/remove-from-group/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Id',
        create: false,
        max_length: 255,
      },
      auth0_id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Auth0 id',
        create: false,
        max_length: 255,
      },
      auth0_app_id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Auth0 app id',
        create: false,
        max_length: 255,
      },
      first_name: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Name',
        create: false,
        max_length: 255,
      },
      last_name: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Lastname',
        create: false,
        max_length: 255,
      },
      email: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Email',
        create: false,
        max_length: 255,
      },
      is_superuser: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is superuser',
        create: false,
        max_length: 255,
      },
      is_staff: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is staff',
        create: false,
        max_length: 255,
      },
      is_active: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is active',
        create: false,
        max_length: 255,
      },
      is_approved: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is approved',
        create: false,
        max_length: 255,
      },
      date_joined: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Date joined',
        create: false,
        max_length: 255,
      },
      groups: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Groups',
        table_name: 'groups',
        singular_name: 'group',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'groups/',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
        },
      },
      permissions: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Permissions',
        table_name: 'users',
        singular_name: 'Permission',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: false,
            url: '',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
          content_type: {
            type: 'string',
            label: 'Content type',
            pk: false,
            url: '',
          },
        },
      },
    },
  },
  {
    name: 'groups',
    title: 'Groups',
    singular_name: 'group',
    path: 'groups',
    backend_url: 'userauth/groups/',
    table_columns: ['id', 'name', 'undefined', 'undefined'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'group',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'userauth/groups/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'userauth/groups/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'add',
        default_value: 'User ID',
        input_key: 'user_id',
        input_label: 'Add user',
        url: 'userauth/groups/',
        end_url: 'actions/add-user/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'remove',
        default_value: 'User ID',
        input_key: 'user_id',
        input_label: 'Remove user',
        url: 'userauth/groups/',
        end_url: 'actions/remove-user/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Id',
        create: false,
        max_length: 255,
      },
      name: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Name',
        create: false,
        max_length: 255,
      },
      permissions: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Permissions',
        table_name: 'groups',
        singular_name: 'Permission',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: false,
            url: '',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
          content_type: {
            type: 'string',
            label: 'Content type',
            pk: false,
            url: '',
          },
        },
      },
    },
  },
  {
    name: 'trading-venues',
    title: 'Trading Venue',
    singular_name: 'Trading Venue',
    path: 'trading-venues',
    backend_url: 'market/internal/trading-venues/',
    table_columns: ['id', 'null', 'name', 'country'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'trading-venue',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'market/internal/trading-venues/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'market/internal/trading-venues/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'market/internal/trading-venues/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'market/internal/trading-venues/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'market/internal/trading-venues/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Id',
        create: false,
      },
      name: {
        type: 'string',
        required: true,
        read_only: false,
        label: 'Name',
        max_length: 50,
        create: true,
      },
      country: {
        type: 'choice',
        required: true,
        read_only: false,
        label: 'Country',
        create: true,
        choices: [
          {
            value: 'GB',
            display_name: 'United Kingdom',
          },
          {
            value: 'AF',
            display_name: 'Afghanistan',
          },
          {
            value: 'AX',
            display_name: 'Åland Islands',
          },
          {
            value: 'AL',
            display_name: 'Albania',
          },
          {
            value: 'DZ',
            display_name: 'Algeria',
          },
          {
            value: 'AS',
            display_name: 'American Samoa',
          },
          {
            value: 'AD',
            display_name: 'Andorra',
          },
          {
            value: 'AO',
            display_name: 'Angola',
          },
          {
            value: 'AI',
            display_name: 'Anguilla',
          },
          {
            value: 'AQ',
            display_name: 'Antarctica',
          },
          {
            value: 'AG',
            display_name: 'Antigua and Barbuda',
          },
          {
            value: 'AR',
            display_name: 'Argentina',
          },
          {
            value: 'AM',
            display_name: 'Armenia',
          },
          {
            value: 'AW',
            display_name: 'Aruba',
          },
          {
            value: 'AU',
            display_name: 'Australia',
          },
          {
            value: 'AT',
            display_name: 'Austria',
          },
          {
            value: 'AZ',
            display_name: 'Azerbaijan',
          },
          {
            value: 'BS',
            display_name: 'Bahamas',
          },
          {
            value: 'BH',
            display_name: 'Bahrain',
          },
          {
            value: 'BD',
            display_name: 'Bangladesh',
          },
          {
            value: 'BB',
            display_name: 'Barbados',
          },
          {
            value: 'BY',
            display_name: 'Belarus',
          },
          {
            value: 'BE',
            display_name: 'Belgium',
          },
          {
            value: 'BZ',
            display_name: 'Belize',
          },
          {
            value: 'BJ',
            display_name: 'Benin',
          },
          {
            value: 'BM',
            display_name: 'Bermuda',
          },
          {
            value: 'BT',
            display_name: 'Bhutan',
          },
          {
            value: 'BO',
            display_name: 'Bolivia',
          },
          {
            value: 'BQ',
            display_name: 'Bonaire, Sint Eustatius and Saba',
          },
          {
            value: 'BA',
            display_name: 'Bosnia and Herzegovina',
          },
          {
            value: 'BW',
            display_name: 'Botswana',
          },
          {
            value: 'BV',
            display_name: 'Bouvet Island',
          },
          {
            value: 'BR',
            display_name: 'Brazil',
          },
          {
            value: 'IO',
            display_name: 'British Indian Ocean Territory',
          },
          {
            value: 'BN',
            display_name: 'Brunei',
          },
          {
            value: 'BG',
            display_name: 'Bulgaria',
          },
          {
            value: 'BF',
            display_name: 'Burkina Faso',
          },
          {
            value: 'BI',
            display_name: 'Burundi',
          },
          {
            value: 'CV',
            display_name: 'Cabo Verde',
          },
          {
            value: 'KH',
            display_name: 'Cambodia',
          },
          {
            value: 'CM',
            display_name: 'Cameroon',
          },
          {
            value: 'CA',
            display_name: 'Canada',
          },
          {
            value: 'KY',
            display_name: 'Cayman Islands',
          },
          {
            value: 'CF',
            display_name: 'Central African Republic',
          },
          {
            value: 'TD',
            display_name: 'Chad',
          },
          {
            value: 'CL',
            display_name: 'Chile',
          },
          {
            value: 'CN',
            display_name: 'China',
          },
          {
            value: 'CX',
            display_name: 'Christmas Island',
          },
          {
            value: 'CC',
            display_name: 'Cocos (Keeling) Islands',
          },
          {
            value: 'CO',
            display_name: 'Colombia',
          },
          {
            value: 'KM',
            display_name: 'Comoros',
          },
          {
            value: 'CG',
            display_name: 'Congo',
          },
          {
            value: 'CD',
            display_name: 'Congo (the Democratic Republic of the)',
          },
          {
            value: 'CK',
            display_name: 'Cook Islands',
          },
          {
            value: 'CR',
            display_name: 'Costa Rica',
          },
          {
            value: 'CI',
            display_name: "Côte d'Ivoire",
          },
          {
            value: 'HR',
            display_name: 'Croatia',
          },
          {
            value: 'CU',
            display_name: 'Cuba',
          },
          {
            value: 'CW',
            display_name: 'Curaçao',
          },
          {
            value: 'CY',
            display_name: 'Cyprus',
          },
          {
            value: 'CZ',
            display_name: 'Czechia',
          },
          {
            value: 'DK',
            display_name: 'Denmark',
          },
          {
            value: 'DJ',
            display_name: 'Djibouti',
          },
          {
            value: 'DM',
            display_name: 'Dominica',
          },
          {
            value: 'DO',
            display_name: 'Dominican Republic',
          },
          {
            value: 'EC',
            display_name: 'Ecuador',
          },
          {
            value: 'EG',
            display_name: 'Egypt',
          },
          {
            value: 'SV',
            display_name: 'El Salvador',
          },
          {
            value: 'GQ',
            display_name: 'Equatorial Guinea',
          },
          {
            value: 'ER',
            display_name: 'Eritrea',
          },
          {
            value: 'EE',
            display_name: 'Estonia',
          },
          {
            value: 'SZ',
            display_name: 'Eswatini',
          },
          {
            value: 'ET',
            display_name: 'Ethiopia',
          },
          {
            value: 'FK',
            display_name: 'Falkland Islands (Malvinas)',
          },
          {
            value: 'FO',
            display_name: 'Faroe Islands',
          },
          {
            value: 'FJ',
            display_name: 'Fiji',
          },
          {
            value: 'FI',
            display_name: 'Finland',
          },
          {
            value: 'FR',
            display_name: 'France',
          },
          {
            value: 'GF',
            display_name: 'French Guiana',
          },
          {
            value: 'PF',
            display_name: 'French Polynesia',
          },
          {
            value: 'TF',
            display_name: 'French Southern Territories',
          },
          {
            value: 'GA',
            display_name: 'Gabon',
          },
          {
            value: 'GM',
            display_name: 'Gambia',
          },
          {
            value: 'GE',
            display_name: 'Georgia',
          },
          {
            value: 'DE',
            display_name: 'Germany',
          },
          {
            value: 'GH',
            display_name: 'Ghana',
          },
          {
            value: 'GI',
            display_name: 'Gibraltar',
          },
          {
            value: 'GR',
            display_name: 'Greece',
          },
          {
            value: 'GL',
            display_name: 'Greenland',
          },
          {
            value: 'GD',
            display_name: 'Grenada',
          },
          {
            value: 'GP',
            display_name: 'Guadeloupe',
          },
          {
            value: 'GU',
            display_name: 'Guam',
          },
          {
            value: 'GT',
            display_name: 'Guatemala',
          },
          {
            value: 'GG',
            display_name: 'Guernsey',
          },
          {
            value: 'GN',
            display_name: 'Guinea',
          },
          {
            value: 'GW',
            display_name: 'Guinea-Bissau',
          },
          {
            value: 'GY',
            display_name: 'Guyana',
          },
          {
            value: 'HT',
            display_name: 'Haiti',
          },
          {
            value: 'HM',
            display_name: 'Heard Island and McDonald Islands',
          },
          {
            value: 'VA',
            display_name: 'Holy See',
          },
          {
            value: 'HN',
            display_name: 'Honduras',
          },
          {
            value: 'HK',
            display_name: 'Hong Kong',
          },
          {
            value: 'HU',
            display_name: 'Hungary',
          },
          {
            value: 'IS',
            display_name: 'Iceland',
          },
          {
            value: 'IN',
            display_name: 'India',
          },
          {
            value: 'ID',
            display_name: 'Indonesia',
          },
          {
            value: 'IR',
            display_name: 'Iran',
          },
          {
            value: 'IQ',
            display_name: 'Iraq',
          },
          {
            value: 'IE',
            display_name: 'Ireland',
          },
          {
            value: 'IM',
            display_name: 'Isle of Man',
          },
          {
            value: 'IL',
            display_name: 'Israel',
          },
          {
            value: 'IT',
            display_name: 'Italy',
          },
          {
            value: 'JM',
            display_name: 'Jamaica',
          },
          {
            value: 'JP',
            display_name: 'Japan',
          },
          {
            value: 'JE',
            display_name: 'Jersey',
          },
          {
            value: 'JO',
            display_name: 'Jordan',
          },
          {
            value: 'KZ',
            display_name: 'Kazakhstan',
          },
          {
            value: 'KE',
            display_name: 'Kenya',
          },
          {
            value: 'KI',
            display_name: 'Kiribati',
          },
          {
            value: 'KW',
            display_name: 'Kuwait',
          },
          {
            value: 'KG',
            display_name: 'Kyrgyzstan',
          },
          {
            value: 'LA',
            display_name: 'Laos',
          },
          {
            value: 'LV',
            display_name: 'Latvia',
          },
          {
            value: 'LB',
            display_name: 'Lebanon',
          },
          {
            value: 'LS',
            display_name: 'Lesotho',
          },
          {
            value: 'LR',
            display_name: 'Liberia',
          },
          {
            value: 'LY',
            display_name: 'Libya',
          },
          {
            value: 'LI',
            display_name: 'Liechtenstein',
          },
          {
            value: 'LT',
            display_name: 'Lithuania',
          },
          {
            value: 'LU',
            display_name: 'Luxembourg',
          },
          {
            value: 'MO',
            display_name: 'Macao',
          },
          {
            value: 'MG',
            display_name: 'Madagascar',
          },
          {
            value: 'MW',
            display_name: 'Malawi',
          },
          {
            value: 'MY',
            display_name: 'Malaysia',
          },
          {
            value: 'MV',
            display_name: 'Maldives',
          },
          {
            value: 'ML',
            display_name: 'Mali',
          },
          {
            value: 'MT',
            display_name: 'Malta',
          },
          {
            value: 'MH',
            display_name: 'Marshall Islands',
          },
          {
            value: 'MQ',
            display_name: 'Martinique',
          },
          {
            value: 'MR',
            display_name: 'Mauritania',
          },
          {
            value: 'MU',
            display_name: 'Mauritius',
          },
          {
            value: 'YT',
            display_name: 'Mayotte',
          },
          {
            value: 'MX',
            display_name: 'Mexico',
          },
          {
            value: 'FM',
            display_name: 'Micronesia (Federated States of)',
          },
          {
            value: 'MD',
            display_name: 'Moldova',
          },
          {
            value: 'MC',
            display_name: 'Monaco',
          },
          {
            value: 'MN',
            display_name: 'Mongolia',
          },
          {
            value: 'ME',
            display_name: 'Montenegro',
          },
          {
            value: 'MS',
            display_name: 'Montserrat',
          },
          {
            value: 'MA',
            display_name: 'Morocco',
          },
          {
            value: 'MZ',
            display_name: 'Mozambique',
          },
          {
            value: 'MM',
            display_name: 'Myanmar',
          },
          {
            value: 'NA',
            display_name: 'Namibia',
          },
          {
            value: 'NR',
            display_name: 'Nauru',
          },
          {
            value: 'NP',
            display_name: 'Nepal',
          },
          {
            value: 'NL',
            display_name: 'Netherlands',
          },
          {
            value: 'NC',
            display_name: 'New Caledonia',
          },
          {
            value: 'NZ',
            display_name: 'New Zealand',
          },
          {
            value: 'NI',
            display_name: 'Nicaragua',
          },
          {
            value: 'NE',
            display_name: 'Niger',
          },
          {
            value: 'NG',
            display_name: 'Nigeria',
          },
          {
            value: 'NU',
            display_name: 'Niue',
          },
          {
            value: 'NF',
            display_name: 'Norfolk Island',
          },
          {
            value: 'KP',
            display_name: 'North Korea',
          },
          {
            value: 'MK',
            display_name: 'North Macedonia',
          },
          {
            value: 'MP',
            display_name: 'Northern Mariana Islands',
          },
          {
            value: 'NO',
            display_name: 'Norway',
          },
          {
            value: 'OM',
            display_name: 'Oman',
          },
          {
            value: 'PK',
            display_name: 'Pakistan',
          },
          {
            value: 'PW',
            display_name: 'Palau',
          },
          {
            value: 'PS',
            display_name: 'Palestine, State of',
          },
          {
            value: 'PA',
            display_name: 'Panama',
          },
          {
            value: 'PG',
            display_name: 'Papua New Guinea',
          },
          {
            value: 'PY',
            display_name: 'Paraguay',
          },
          {
            value: 'PE',
            display_name: 'Peru',
          },
          {
            value: 'PH',
            display_name: 'Philippines',
          },
          {
            value: 'PN',
            display_name: 'Pitcairn',
          },
          {
            value: 'PL',
            display_name: 'Poland',
          },
          {
            value: 'PT',
            display_name: 'Portugal',
          },
          {
            value: 'PR',
            display_name: 'Puerto Rico',
          },
          {
            value: 'QA',
            display_name: 'Qatar',
          },
          {
            value: 'RE',
            display_name: 'Réunion',
          },
          {
            value: 'RO',
            display_name: 'Romania',
          },
          {
            value: 'RU',
            display_name: 'Russia',
          },
          {
            value: 'RW',
            display_name: 'Rwanda',
          },
          {
            value: 'BL',
            display_name: 'Saint Barthélemy',
          },
          {
            value: 'SH',
            display_name: 'Saint Helena, Ascension and Tristan da Cunha',
          },
          {
            value: 'KN',
            display_name: 'Saint Kitts and Nevis',
          },
          {
            value: 'LC',
            display_name: 'Saint Lucia',
          },
          {
            value: 'MF',
            display_name: 'Saint Martin (French part)',
          },
          {
            value: 'PM',
            display_name: 'Saint Pierre and Miquelon',
          },
          {
            value: 'VC',
            display_name: 'Saint Vincent and the Grenadines',
          },
          {
            value: 'WS',
            display_name: 'Samoa',
          },
          {
            value: 'SM',
            display_name: 'San Marino',
          },
          {
            value: 'ST',
            display_name: 'Sao Tome and Principe',
          },
          {
            value: 'SA',
            display_name: 'Saudi Arabia',
          },
          {
            value: 'SN',
            display_name: 'Senegal',
          },
          {
            value: 'RS',
            display_name: 'Serbia',
          },
          {
            value: 'SC',
            display_name: 'Seychelles',
          },
          {
            value: 'SL',
            display_name: 'Sierra Leone',
          },
          {
            value: 'SG',
            display_name: 'Singapore',
          },
          {
            value: 'SX',
            display_name: 'Sint Maarten (Dutch part)',
          },
          {
            value: 'SK',
            display_name: 'Slovakia',
          },
          {
            value: 'SI',
            display_name: 'Slovenia',
          },
          {
            value: 'SB',
            display_name: 'Solomon Islands',
          },
          {
            value: 'SO',
            display_name: 'Somalia',
          },
          {
            value: 'ZA',
            display_name: 'South Africa',
          },
          {
            value: 'GS',
            display_name: 'South Georgia and the South Sandwich Islands',
          },
          {
            value: 'KR',
            display_name: 'South Korea',
          },
          {
            value: 'SS',
            display_name: 'South Sudan',
          },
          {
            value: 'ES',
            display_name: 'Spain',
          },
          {
            value: 'LK',
            display_name: 'Sri Lanka',
          },
          {
            value: 'SD',
            display_name: 'Sudan',
          },
          {
            value: 'SR',
            display_name: 'Suriname',
          },
          {
            value: 'SJ',
            display_name: 'Svalbard and Jan Mayen',
          },
          {
            value: 'SE',
            display_name: 'Sweden',
          },
          {
            value: 'CH',
            display_name: 'Switzerland',
          },
          {
            value: 'SY',
            display_name: 'Syria',
          },
          {
            value: 'TW',
            display_name: 'Taiwan',
          },
          {
            value: 'TJ',
            display_name: 'Tajikistan',
          },
          {
            value: 'TZ',
            display_name: 'Tanzania',
          },
          {
            value: 'TH',
            display_name: 'Thailand',
          },
          {
            value: 'TL',
            display_name: 'Timor-Leste',
          },
          {
            value: 'TG',
            display_name: 'Togo',
          },
          {
            value: 'TK',
            display_name: 'Tokelau',
          },
          {
            value: 'TO',
            display_name: 'Tonga',
          },
          {
            value: 'TT',
            display_name: 'Trinidad and Tobago',
          },
          {
            value: 'TN',
            display_name: 'Tunisia',
          },
          {
            value: 'TR',
            display_name: 'Turkey',
          },
          {
            value: 'TM',
            display_name: 'Turkmenistan',
          },
          {
            value: 'TC',
            display_name: 'Turks and Caicos Islands',
          },
          {
            value: 'TV',
            display_name: 'Tuvalu',
          },
          {
            value: 'UG',
            display_name: 'Uganda',
          },
          {
            value: 'UA',
            display_name: 'Ukraine',
          },
          {
            value: 'AE',
            display_name: 'United Arab Emirates',
          },
          {
            value: 'UM',
            display_name: 'United States Minor Outlying Islands',
          },
          {
            value: 'US',
            display_name: 'United States of America',
          },
          {
            value: 'UY',
            display_name: 'Uruguay',
          },
          {
            value: 'UZ',
            display_name: 'Uzbekistan',
          },
          {
            value: 'VU',
            display_name: 'Vanuatu',
          },
          {
            value: 'VE',
            display_name: 'Venezuela',
          },
          {
            value: 'VN',
            display_name: 'Vietnam',
          },
          {
            value: 'VG',
            display_name: 'Virgin Islands (British)',
          },
          {
            value: 'VI',
            display_name: 'Virgin Islands (U.S.)',
          },
          {
            value: 'WF',
            display_name: 'Wallis and Futuna',
          },
          {
            value: 'EH',
            display_name: 'Western Sahara',
          },
          {
            value: 'YE',
            display_name: 'Yemen',
          },
          {
            value: 'ZM',
            display_name: 'Zambia',
          },
          {
            value: 'ZW',
            display_name: 'Zimbabwe',
          },
        ],
      },
    },
  },
  {
    name: 'trading-venues-cross-references',
    title: 'T.V cross references',
    singular_name: 'T.V cross reference',
    path: 'trading-venue-cross-references',
    backend_url: 'market/internal/trading-venue-cross-references/',
    table_columns: ['provider', 'value', 'key', 'id'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'T.V cross refence',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'market/internal/trading-venue-cross-references/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'market/internal/trading-venue-cross-references/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'market/internal/trading-venue-cross-references/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'market/internal/trading-venue-cross-references/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'market/internal/trading-venue-cross-references/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Id',
      },
      trading_venue: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Trading venues',
        table_name: 'Tranding venues',
        singular_name: 'Tranding venue',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'trading-venues/',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
        },
      },
      trading_venue_id: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Trading venue id',
      },
      provider: {
        type: 'choice',
        required: true,
        read_only: false,
        label: 'Provider',
        create: true,
        choices: [
          {
            value: 'Factset',
            display_name: 'Factset',
          },
        ],
      },
      key: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Key',
        max_length: 50,
      },
      value: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Value',
        max_length: 255,
      },
    },
  },
  {
    name: 'instrument-type',
    title: 'Instrument types',
    singular_name: 'Instrument type',
    path: 'instrument-types',
    backend_url: 'market/internal/instrument-types/',
    table_columns: ['id', 'null', 'name', 'null'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'Instrument type',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'market/internal/instrument-types/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'market/internal/instrument-types/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Id',
        create: false,
        max_length: 255,
      },
      name: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Name',
        create: false,
        max_length: 255,
      },
      description: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Description',
        create: false,
        max_length: 255,
      },
    },
  },
  {
    name: 'instrument',
    title: 'Instruments',
    singular_name: 'Instrument',
    path: 'instruments',
    backend_url: 'market/internal/instruments/',
    table_columns: ['id', 'type.name', 'isin', 'active'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'Instrument type',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'market/internal/instruments/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'market/internal/instruments/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'market/internal/instruments/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'market/internal/instruments/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'market/internal/instruments/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        // TODO: implement this action
        httpMethod: 'POST',
        type: 'action',
        name: 'add',
        default_value: 'TODO: Check this action',
        input_key: 'user_id',
        input_label: 'Bulk create',
        url: 'market/internal/instruments/',
        end_url: 'actions/bulk-create/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Id',
        create: false,
        max_length: 255,
      },
      isin: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Isin',
        max_length: 12,
      },
      type: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Type',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'instrument-types/',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
        },
      },
      type_id: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Instrument type id',
      },
      static_data: {
        type: 'nested_field',
        required: false,
        read_only: false,
        create: false,
        label: 'Static data',
        children: {
          short_name: {
            type: 'string',
            required: false,
            read_only: false,
            create: false,
            label: 'Short name',
          },
          logo_url_large: {
            type: 'string',
            required: false,
            read_only: false,
            create: false,
            label: 'Logo url large',
          },
          logo_url_small: {
            type: 'string',
            required: false,
            read_only: false,
            create: false,
            label: 'Logo url small',
          },
          logo_url_medium: {
            type: 'string',
            required: false,
            read_only: false,
            create: false,
            label: 'Logo url medium',
          },
          logo_url_vector: {
            type: 'string',
            required: false,
            read_only: false,
            create: false,
            label: 'Logo url vector',
          },
          long_description: {
            type: 'string',
            required: false,
            read_only: false,
            create: false,
            label: 'Long description',
          },
          short_description: {
            type: 'string',
            required: false,
            read_only: false,
            create: false,
            label: 'Short description',
          },
        },
      },
      // TODO: implement flow options in create
      flow_options: {
        type: 'nested_field',
        required: false,
        read_only: false,
        create: false,
        label: 'Flow options',
        children: {
          trigger_flow: {
            type: 'boolean',
            required: true,
            read_only: false,
            create: false,
            label: 'Trigger flow',
          },
          ignore_unknown_venues: {
            type: 'boolean',
            required: false,
            read_only: false,
            create: false,
            label: 'Ignore unknown venues',
          },
          end_date: {
            type: 'date',
            required: false,
            read_only: false,
            label: 'End date',
          },
          start_date: {
            type: 'date',
            required: false,
            read_only: false,
            label: 'Start date',
          },
          instruments_batch_size: {
            type: 'integer',
            required: false,
            read_only: false,
            label: 'Instruments batch size',
            min_value: 1,
          },
          dates_batch_size: {
            type: 'integer',
            required: false,
            read_only: false,
            label: 'Dates batch size',
            min_value: 1,
          },
          activate_historical_constituents: {
            type: 'boolean',
            required: false,
            read_only: false,
            label: 'Activate historical constituents',
          },
        },
      },
      categories: {
        type: 'string',
        required: false,
        read_only: false,
        create: false,
        label: 'Categories',
      },
      active: {
        type: 'boolean',
        required: false,
        read_only: false,
        create: false,
        label: 'Active',
      },
    },
  },
  {
    name: 'EFT-contituents',
    title: 'EFT constituents',
    singular_name: 'EFT constituent',
    path: 'eft-constituents',
    backend_url: 'market/internal/etf-constituents/',
    table_columns: ['id', 'null', 'valuation_date', 'currency'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'Instrument type',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'market/internal/etf-constituents/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'market/internal/etf-constituents/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'market/internal/etf-constituents/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'market/internal/etf-constituents/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'market/internal/etf-constituents/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Id',
        create: false,
        max_length: 255,
      },
      etf: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'ETF',
        table_name: 'EFT constituents',
        singular_name: 'EFT constituent',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'instruments/',
          },
          isin: {
            type: 'string',
            label: 'ISIN',
            pk: false,
            url: '',
          },
          short_name: {
            type: 'string',
            label: 'Short name',
            pk: false,
            url: '',
          },
        },
      },
      etf_id: {
        type: 'string',
        required: true,
        read_only: false,
        label: 'ETF instrument id',
        create: true,
        max_length: 255,
      },
      constituent: {
        type: 'nested_object',
        required: false,
        read_only: true,
        label: 'Constituent',
        children: {
          id: {
            type: 'string',
            required: false,
            read_only: true,
            label: 'Id',
          },
          isin: {
            type: 'string',
            required: true,
            read_only: false,
            label: 'Isin',
            max_length: 12,
          },
          short_name: {
            type: 'field',
            required: false,
            read_only: true,
            label: 'Short name',
          },
        },
      },
      constituent_id: {
        type: 'string',
        required: true,
        read_only: false,
        label: 'Constituent id',
        create: true,
        max_length: 255,
      },
      data: {
        type: 'nested_field',
        required: true,
        read_only: false,
        create: true,
        label: 'Data',
        children: {
          adj_holding: {
            type: 'string',
            required: false,
            read_only: false,
            create: true,
            label: 'Adj holding',
          },
          adj_market_value: {
            type: 'string',
            required: false,
            read_only: false,
            create: true,
            label: 'Adj market value',
          },
          weight_close: {
            type: 'string',
            required: false,
            read_only: false,
            create: true,
            label: 'Weight close',
          },
        },
      },
      valuation_date: {
        type: 'string',
        required: true,
        read_only: false,
        label: 'Valuation date',
        create: true,
        max_length: 255,
      },
      currency: {
        type: 'choice',
        required: true,
        read_only: false,
        create: true,
        label: 'Currency',
        choices: [
          {
            value: 'GBP',
            display_name: 'Pound Sterling',
          },
          {
            value: 'EUR',
            display_name: 'Euro',
          },
          {
            value: 'AED',
            display_name: 'UAE Dirham',
          },
          {
            value: 'AFN',
            display_name: 'Afghani',
          },
          {
            value: 'ALL',
            display_name: 'Lek',
          },
          {
            value: 'AMD',
            display_name: 'Armenian Dram',
          },
          {
            value: 'ANG',
            display_name: 'Netherlands Antillean Guilder',
          },
          {
            value: 'AOA',
            display_name: 'Kwanza',
          },
          {
            value: 'ARS',
            display_name: 'Argentine Peso',
          },
          {
            value: 'AUD',
            display_name: 'Australian Dollar',
          },
          {
            value: 'AWG',
            display_name: 'Aruban Florin',
          },
          {
            value: 'AZN',
            display_name: 'Azerbaijanian Manat',
          },
          {
            value: 'BAM',
            display_name: 'Convertible Mark',
          },
          {
            value: 'BBD',
            display_name: 'Barbados Dollar',
          },
          {
            value: 'BDT',
            display_name: 'Taka',
          },
          {
            value: 'BGN',
            display_name: 'Bulgarian Lev',
          },
          {
            value: 'BHD',
            display_name: 'Bahraini Dinar',
          },
          {
            value: 'BIF',
            display_name: 'Burundi Franc',
          },
          {
            value: 'BMD',
            display_name: 'Bermudian Dollar',
          },
          {
            value: 'BND',
            display_name: 'Brunei Dollar',
          },
          {
            value: 'BOB',
            display_name: 'Boliviano',
          },
          {
            value: 'BRL',
            display_name: 'Brazilian Real',
          },
          {
            value: 'BSD',
            display_name: 'Bahamian Dollar',
          },
          {
            value: 'BTN',
            display_name: 'Ngultrum',
          },
          {
            value: 'BWP',
            display_name: 'Pula',
          },
          {
            value: 'BYN',
            display_name: 'Belarusian Ruble',
          },
          {
            value: 'BZD',
            display_name: 'Belize Dollar',
          },
          {
            value: 'CAD',
            display_name: 'Canadian Dollar',
          },
          {
            value: 'CDF',
            display_name: 'Congolese Franc',
          },
          {
            value: 'CHF',
            display_name: 'Swiss Franc',
          },
          {
            value: 'CLP',
            display_name: 'Chilean Peso',
          },
          {
            value: 'CNY',
            display_name: 'Yuan Renminbi',
          },
          {
            value: 'COP',
            display_name: 'Colombian Peso',
          },
          {
            value: 'CRC',
            display_name: 'Costa Rican Colon',
          },
          {
            value: 'CUC',
            display_name: 'Peso Convertible',
          },
          {
            value: 'CUP',
            display_name: 'Cuban Peso',
          },
          {
            value: 'CVE',
            display_name: 'Cabo Verde Escudo',
          },
          {
            value: 'CZK',
            display_name: 'Czech Koruna',
          },
          {
            value: 'DJF',
            display_name: 'Djibouti Franc',
          },
          {
            value: 'DKK',
            display_name: 'Danish Krone',
          },
          {
            value: 'DOP',
            display_name: 'Dominican Peso',
          },
          {
            value: 'DZD',
            display_name: 'Algerian Dinar',
          },
          {
            value: 'EGP',
            display_name: 'Egyptian Pound',
          },
          {
            value: 'ERN',
            display_name: 'Nakfa',
          },
          {
            value: 'ETB',
            display_name: 'Ethiopian Birr',
          },
          {
            value: 'FJD',
            display_name: 'Fiji Dollar',
          },
          {
            value: 'FKP',
            display_name: 'Falkland Islands Pound',
          },
          {
            value: 'GEL',
            display_name: 'Lari',
          },
          {
            value: 'GHS',
            display_name: 'Ghana Cedi',
          },
          {
            value: 'GIP',
            display_name: 'Gibraltar Pound',
          },
          {
            value: 'GMD',
            display_name: 'Dalasi',
          },
          {
            value: 'GNF',
            display_name: 'Guinea Franc',
          },
          {
            value: 'GTQ',
            display_name: 'Quetzal',
          },
          {
            value: 'GYD',
            display_name: 'Guyana Dollar',
          },
          {
            value: 'HKD',
            display_name: 'Hong Kong Dollar',
          },
          {
            value: 'HNL',
            display_name: 'Lempira',
          },
          {
            value: 'HRK',
            display_name: 'Kuna',
          },
          {
            value: 'HTG',
            display_name: 'Gourde',
          },
          {
            value: 'HUF',
            display_name: 'Forint',
          },
          {
            value: 'IDR',
            display_name: 'Rupiah',
          },
          {
            value: 'ILS',
            display_name: 'New Israeli Sheqel',
          },
          {
            value: 'INR',
            display_name: 'Indian Rupee',
          },
          {
            value: 'IQD',
            display_name: 'Iraqi Dinar',
          },
          {
            value: 'IRR',
            display_name: 'Iranian Rial',
          },
          {
            value: 'ISK',
            display_name: 'Iceland Krona',
          },
          {
            value: 'JMD',
            display_name: 'Jamaican Dollar',
          },
          {
            value: 'JOD',
            display_name: 'Jordanian Dinar',
          },
          {
            value: 'JPY',
            display_name: 'Yen',
          },
          {
            value: 'KES',
            display_name: 'Kenyan Shilling',
          },
          {
            value: 'KGS',
            display_name: 'Som',
          },
          {
            value: 'KHR',
            display_name: 'Riel',
          },
          {
            value: 'KMF',
            display_name: 'Comoro Franc',
          },
          {
            value: 'KPW',
            display_name: 'North Korean Won',
          },
          {
            value: 'KRW',
            display_name: 'Won',
          },
          {
            value: 'KWD',
            display_name: 'Kuwaiti Dinar',
          },
          {
            value: 'KYD',
            display_name: 'Cayman Islands Dollar',
          },
          {
            value: 'KZT',
            display_name: 'Tenge',
          },
          {
            value: 'LAK',
            display_name: 'Kip',
          },
          {
            value: 'LBP',
            display_name: 'Lebanese Pound',
          },
          {
            value: 'LKR',
            display_name: 'Sri Lanka Rupee',
          },
          {
            value: 'LRD',
            display_name: 'Liberian Dollar',
          },
          {
            value: 'LSL',
            display_name: 'Loti',
          },
          {
            value: 'LYD',
            display_name: 'Libyan Dinar',
          },
          {
            value: 'MAD',
            display_name: 'Moroccan Dirham',
          },
          {
            value: 'MDL',
            display_name: 'Moldovan Leu',
          },
          {
            value: 'MGA',
            display_name: 'Malagasy Ariary',
          },
          {
            value: 'MKD',
            display_name: 'Denar',
          },
          {
            value: 'MMK',
            display_name: 'Kyat',
          },
          {
            value: 'MNT',
            display_name: 'Tugrik',
          },
          {
            value: 'MOP',
            display_name: 'Pataca',
          },
          {
            value: 'MRO',
            display_name: 'Ouguiya',
          },
          {
            value: 'MUR',
            display_name: 'Mauritius Rupee',
          },
          {
            value: 'MVR',
            display_name: 'Rufiyaa',
          },
          {
            value: 'MWK',
            display_name: 'Malawi Kwacha',
          },
          {
            value: 'MXN',
            display_name: 'Mexican Peso',
          },
          {
            value: 'MYR',
            display_name: 'Malaysian Ringgit',
          },
          {
            value: 'MZN',
            display_name: 'Mozambique Metical',
          },
          {
            value: 'NAD',
            display_name: 'Namibia Dollar',
          },
          {
            value: 'NGN',
            display_name: 'Naira',
          },
          {
            value: 'NIO',
            display_name: 'Cordoba Oro',
          },
          {
            value: 'NOK',
            display_name: 'Norwegian Krone',
          },
          {
            value: 'NPR',
            display_name: 'Nepalese Rupee',
          },
          {
            value: 'NZD',
            display_name: 'New Zealand Dollar',
          },
          {
            value: 'OMR',
            display_name: 'Rial Omani',
          },
          {
            value: 'PAB',
            display_name: 'Balboa',
          },
          {
            value: 'PEN',
            display_name: 'Sol',
          },
          {
            value: 'PGK',
            display_name: 'Kina',
          },
          {
            value: 'PHP',
            display_name: 'Philippine Peso',
          },
          {
            value: 'PKR',
            display_name: 'Pakistan Rupee',
          },
          {
            value: 'PLN',
            display_name: 'Zloty',
          },
          {
            value: 'PYG',
            display_name: 'Guarani',
          },
          {
            value: 'QAR',
            display_name: 'Qatari Rial',
          },
          {
            value: 'RON',
            display_name: 'Romanian Leu',
          },
          {
            value: 'RSD',
            display_name: 'Serbian Dinar',
          },
          {
            value: 'RUB',
            display_name: 'Russian Ruble',
          },
          {
            value: 'RWF',
            display_name: 'Rwanda Franc',
          },
          {
            value: 'SAR',
            display_name: 'Saudi Riyal',
          },
          {
            value: 'SBD',
            display_name: 'Solomon Islands Dollar',
          },
          {
            value: 'SCR',
            display_name: 'Seychelles Rupee',
          },
          {
            value: 'SDG',
            display_name: 'Sudanese Pound',
          },
          {
            value: 'SEK',
            display_name: 'Swedish Krona',
          },
          {
            value: 'SGD',
            display_name: 'Singapore Dollar',
          },
          {
            value: 'SHP',
            display_name: 'Saint Helena Pound',
          },
          {
            value: 'SLL',
            display_name: 'Leone',
          },
          {
            value: 'SOS',
            display_name: 'Somali Shilling',
          },
          {
            value: 'SRD',
            display_name: 'Surinam Dollar',
          },
          {
            value: 'SSP',
            display_name: 'South Sudanese Pound',
          },
          {
            value: 'STD',
            display_name: 'Dobra',
          },
          {
            value: 'SVC',
            display_name: 'El Salvador Colon',
          },
          {
            value: 'SYP',
            display_name: 'Syrian Pound',
          },
          {
            value: 'SZL',
            display_name: 'Lilangeni',
          },
          {
            value: 'THB',
            display_name: 'Baht',
          },
          {
            value: 'TJS',
            display_name: 'Somoni',
          },
          {
            value: 'TMT',
            display_name: 'Turkmenistan New Manat',
          },
          {
            value: 'TND',
            display_name: 'Tunisian Dinar',
          },
          {
            value: 'TOP',
            display_name: 'Pa’anga',
          },
          {
            value: 'TRY',
            display_name: 'Turkish Lira',
          },
          {
            value: 'TTD',
            display_name: 'Trinidad and Tobago Dollar',
          },
          {
            value: 'TWD',
            display_name: 'New Taiwan Dollar',
          },
          {
            value: 'TZS',
            display_name: 'Tanzanian Shilling',
          },
          {
            value: 'UAH',
            display_name: 'Hryvnia',
          },
          {
            value: 'UGX',
            display_name: 'Uganda Shilling',
          },
          {
            value: 'USD',
            display_name: 'US Dollar',
          },
          {
            value: 'UYU',
            display_name: 'Peso Uruguayo',
          },
          {
            value: 'UZS',
            display_name: 'Uzbekistan Sum',
          },
          {
            value: 'VEF',
            display_name: 'Bolívar',
          },
          {
            value: 'VND',
            display_name: 'Dong',
          },
          {
            value: 'VUV',
            display_name: 'Vatu',
          },
          {
            value: 'WST',
            display_name: 'Tala',
          },
          {
            value: 'XAF',
            display_name: 'CFA Franc BEAC',
          },
          {
            value: 'XAG',
            display_name: 'Silver',
          },
          {
            value: 'XAU',
            display_name: 'Gold',
          },
          {
            value: 'XBA',
            display_name: 'Bond Markets Unit European Composite Unit (EURCO)',
          },
          {
            value: 'XBB',
            display_name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
          },
          {
            value: 'XBC',
            display_name:
              'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
          },
          {
            value: 'XBD',
            display_name:
              'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
          },
          {
            value: 'XCD',
            display_name: 'East Caribbean Dollar',
          },
          {
            value: 'XDR',
            display_name: 'SDR (Special Drawing Right)',
          },
          {
            value: 'XOF',
            display_name: 'CFA Franc BCEAO',
          },
          {
            value: 'XPD',
            display_name: 'Palladium',
          },
          {
            value: 'XPF',
            display_name: 'CFP Franc',
          },
          {
            value: 'XPT',
            display_name: 'Platinum',
          },
          {
            value: 'XSU',
            display_name: 'Sucre',
          },
          {
            value: 'XTS',
            display_name: 'Codes specifically reserved for testing purposes',
          },
          {
            value: 'XUA',
            display_name: 'ADB Unit of Account',
          },
          {
            value: 'XXX',
            display_name:
              'The codes assigned for transactions where no currency is involved',
          },
          {
            value: 'YER',
            display_name: 'Yemeni Rial',
          },
          {
            value: 'ZAR',
            display_name: 'Rand',
          },
          {
            value: 'ZMW',
            display_name: 'Zambian Kwacha',
          },
          {
            value: 'ZWL',
            display_name: 'Zimbabwe Dollar',
          },
        ],
      },
    },
  },
  {
    name: 'factset-identifiers',
    title: 'Factset identifiers',
    singular_name: 'Factset identifier',
    path: 'factset-identifiers',
    backend_url: 'market/internal/factset-identifiers/',
    table_columns: ['id', 'currency', 'conversion_factor', 'type'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'Instrument type',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'market/internal/factset-identifiers/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'market/internal/factset-identifiers/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'market/internal/factset-identifiers/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'market/internal/factset-identifiers/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'market/internal/factset-identifiers/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Id',
      },
      instrument: {
        type: 'nested_object',
        required: false,
        read_only: true,
        label: 'Instrument',
        table_name: 'instruments',
        singular_name: 'Instrument',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'instruments/',
          },
          isin: {
            type: 'string',
            label: 'ISIN',
            pk: false,
            url: '',
          },
          short_name: {
            type: 'string',
            label: 'Short name',
            pk: false,
            url: '',
          },
        },
      },
      instrument_id: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Instrument id',
      },
      trading_venue: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Trading venue',
        table_name: 'Trading venues',
        singular_name: 'Tranding venue',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'trading-venues/',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
        },
      },
      trading_venue_id: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Trading venue id',
      },
      currency: {
        type: 'choice',
        required: true,
        read_only: false,
        create: true,
        label: 'Currency',
        choices: [
          {
            value: 'GBP',
            display_name: 'Pound Sterling',
          },
          {
            value: 'EUR',
            display_name: 'Euro',
          },
          {
            value: 'AED',
            display_name: 'UAE Dirham',
          },
          {
            value: 'AFN',
            display_name: 'Afghani',
          },
          {
            value: 'ALL',
            display_name: 'Lek',
          },
          {
            value: 'AMD',
            display_name: 'Armenian Dram',
          },
          {
            value: 'ANG',
            display_name: 'Netherlands Antillean Guilder',
          },
          {
            value: 'AOA',
            display_name: 'Kwanza',
          },
          {
            value: 'ARS',
            display_name: 'Argentine Peso',
          },
          {
            value: 'AUD',
            display_name: 'Australian Dollar',
          },
          {
            value: 'AWG',
            display_name: 'Aruban Florin',
          },
          {
            value: 'AZN',
            display_name: 'Azerbaijanian Manat',
          },
          {
            value: 'BAM',
            display_name: 'Convertible Mark',
          },
          {
            value: 'BBD',
            display_name: 'Barbados Dollar',
          },
          {
            value: 'BDT',
            display_name: 'Taka',
          },
          {
            value: 'BGN',
            display_name: 'Bulgarian Lev',
          },
          {
            value: 'BHD',
            display_name: 'Bahraini Dinar',
          },
          {
            value: 'BIF',
            display_name: 'Burundi Franc',
          },
          {
            value: 'BMD',
            display_name: 'Bermudian Dollar',
          },
          {
            value: 'BND',
            display_name: 'Brunei Dollar',
          },
          {
            value: 'BOB',
            display_name: 'Boliviano',
          },
          {
            value: 'BRL',
            display_name: 'Brazilian Real',
          },
          {
            value: 'BSD',
            display_name: 'Bahamian Dollar',
          },
          {
            value: 'BTN',
            display_name: 'Ngultrum',
          },
          {
            value: 'BWP',
            display_name: 'Pula',
          },
          {
            value: 'BYN',
            display_name: 'Belarusian Ruble',
          },
          {
            value: 'BZD',
            display_name: 'Belize Dollar',
          },
          {
            value: 'CAD',
            display_name: 'Canadian Dollar',
          },
          {
            value: 'CDF',
            display_name: 'Congolese Franc',
          },
          {
            value: 'CHF',
            display_name: 'Swiss Franc',
          },
          {
            value: 'CLP',
            display_name: 'Chilean Peso',
          },
          {
            value: 'CNY',
            display_name: 'Yuan Renminbi',
          },
          {
            value: 'COP',
            display_name: 'Colombian Peso',
          },
          {
            value: 'CRC',
            display_name: 'Costa Rican Colon',
          },
          {
            value: 'CUC',
            display_name: 'Peso Convertible',
          },
          {
            value: 'CUP',
            display_name: 'Cuban Peso',
          },
          {
            value: 'CVE',
            display_name: 'Cabo Verde Escudo',
          },
          {
            value: 'CZK',
            display_name: 'Czech Koruna',
          },
          {
            value: 'DJF',
            display_name: 'Djibouti Franc',
          },
          {
            value: 'DKK',
            display_name: 'Danish Krone',
          },
          {
            value: 'DOP',
            display_name: 'Dominican Peso',
          },
          {
            value: 'DZD',
            display_name: 'Algerian Dinar',
          },
          {
            value: 'EGP',
            display_name: 'Egyptian Pound',
          },
          {
            value: 'ERN',
            display_name: 'Nakfa',
          },
          {
            value: 'ETB',
            display_name: 'Ethiopian Birr',
          },
          {
            value: 'FJD',
            display_name: 'Fiji Dollar',
          },
          {
            value: 'FKP',
            display_name: 'Falkland Islands Pound',
          },
          {
            value: 'GEL',
            display_name: 'Lari',
          },
          {
            value: 'GHS',
            display_name: 'Ghana Cedi',
          },
          {
            value: 'GIP',
            display_name: 'Gibraltar Pound',
          },
          {
            value: 'GMD',
            display_name: 'Dalasi',
          },
          {
            value: 'GNF',
            display_name: 'Guinea Franc',
          },
          {
            value: 'GTQ',
            display_name: 'Quetzal',
          },
          {
            value: 'GYD',
            display_name: 'Guyana Dollar',
          },
          {
            value: 'HKD',
            display_name: 'Hong Kong Dollar',
          },
          {
            value: 'HNL',
            display_name: 'Lempira',
          },
          {
            value: 'HRK',
            display_name: 'Kuna',
          },
          {
            value: 'HTG',
            display_name: 'Gourde',
          },
          {
            value: 'HUF',
            display_name: 'Forint',
          },
          {
            value: 'IDR',
            display_name: 'Rupiah',
          },
          {
            value: 'ILS',
            display_name: 'New Israeli Sheqel',
          },
          {
            value: 'INR',
            display_name: 'Indian Rupee',
          },
          {
            value: 'IQD',
            display_name: 'Iraqi Dinar',
          },
          {
            value: 'IRR',
            display_name: 'Iranian Rial',
          },
          {
            value: 'ISK',
            display_name: 'Iceland Krona',
          },
          {
            value: 'JMD',
            display_name: 'Jamaican Dollar',
          },
          {
            value: 'JOD',
            display_name: 'Jordanian Dinar',
          },
          {
            value: 'JPY',
            display_name: 'Yen',
          },
          {
            value: 'KES',
            display_name: 'Kenyan Shilling',
          },
          {
            value: 'KGS',
            display_name: 'Som',
          },
          {
            value: 'KHR',
            display_name: 'Riel',
          },
          {
            value: 'KMF',
            display_name: 'Comoro Franc',
          },
          {
            value: 'KPW',
            display_name: 'North Korean Won',
          },
          {
            value: 'KRW',
            display_name: 'Won',
          },
          {
            value: 'KWD',
            display_name: 'Kuwaiti Dinar',
          },
          {
            value: 'KYD',
            display_name: 'Cayman Islands Dollar',
          },
          {
            value: 'KZT',
            display_name: 'Tenge',
          },
          {
            value: 'LAK',
            display_name: 'Kip',
          },
          {
            value: 'LBP',
            display_name: 'Lebanese Pound',
          },
          {
            value: 'LKR',
            display_name: 'Sri Lanka Rupee',
          },
          {
            value: 'LRD',
            display_name: 'Liberian Dollar',
          },
          {
            value: 'LSL',
            display_name: 'Loti',
          },
          {
            value: 'LYD',
            display_name: 'Libyan Dinar',
          },
          {
            value: 'MAD',
            display_name: 'Moroccan Dirham',
          },
          {
            value: 'MDL',
            display_name: 'Moldovan Leu',
          },
          {
            value: 'MGA',
            display_name: 'Malagasy Ariary',
          },
          {
            value: 'MKD',
            display_name: 'Denar',
          },
          {
            value: 'MMK',
            display_name: 'Kyat',
          },
          {
            value: 'MNT',
            display_name: 'Tugrik',
          },
          {
            value: 'MOP',
            display_name: 'Pataca',
          },
          {
            value: 'MRO',
            display_name: 'Ouguiya',
          },
          {
            value: 'MUR',
            display_name: 'Mauritius Rupee',
          },
          {
            value: 'MVR',
            display_name: 'Rufiyaa',
          },
          {
            value: 'MWK',
            display_name: 'Malawi Kwacha',
          },
          {
            value: 'MXN',
            display_name: 'Mexican Peso',
          },
          {
            value: 'MYR',
            display_name: 'Malaysian Ringgit',
          },
          {
            value: 'MZN',
            display_name: 'Mozambique Metical',
          },
          {
            value: 'NAD',
            display_name: 'Namibia Dollar',
          },
          {
            value: 'NGN',
            display_name: 'Naira',
          },
          {
            value: 'NIO',
            display_name: 'Cordoba Oro',
          },
          {
            value: 'NOK',
            display_name: 'Norwegian Krone',
          },
          {
            value: 'NPR',
            display_name: 'Nepalese Rupee',
          },
          {
            value: 'NZD',
            display_name: 'New Zealand Dollar',
          },
          {
            value: 'OMR',
            display_name: 'Rial Omani',
          },
          {
            value: 'PAB',
            display_name: 'Balboa',
          },
          {
            value: 'PEN',
            display_name: 'Sol',
          },
          {
            value: 'PGK',
            display_name: 'Kina',
          },
          {
            value: 'PHP',
            display_name: 'Philippine Peso',
          },
          {
            value: 'PKR',
            display_name: 'Pakistan Rupee',
          },
          {
            value: 'PLN',
            display_name: 'Zloty',
          },
          {
            value: 'PYG',
            display_name: 'Guarani',
          },
          {
            value: 'QAR',
            display_name: 'Qatari Rial',
          },
          {
            value: 'RON',
            display_name: 'Romanian Leu',
          },
          {
            value: 'RSD',
            display_name: 'Serbian Dinar',
          },
          {
            value: 'RUB',
            display_name: 'Russian Ruble',
          },
          {
            value: 'RWF',
            display_name: 'Rwanda Franc',
          },
          {
            value: 'SAR',
            display_name: 'Saudi Riyal',
          },
          {
            value: 'SBD',
            display_name: 'Solomon Islands Dollar',
          },
          {
            value: 'SCR',
            display_name: 'Seychelles Rupee',
          },
          {
            value: 'SDG',
            display_name: 'Sudanese Pound',
          },
          {
            value: 'SEK',
            display_name: 'Swedish Krona',
          },
          {
            value: 'SGD',
            display_name: 'Singapore Dollar',
          },
          {
            value: 'SHP',
            display_name: 'Saint Helena Pound',
          },
          {
            value: 'SLL',
            display_name: 'Leone',
          },
          {
            value: 'SOS',
            display_name: 'Somali Shilling',
          },
          {
            value: 'SRD',
            display_name: 'Surinam Dollar',
          },
          {
            value: 'SSP',
            display_name: 'South Sudanese Pound',
          },
          {
            value: 'STD',
            display_name: 'Dobra',
          },
          {
            value: 'SVC',
            display_name: 'El Salvador Colon',
          },
          {
            value: 'SYP',
            display_name: 'Syrian Pound',
          },
          {
            value: 'SZL',
            display_name: 'Lilangeni',
          },
          {
            value: 'THB',
            display_name: 'Baht',
          },
          {
            value: 'TJS',
            display_name: 'Somoni',
          },
          {
            value: 'TMT',
            display_name: 'Turkmenistan New Manat',
          },
          {
            value: 'TND',
            display_name: 'Tunisian Dinar',
          },
          {
            value: 'TOP',
            display_name: 'Pa’anga',
          },
          {
            value: 'TRY',
            display_name: 'Turkish Lira',
          },
          {
            value: 'TTD',
            display_name: 'Trinidad and Tobago Dollar',
          },
          {
            value: 'TWD',
            display_name: 'New Taiwan Dollar',
          },
          {
            value: 'TZS',
            display_name: 'Tanzanian Shilling',
          },
          {
            value: 'UAH',
            display_name: 'Hryvnia',
          },
          {
            value: 'UGX',
            display_name: 'Uganda Shilling',
          },
          {
            value: 'USD',
            display_name: 'US Dollar',
          },
          {
            value: 'UYU',
            display_name: 'Peso Uruguayo',
          },
          {
            value: 'UZS',
            display_name: 'Uzbekistan Sum',
          },
          {
            value: 'VEF',
            display_name: 'Bolívar',
          },
          {
            value: 'VND',
            display_name: 'Dong',
          },
          {
            value: 'VUV',
            display_name: 'Vatu',
          },
          {
            value: 'WST',
            display_name: 'Tala',
          },
          {
            value: 'XAF',
            display_name: 'CFA Franc BEAC',
          },
          {
            value: 'XAG',
            display_name: 'Silver',
          },
          {
            value: 'XAU',
            display_name: 'Gold',
          },
          {
            value: 'XBA',
            display_name: 'Bond Markets Unit European Composite Unit (EURCO)',
          },
          {
            value: 'XBB',
            display_name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
          },
          {
            value: 'XBC',
            display_name:
              'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
          },
          {
            value: 'XBD',
            display_name:
              'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
          },
          {
            value: 'XCD',
            display_name: 'East Caribbean Dollar',
          },
          {
            value: 'XDR',
            display_name: 'SDR (Special Drawing Right)',
          },
          {
            value: 'XOF',
            display_name: 'CFA Franc BCEAO',
          },
          {
            value: 'XPD',
            display_name: 'Palladium',
          },
          {
            value: 'XPF',
            display_name: 'CFP Franc',
          },
          {
            value: 'XPT',
            display_name: 'Platinum',
          },
          {
            value: 'XSU',
            display_name: 'Sucre',
          },
          {
            value: 'XTS',
            display_name: 'Codes specifically reserved for testing purposes',
          },
          {
            value: 'XUA',
            display_name: 'ADB Unit of Account',
          },
          {
            value: 'XXX',
            display_name:
              'The codes assigned for transactions where no currency is involved',
          },
          {
            value: 'YER',
            display_name: 'Yemeni Rial',
          },
          {
            value: 'ZAR',
            display_name: 'Rand',
          },
          {
            value: 'ZMW',
            display_name: 'Zambian Kwacha',
          },
          {
            value: 'ZWL',
            display_name: 'Zimbabwe Dollar',
          },
        ],
      },
      conversion_factor: {
        type: 'number',
        required: false,
        read_only: false,
        create: true,
        label: 'Conversion factor',
      },
      value: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Value',
        max_length: 255,
      },
      type: {
        type: 'choice',
        required: true,
        read_only: false,
        create: true,
        label: 'Type',
        choices: [
          {
            value: 'primary',
            display_name: 'primary',
          },
          {
            value: 'notation',
            display_name: 'notation',
          },
          {
            value: 'fsym',
            display_name: 'fsym',
          },
        ],
      },
    },
  },
  {
    name: 'eod-prices',
    title: 'EOD prices',
    singular_name: 'EOD price',
    path: 'eod-prices',
    backend_url: 'market/internal/eod-prices/',
    table_columns: ['id', 'currency', 'quote_date', 'null'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'Instrument type',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'market/internal/eod-prices/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'market/internal/eod-prices/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'market/internal/eod-prices/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'market/internal/eod-prices/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'market/internal/eod-prices/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Id',
      },
      instrument: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Instrument',
        table_name: 'instruments',
        singular_name: 'Instrument',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'instruments/',
          },
          isin: {
            type: 'string',
            label: 'ISIN',
            pk: false,
            url: '',
          },
          short_name: {
            type: 'string',
            label: 'Short name',
            pk: false,
            url: '',
          },
        },
      },
      instrument_id: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Instrument id',
      },
      trading_venue: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Trading venue',
        table_name: 'Trading venues',
        singular_name: 'Tranding venue',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'trading-venues/',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
        },
      },
      trading_venue_id: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Trading venue id',
      },
      type_name: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Type name',
      },
      data: {
        type: 'nested_field',
        required: true,
        read_only: false,
        create: true,
        label: 'Data',
        children: {
          first: {
            type: 'number',
            required: true,
            read_only: false,
            create: true,
            label: 'First',
          },
          last: {
            type: 'number',
            required: true,
            read_only: false,
            create: true,
            label: 'Last',
          },
          low: {
            type: 'number',
            required: true,
            read_only: false,
            create: true,
            label: 'Low',
          },
          high: {
            type: 'number',
            required: true,
            read_only: false,
            create: true,
            label: 'High',
          },
          trading_volume: {
            type: 'number',
            required: true,
            read_only: false,
            create: true,
            label: 'Trading volume',
          },
          trading_value: {
            type: 'number',
            required: true,
            read_only: false,
            create: true,
            label: 'Trading value',
          },
        },
      },
      quote_date: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Quote date',
      },
      currency: {
        type: 'choice',
        required: true,
        read_only: false,
        create: true,
        label: 'Currency',
        choices: [
          {
            value: 'GBP',
            display_name: 'Pound Sterling',
          },
          {
            value: 'EUR',
            display_name: 'Euro',
          },
          {
            value: 'AED',
            display_name: 'UAE Dirham',
          },
          {
            value: 'AFN',
            display_name: 'Afghani',
          },
          {
            value: 'ALL',
            display_name: 'Lek',
          },
          {
            value: 'AMD',
            display_name: 'Armenian Dram',
          },
          {
            value: 'ANG',
            display_name: 'Netherlands Antillean Guilder',
          },
          {
            value: 'AOA',
            display_name: 'Kwanza',
          },
          {
            value: 'ARS',
            display_name: 'Argentine Peso',
          },
          {
            value: 'AUD',
            display_name: 'Australian Dollar',
          },
          {
            value: 'AWG',
            display_name: 'Aruban Florin',
          },
          {
            value: 'AZN',
            display_name: 'Azerbaijanian Manat',
          },
          {
            value: 'BAM',
            display_name: 'Convertible Mark',
          },
          {
            value: 'BBD',
            display_name: 'Barbados Dollar',
          },
          {
            value: 'BDT',
            display_name: 'Taka',
          },
          {
            value: 'BGN',
            display_name: 'Bulgarian Lev',
          },
          {
            value: 'BHD',
            display_name: 'Bahraini Dinar',
          },
          {
            value: 'BIF',
            display_name: 'Burundi Franc',
          },
          {
            value: 'BMD',
            display_name: 'Bermudian Dollar',
          },
          {
            value: 'BND',
            display_name: 'Brunei Dollar',
          },
          {
            value: 'BOB',
            display_name: 'Boliviano',
          },
          {
            value: 'BRL',
            display_name: 'Brazilian Real',
          },
          {
            value: 'BSD',
            display_name: 'Bahamian Dollar',
          },
          {
            value: 'BTN',
            display_name: 'Ngultrum',
          },
          {
            value: 'BWP',
            display_name: 'Pula',
          },
          {
            value: 'BYN',
            display_name: 'Belarusian Ruble',
          },
          {
            value: 'BZD',
            display_name: 'Belize Dollar',
          },
          {
            value: 'CAD',
            display_name: 'Canadian Dollar',
          },
          {
            value: 'CDF',
            display_name: 'Congolese Franc',
          },
          {
            value: 'CHF',
            display_name: 'Swiss Franc',
          },
          {
            value: 'CLP',
            display_name: 'Chilean Peso',
          },
          {
            value: 'CNY',
            display_name: 'Yuan Renminbi',
          },
          {
            value: 'COP',
            display_name: 'Colombian Peso',
          },
          {
            value: 'CRC',
            display_name: 'Costa Rican Colon',
          },
          {
            value: 'CUC',
            display_name: 'Peso Convertible',
          },
          {
            value: 'CUP',
            display_name: 'Cuban Peso',
          },
          {
            value: 'CVE',
            display_name: 'Cabo Verde Escudo',
          },
          {
            value: 'CZK',
            display_name: 'Czech Koruna',
          },
          {
            value: 'DJF',
            display_name: 'Djibouti Franc',
          },
          {
            value: 'DKK',
            display_name: 'Danish Krone',
          },
          {
            value: 'DOP',
            display_name: 'Dominican Peso',
          },
          {
            value: 'DZD',
            display_name: 'Algerian Dinar',
          },
          {
            value: 'EGP',
            display_name: 'Egyptian Pound',
          },
          {
            value: 'ERN',
            display_name: 'Nakfa',
          },
          {
            value: 'ETB',
            display_name: 'Ethiopian Birr',
          },
          {
            value: 'FJD',
            display_name: 'Fiji Dollar',
          },
          {
            value: 'FKP',
            display_name: 'Falkland Islands Pound',
          },
          {
            value: 'GEL',
            display_name: 'Lari',
          },
          {
            value: 'GHS',
            display_name: 'Ghana Cedi',
          },
          {
            value: 'GIP',
            display_name: 'Gibraltar Pound',
          },
          {
            value: 'GMD',
            display_name: 'Dalasi',
          },
          {
            value: 'GNF',
            display_name: 'Guinea Franc',
          },
          {
            value: 'GTQ',
            display_name: 'Quetzal',
          },
          {
            value: 'GYD',
            display_name: 'Guyana Dollar',
          },
          {
            value: 'HKD',
            display_name: 'Hong Kong Dollar',
          },
          {
            value: 'HNL',
            display_name: 'Lempira',
          },
          {
            value: 'HRK',
            display_name: 'Kuna',
          },
          {
            value: 'HTG',
            display_name: 'Gourde',
          },
          {
            value: 'HUF',
            display_name: 'Forint',
          },
          {
            value: 'IDR',
            display_name: 'Rupiah',
          },
          {
            value: 'ILS',
            display_name: 'New Israeli Sheqel',
          },
          {
            value: 'INR',
            display_name: 'Indian Rupee',
          },
          {
            value: 'IQD',
            display_name: 'Iraqi Dinar',
          },
          {
            value: 'IRR',
            display_name: 'Iranian Rial',
          },
          {
            value: 'ISK',
            display_name: 'Iceland Krona',
          },
          {
            value: 'JMD',
            display_name: 'Jamaican Dollar',
          },
          {
            value: 'JOD',
            display_name: 'Jordanian Dinar',
          },
          {
            value: 'JPY',
            display_name: 'Yen',
          },
          {
            value: 'KES',
            display_name: 'Kenyan Shilling',
          },
          {
            value: 'KGS',
            display_name: 'Som',
          },
          {
            value: 'KHR',
            display_name: 'Riel',
          },
          {
            value: 'KMF',
            display_name: 'Comoro Franc',
          },
          {
            value: 'KPW',
            display_name: 'North Korean Won',
          },
          {
            value: 'KRW',
            display_name: 'Won',
          },
          {
            value: 'KWD',
            display_name: 'Kuwaiti Dinar',
          },
          {
            value: 'KYD',
            display_name: 'Cayman Islands Dollar',
          },
          {
            value: 'KZT',
            display_name: 'Tenge',
          },
          {
            value: 'LAK',
            display_name: 'Kip',
          },
          {
            value: 'LBP',
            display_name: 'Lebanese Pound',
          },
          {
            value: 'LKR',
            display_name: 'Sri Lanka Rupee',
          },
          {
            value: 'LRD',
            display_name: 'Liberian Dollar',
          },
          {
            value: 'LSL',
            display_name: 'Loti',
          },
          {
            value: 'LYD',
            display_name: 'Libyan Dinar',
          },
          {
            value: 'MAD',
            display_name: 'Moroccan Dirham',
          },
          {
            value: 'MDL',
            display_name: 'Moldovan Leu',
          },
          {
            value: 'MGA',
            display_name: 'Malagasy Ariary',
          },
          {
            value: 'MKD',
            display_name: 'Denar',
          },
          {
            value: 'MMK',
            display_name: 'Kyat',
          },
          {
            value: 'MNT',
            display_name: 'Tugrik',
          },
          {
            value: 'MOP',
            display_name: 'Pataca',
          },
          {
            value: 'MRO',
            display_name: 'Ouguiya',
          },
          {
            value: 'MUR',
            display_name: 'Mauritius Rupee',
          },
          {
            value: 'MVR',
            display_name: 'Rufiyaa',
          },
          {
            value: 'MWK',
            display_name: 'Malawi Kwacha',
          },
          {
            value: 'MXN',
            display_name: 'Mexican Peso',
          },
          {
            value: 'MYR',
            display_name: 'Malaysian Ringgit',
          },
          {
            value: 'MZN',
            display_name: 'Mozambique Metical',
          },
          {
            value: 'NAD',
            display_name: 'Namibia Dollar',
          },
          {
            value: 'NGN',
            display_name: 'Naira',
          },
          {
            value: 'NIO',
            display_name: 'Cordoba Oro',
          },
          {
            value: 'NOK',
            display_name: 'Norwegian Krone',
          },
          {
            value: 'NPR',
            display_name: 'Nepalese Rupee',
          },
          {
            value: 'NZD',
            display_name: 'New Zealand Dollar',
          },
          {
            value: 'OMR',
            display_name: 'Rial Omani',
          },
          {
            value: 'PAB',
            display_name: 'Balboa',
          },
          {
            value: 'PEN',
            display_name: 'Sol',
          },
          {
            value: 'PGK',
            display_name: 'Kina',
          },
          {
            value: 'PHP',
            display_name: 'Philippine Peso',
          },
          {
            value: 'PKR',
            display_name: 'Pakistan Rupee',
          },
          {
            value: 'PLN',
            display_name: 'Zloty',
          },
          {
            value: 'PYG',
            display_name: 'Guarani',
          },
          {
            value: 'QAR',
            display_name: 'Qatari Rial',
          },
          {
            value: 'RON',
            display_name: 'Romanian Leu',
          },
          {
            value: 'RSD',
            display_name: 'Serbian Dinar',
          },
          {
            value: 'RUB',
            display_name: 'Russian Ruble',
          },
          {
            value: 'RWF',
            display_name: 'Rwanda Franc',
          },
          {
            value: 'SAR',
            display_name: 'Saudi Riyal',
          },
          {
            value: 'SBD',
            display_name: 'Solomon Islands Dollar',
          },
          {
            value: 'SCR',
            display_name: 'Seychelles Rupee',
          },
          {
            value: 'SDG',
            display_name: 'Sudanese Pound',
          },
          {
            value: 'SEK',
            display_name: 'Swedish Krona',
          },
          {
            value: 'SGD',
            display_name: 'Singapore Dollar',
          },
          {
            value: 'SHP',
            display_name: 'Saint Helena Pound',
          },
          {
            value: 'SLL',
            display_name: 'Leone',
          },
          {
            value: 'SOS',
            display_name: 'Somali Shilling',
          },
          {
            value: 'SRD',
            display_name: 'Surinam Dollar',
          },
          {
            value: 'SSP',
            display_name: 'South Sudanese Pound',
          },
          {
            value: 'STD',
            display_name: 'Dobra',
          },
          {
            value: 'SVC',
            display_name: 'El Salvador Colon',
          },
          {
            value: 'SYP',
            display_name: 'Syrian Pound',
          },
          {
            value: 'SZL',
            display_name: 'Lilangeni',
          },
          {
            value: 'THB',
            display_name: 'Baht',
          },
          {
            value: 'TJS',
            display_name: 'Somoni',
          },
          {
            value: 'TMT',
            display_name: 'Turkmenistan New Manat',
          },
          {
            value: 'TND',
            display_name: 'Tunisian Dinar',
          },
          {
            value: 'TOP',
            display_name: 'Pa’anga',
          },
          {
            value: 'TRY',
            display_name: 'Turkish Lira',
          },
          {
            value: 'TTD',
            display_name: 'Trinidad and Tobago Dollar',
          },
          {
            value: 'TWD',
            display_name: 'New Taiwan Dollar',
          },
          {
            value: 'TZS',
            display_name: 'Tanzanian Shilling',
          },
          {
            value: 'UAH',
            display_name: 'Hryvnia',
          },
          {
            value: 'UGX',
            display_name: 'Uganda Shilling',
          },
          {
            value: 'USD',
            display_name: 'US Dollar',
          },
          {
            value: 'UYU',
            display_name: 'Peso Uruguayo',
          },
          {
            value: 'UZS',
            display_name: 'Uzbekistan Sum',
          },
          {
            value: 'VEF',
            display_name: 'Bolívar',
          },
          {
            value: 'VND',
            display_name: 'Dong',
          },
          {
            value: 'VUV',
            display_name: 'Vatu',
          },
          {
            value: 'WST',
            display_name: 'Tala',
          },
          {
            value: 'XAF',
            display_name: 'CFA Franc BEAC',
          },
          {
            value: 'XAG',
            display_name: 'Silver',
          },
          {
            value: 'XAU',
            display_name: 'Gold',
          },
          {
            value: 'XBA',
            display_name: 'Bond Markets Unit European Composite Unit (EURCO)',
          },
          {
            value: 'XBB',
            display_name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
          },
          {
            value: 'XBC',
            display_name:
              'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
          },
          {
            value: 'XBD',
            display_name:
              'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
          },
          {
            value: 'XCD',
            display_name: 'East Caribbean Dollar',
          },
          {
            value: 'XDR',
            display_name: 'SDR (Special Drawing Right)',
          },
          {
            value: 'XOF',
            display_name: 'CFA Franc BCEAO',
          },
          {
            value: 'XPD',
            display_name: 'Palladium',
          },
          {
            value: 'XPF',
            display_name: 'CFP Franc',
          },
          {
            value: 'XPT',
            display_name: 'Platinum',
          },
          {
            value: 'XSU',
            display_name: 'Sucre',
          },
          {
            value: 'XTS',
            display_name: 'Codes specifically reserved for testing purposes',
          },
          {
            value: 'XUA',
            display_name: 'ADB Unit of Account',
          },
          {
            value: 'XXX',
            display_name:
              'The codes assigned for transactions where no currency is involved',
          },
          {
            value: 'YER',
            display_name: 'Yemeni Rial',
          },
          {
            value: 'ZAR',
            display_name: 'Rand',
          },
          {
            value: 'ZMW',
            display_name: 'Zambian Kwacha',
          },
          {
            value: 'ZWL',
            display_name: 'Zimbabwe Dollar',
          },
        ],
      },
    },
  },
  {
    name: 'fundamentals-data',
    title: 'Fundamentals data',
    singular_name: 'Fundamental data',
    path: 'fundamentals-datapoints',
    backend_url: 'market/internal/fundamentals-datapoints/',
    table_columns: ['id', 'periodicity', 'currency', 'fiscal_period'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'Instrument type',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'market/internal/fundamentals-datapoints/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'market/internal/fundamentals-datapoints/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'market/internal/fundamentals-datapoints/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'market/internal/fundamentals-datapoints/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'market/internal/fundamentals-datapoints/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        create: false,
        label: 'Id',
      },
      instrument: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Instrument',
        table_name: 'instruments',
        singular_name: 'Instrument',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'instruments/',
          },
          isin: {
            type: 'string',
            label: 'ISIN',
            pk: false,
            url: '',
          },
          short_name: {
            type: 'string',
            label: 'Short name',
            pk: false,
            url: '',
          },
        },
      },
      instrument_id: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Instrument id',
      },
      metrics: {
        type: 'nested_field',
        required: false,
        read_only: false,
        create: true,
        label: 'Metrics',
        children: {
          ff_pe: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff pe',
          },
          ff_pbk: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff pbk',
          },
          ff_eps: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff eps',
          },
          ff_com_shs_out: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff com shs out',
          },
          ff_div_yld: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff div yld',
          },
          ff_psales: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff psales',
          },
          ff_roe: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff roe',
          },
          ff_rotc: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff rotc',
          },
          ff_curr_ratio: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff curr ratio',
          },
          ff_quick_ratio: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff quick ratio',
          },
          ff_eps_dil: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff eps dil',
          },
          ff_debt_assets: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff debt assets',
          },
          ff_ebitda_oper: {
            type: 'number',
            required: false,
            read_only: false,
            create: true,
            label: 'Ff ebitda oper',
          },
        },
      },
      periodicity: {
        type: 'choice',
        required: true,
        read_only: false,
        create: true,
        label: 'Periodicity',
        choices: [
          {
            value: 'ANN',
            display_name: 'Annual',
          },
          {
            value: 'ANN_R',
            display_name: 'Annual - Includes restatements',
          },
          {
            value: 'QTR',
            display_name: 'Quarterly',
          },
          {
            value: 'QTR_R',
            display_name: 'Quarterly - Includes restatements',
          },
          {
            value: 'SEMI',
            display_name: 'Semi-Annual',
          },
          {
            value: 'LTM',
            display_name: 'Last Twelve Months',
          },
          {
            value: 'LTMSG',
            display_name: 'Last Twelve Months Global',
          },
          {
            value: 'YTD',
            display_name: 'Yea-to-date',
          },
        ],
      },
      fiscal_year: {
        type: 'number',
        required: true,
        read_only: false,
        create: true,
        label: 'Fiscal year',
        min_value: -2147483648,
        max_value: 2147483647,
      },
      fiscal_period: {
        type: 'number',
        required: true,
        read_only: false,
        create: true,
        label: 'Fiscal period',
        min_value: -2147483648,
        max_value: 2147483647,
      },
      fiscal_period_length: {
        type: 'number',
        required: false,
        read_only: false,
        create: true,
        label: 'Fiscal period length',
        min_value: -2147483648,
        max_value: 2147483647,
      },
      fiscal_end_date: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Fiscal end date',
      },
      currency: {
        type: 'choice',
        required: true,
        read_only: false,
        create: true,
        label: 'Currency',
        choices: [
          {
            value: 'GBP',
            display_name: 'Pound Sterling',
          },
          {
            value: 'EUR',
            display_name: 'Euro',
          },
          {
            value: 'AED',
            display_name: 'UAE Dirham',
          },
          {
            value: 'AFN',
            display_name: 'Afghani',
          },
          {
            value: 'ALL',
            display_name: 'Lek',
          },
          {
            value: 'AMD',
            display_name: 'Armenian Dram',
          },
          {
            value: 'ANG',
            display_name: 'Netherlands Antillean Guilder',
          },
          {
            value: 'AOA',
            display_name: 'Kwanza',
          },
          {
            value: 'ARS',
            display_name: 'Argentine Peso',
          },
          {
            value: 'AUD',
            display_name: 'Australian Dollar',
          },
          {
            value: 'AWG',
            display_name: 'Aruban Florin',
          },
          {
            value: 'AZN',
            display_name: 'Azerbaijanian Manat',
          },
          {
            value: 'BAM',
            display_name: 'Convertible Mark',
          },
          {
            value: 'BBD',
            display_name: 'Barbados Dollar',
          },
          {
            value: 'BDT',
            display_name: 'Taka',
          },
          {
            value: 'BGN',
            display_name: 'Bulgarian Lev',
          },
          {
            value: 'BHD',
            display_name: 'Bahraini Dinar',
          },
          {
            value: 'BIF',
            display_name: 'Burundi Franc',
          },
          {
            value: 'BMD',
            display_name: 'Bermudian Dollar',
          },
          {
            value: 'BND',
            display_name: 'Brunei Dollar',
          },
          {
            value: 'BOB',
            display_name: 'Boliviano',
          },
          {
            value: 'BRL',
            display_name: 'Brazilian Real',
          },
          {
            value: 'BSD',
            display_name: 'Bahamian Dollar',
          },
          {
            value: 'BTN',
            display_name: 'Ngultrum',
          },
          {
            value: 'BWP',
            display_name: 'Pula',
          },
          {
            value: 'BYN',
            display_name: 'Belarusian Ruble',
          },
          {
            value: 'BZD',
            display_name: 'Belize Dollar',
          },
          {
            value: 'CAD',
            display_name: 'Canadian Dollar',
          },
          {
            value: 'CDF',
            display_name: 'Congolese Franc',
          },
          {
            value: 'CHF',
            display_name: 'Swiss Franc',
          },
          {
            value: 'CLP',
            display_name: 'Chilean Peso',
          },
          {
            value: 'CNY',
            display_name: 'Yuan Renminbi',
          },
          {
            value: 'COP',
            display_name: 'Colombian Peso',
          },
          {
            value: 'CRC',
            display_name: 'Costa Rican Colon',
          },
          {
            value: 'CUC',
            display_name: 'Peso Convertible',
          },
          {
            value: 'CUP',
            display_name: 'Cuban Peso',
          },
          {
            value: 'CVE',
            display_name: 'Cabo Verde Escudo',
          },
          {
            value: 'CZK',
            display_name: 'Czech Koruna',
          },
          {
            value: 'DJF',
            display_name: 'Djibouti Franc',
          },
          {
            value: 'DKK',
            display_name: 'Danish Krone',
          },
          {
            value: 'DOP',
            display_name: 'Dominican Peso',
          },
          {
            value: 'DZD',
            display_name: 'Algerian Dinar',
          },
          {
            value: 'EGP',
            display_name: 'Egyptian Pound',
          },
          {
            value: 'ERN',
            display_name: 'Nakfa',
          },
          {
            value: 'ETB',
            display_name: 'Ethiopian Birr',
          },
          {
            value: 'FJD',
            display_name: 'Fiji Dollar',
          },
          {
            value: 'FKP',
            display_name: 'Falkland Islands Pound',
          },
          {
            value: 'GEL',
            display_name: 'Lari',
          },
          {
            value: 'GHS',
            display_name: 'Ghana Cedi',
          },
          {
            value: 'GIP',
            display_name: 'Gibraltar Pound',
          },
          {
            value: 'GMD',
            display_name: 'Dalasi',
          },
          {
            value: 'GNF',
            display_name: 'Guinea Franc',
          },
          {
            value: 'GTQ',
            display_name: 'Quetzal',
          },
          {
            value: 'GYD',
            display_name: 'Guyana Dollar',
          },
          {
            value: 'HKD',
            display_name: 'Hong Kong Dollar',
          },
          {
            value: 'HNL',
            display_name: 'Lempira',
          },
          {
            value: 'HRK',
            display_name: 'Kuna',
          },
          {
            value: 'HTG',
            display_name: 'Gourde',
          },
          {
            value: 'HUF',
            display_name: 'Forint',
          },
          {
            value: 'IDR',
            display_name: 'Rupiah',
          },
          {
            value: 'ILS',
            display_name: 'New Israeli Sheqel',
          },
          {
            value: 'INR',
            display_name: 'Indian Rupee',
          },
          {
            value: 'IQD',
            display_name: 'Iraqi Dinar',
          },
          {
            value: 'IRR',
            display_name: 'Iranian Rial',
          },
          {
            value: 'ISK',
            display_name: 'Iceland Krona',
          },
          {
            value: 'JMD',
            display_name: 'Jamaican Dollar',
          },
          {
            value: 'JOD',
            display_name: 'Jordanian Dinar',
          },
          {
            value: 'JPY',
            display_name: 'Yen',
          },
          {
            value: 'KES',
            display_name: 'Kenyan Shilling',
          },
          {
            value: 'KGS',
            display_name: 'Som',
          },
          {
            value: 'KHR',
            display_name: 'Riel',
          },
          {
            value: 'KMF',
            display_name: 'Comoro Franc',
          },
          {
            value: 'KPW',
            display_name: 'North Korean Won',
          },
          {
            value: 'KRW',
            display_name: 'Won',
          },
          {
            value: 'KWD',
            display_name: 'Kuwaiti Dinar',
          },
          {
            value: 'KYD',
            display_name: 'Cayman Islands Dollar',
          },
          {
            value: 'KZT',
            display_name: 'Tenge',
          },
          {
            value: 'LAK',
            display_name: 'Kip',
          },
          {
            value: 'LBP',
            display_name: 'Lebanese Pound',
          },
          {
            value: 'LKR',
            display_name: 'Sri Lanka Rupee',
          },
          {
            value: 'LRD',
            display_name: 'Liberian Dollar',
          },
          {
            value: 'LSL',
            display_name: 'Loti',
          },
          {
            value: 'LYD',
            display_name: 'Libyan Dinar',
          },
          {
            value: 'MAD',
            display_name: 'Moroccan Dirham',
          },
          {
            value: 'MDL',
            display_name: 'Moldovan Leu',
          },
          {
            value: 'MGA',
            display_name: 'Malagasy Ariary',
          },
          {
            value: 'MKD',
            display_name: 'Denar',
          },
          {
            value: 'MMK',
            display_name: 'Kyat',
          },
          {
            value: 'MNT',
            display_name: 'Tugrik',
          },
          {
            value: 'MOP',
            display_name: 'Pataca',
          },
          {
            value: 'MRO',
            display_name: 'Ouguiya',
          },
          {
            value: 'MUR',
            display_name: 'Mauritius Rupee',
          },
          {
            value: 'MVR',
            display_name: 'Rufiyaa',
          },
          {
            value: 'MWK',
            display_name: 'Malawi Kwacha',
          },
          {
            value: 'MXN',
            display_name: 'Mexican Peso',
          },
          {
            value: 'MYR',
            display_name: 'Malaysian Ringgit',
          },
          {
            value: 'MZN',
            display_name: 'Mozambique Metical',
          },
          {
            value: 'NAD',
            display_name: 'Namibia Dollar',
          },
          {
            value: 'NGN',
            display_name: 'Naira',
          },
          {
            value: 'NIO',
            display_name: 'Cordoba Oro',
          },
          {
            value: 'NOK',
            display_name: 'Norwegian Krone',
          },
          {
            value: 'NPR',
            display_name: 'Nepalese Rupee',
          },
          {
            value: 'NZD',
            display_name: 'New Zealand Dollar',
          },
          {
            value: 'OMR',
            display_name: 'Rial Omani',
          },
          {
            value: 'PAB',
            display_name: 'Balboa',
          },
          {
            value: 'PEN',
            display_name: 'Sol',
          },
          {
            value: 'PGK',
            display_name: 'Kina',
          },
          {
            value: 'PHP',
            display_name: 'Philippine Peso',
          },
          {
            value: 'PKR',
            display_name: 'Pakistan Rupee',
          },
          {
            value: 'PLN',
            display_name: 'Zloty',
          },
          {
            value: 'PYG',
            display_name: 'Guarani',
          },
          {
            value: 'QAR',
            display_name: 'Qatari Rial',
          },
          {
            value: 'RON',
            display_name: 'Romanian Leu',
          },
          {
            value: 'RSD',
            display_name: 'Serbian Dinar',
          },
          {
            value: 'RUB',
            display_name: 'Russian Ruble',
          },
          {
            value: 'RWF',
            display_name: 'Rwanda Franc',
          },
          {
            value: 'SAR',
            display_name: 'Saudi Riyal',
          },
          {
            value: 'SBD',
            display_name: 'Solomon Islands Dollar',
          },
          {
            value: 'SCR',
            display_name: 'Seychelles Rupee',
          },
          {
            value: 'SDG',
            display_name: 'Sudanese Pound',
          },
          {
            value: 'SEK',
            display_name: 'Swedish Krona',
          },
          {
            value: 'SGD',
            display_name: 'Singapore Dollar',
          },
          {
            value: 'SHP',
            display_name: 'Saint Helena Pound',
          },
          {
            value: 'SLL',
            display_name: 'Leone',
          },
          {
            value: 'SOS',
            display_name: 'Somali Shilling',
          },
          {
            value: 'SRD',
            display_name: 'Surinam Dollar',
          },
          {
            value: 'SSP',
            display_name: 'South Sudanese Pound',
          },
          {
            value: 'STD',
            display_name: 'Dobra',
          },
          {
            value: 'SVC',
            display_name: 'El Salvador Colon',
          },
          {
            value: 'SYP',
            display_name: 'Syrian Pound',
          },
          {
            value: 'SZL',
            display_name: 'Lilangeni',
          },
          {
            value: 'THB',
            display_name: 'Baht',
          },
          {
            value: 'TJS',
            display_name: 'Somoni',
          },
          {
            value: 'TMT',
            display_name: 'Turkmenistan New Manat',
          },
          {
            value: 'TND',
            display_name: 'Tunisian Dinar',
          },
          {
            value: 'TOP',
            display_name: 'Pa’anga',
          },
          {
            value: 'TRY',
            display_name: 'Turkish Lira',
          },
          {
            value: 'TTD',
            display_name: 'Trinidad and Tobago Dollar',
          },
          {
            value: 'TWD',
            display_name: 'New Taiwan Dollar',
          },
          {
            value: 'TZS',
            display_name: 'Tanzanian Shilling',
          },
          {
            value: 'UAH',
            display_name: 'Hryvnia',
          },
          {
            value: 'UGX',
            display_name: 'Uganda Shilling',
          },
          {
            value: 'USD',
            display_name: 'US Dollar',
          },
          {
            value: 'UYU',
            display_name: 'Peso Uruguayo',
          },
          {
            value: 'UZS',
            display_name: 'Uzbekistan Sum',
          },
          {
            value: 'VEF',
            display_name: 'Bolívar',
          },
          {
            value: 'VND',
            display_name: 'Dong',
          },
          {
            value: 'VUV',
            display_name: 'Vatu',
          },
          {
            value: 'WST',
            display_name: 'Tala',
          },
          {
            value: 'XAF',
            display_name: 'CFA Franc BEAC',
          },
          {
            value: 'XAG',
            display_name: 'Silver',
          },
          {
            value: 'XAU',
            display_name: 'Gold',
          },
          {
            value: 'XBA',
            display_name: 'Bond Markets Unit European Composite Unit (EURCO)',
          },
          {
            value: 'XBB',
            display_name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
          },
          {
            value: 'XBC',
            display_name:
              'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
          },
          {
            value: 'XBD',
            display_name:
              'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
          },
          {
            value: 'XCD',
            display_name: 'East Caribbean Dollar',
          },
          {
            value: 'XDR',
            display_name: 'SDR (Special Drawing Right)',
          },
          {
            value: 'XOF',
            display_name: 'CFA Franc BCEAO',
          },
          {
            value: 'XPD',
            display_name: 'Palladium',
          },
          {
            value: 'XPF',
            display_name: 'CFP Franc',
          },
          {
            value: 'XPT',
            display_name: 'Platinum',
          },
          {
            value: 'XSU',
            display_name: 'Sucre',
          },
          {
            value: 'XTS',
            display_name: 'Codes specifically reserved for testing purposes',
          },
          {
            value: 'XUA',
            display_name: 'ADB Unit of Account',
          },
          {
            value: 'XXX',
            display_name:
              'The codes assigned for transactions where no currency is involved',
          },
          {
            value: 'YER',
            display_name: 'Yemeni Rial',
          },
          {
            value: 'ZAR',
            display_name: 'Rand',
          },
          {
            value: 'ZMW',
            display_name: 'Zambian Kwacha',
          },
          {
            value: 'ZWL',
            display_name: 'Zimbabwe Dollar',
          },
        ],
      },
      update_type: {
        type: 'number',
        required: false,
        read_only: false,
        create: true,
        label: 'Update type',
        min_value: -2147483648,
        max_value: 2147483647,
      },
      update_status: {
        type: 'string',
        required: false,
        read_only: false,
        create: true,
        label: 'Update status',
        max_length: 2,
      },
      report_date: {
        type: 'string',
        required: false,
        read_only: false,
        create: true,
        label: 'Report date',
      },
      eps_report_date: {
        type: 'string',
        required: false,
        read_only: false,
        create: true,
        label: 'Eps report date',
      },
    },
  },
];
