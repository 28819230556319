import mobile_svg from '../svg/mobile.svg';

export const MOBILE_METADATA = {
  image: mobile_svg,
  name: 'mobile',
  title: 'Mobile Microservice',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc consectetur, libero sit amet bibendum ultrices, odio nunc luctus lacus, ut commodo mauris eros eu velit. Cras mi ante, ullamcorper eget elit vel, varius efficitur est. Donec ut nibh sem. Quisque condimentum pulvinar quam non scelerisque. Etiam hendrerit odio in leo cursus sollicitudin. Nam fringilla, mi eu finibus luctus, ligula lectus bibendum neque, sed consectetur nulla magna non elit. ',
};

export const MOBILE_TABLES = [
  {
    name: 'users',
    title: 'Users',
    singular_name: 'user',
    path: 'users',
    backend_url: 'userauth/users/',
    table_columns: ['id', 'email', 'auth0_app_id', 'is_staff'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'user',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'userauth/users/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'userauth/users/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'add',
        default_value: 'Group name',
        input_key: 'group_name',
        input_label: 'Add to group',
        url: 'userauth/users/',
        end_url: 'actions/add-to-group/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'remove',
        default_value: 'Group name',
        input_key: 'group_name',
        input_label: 'Remove from group',
        url: 'userauth/users/',
        end_url: 'actions/remove-from-group/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'Approve',
        url: 'actions/approve/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'Unapprove',
        url: 'actions/disapprove/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Id',
        create: false,
        max_length: 255,
      },
      auth0_id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Auth0 id',
        create: false,
        max_length: 255,
      },
      auth0_app_id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Auth0 app id',
        create: false,
        max_length: 255,
      },
      first_name: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Name',
        create: false,
        max_length: 255,
      },
      last_name: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Lastname',
        create: false,
        max_length: 255,
      },
      email: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Email',
        create: false,
        max_length: 255,
      },
      is_superuser: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is superuser',
        create: false,
        max_length: 255,
      },
      is_staff: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is staff',
        create: false,
        max_length: 255,
      },
      is_active: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is active',
        create: false,
        max_length: 255,
      },
      is_approved: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Is approved',
        create: false,
        max_length: 255,
      },
      date_joined: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Date joined',
        create: false,
        max_length: 255,
      },
      groups: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Groups',
        table_name: 'groups',
        singular_name: 'group',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: true,
            url: 'groups/',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
        },
      },
      permissions: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Permissions',
        table_name: 'users',
        singular_name: 'Permission',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: false,
            url: '',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
          content_type: {
            type: 'string',
            label: 'Content type',
            pk: false,
            url: '',
          },
        },
      },
    },
  },
  {
    name: 'groups',
    title: 'Groups',
    singular_name: 'group',
    path: 'groups',
    backend_url: 'userauth/groups/',
    table_columns: ['id', 'name', 'undefined', 'undefined'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'group',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'userauth/groups/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'userauth/groups/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'add',
        default_value: 'User ID',
        input_key: 'user_id',
        input_label: 'Add user',
        url: 'userauth/groups/',
        end_url: 'actions/add-user/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'remove',
        default_value: 'User ID',
        input_key: 'user_id',
        input_label: 'Remove user',
        url: 'userauth/groups/',
        end_url: 'actions/remove-user/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Id',
        create: false,
        max_length: 255,
      },
      name: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Name',
        create: false,
        max_length: 255,
      },
      permissions: {
        type: 'nested_object',
        required: false,
        read_only: true,
        create: false,
        label: 'Permissions',
        table_name: 'groups',
        singular_name: 'Permission',
        children: {
          id: {
            type: 'string',
            label: 'Id',
            pk: false,
            url: '',
          },
          name: {
            type: 'string',
            label: 'Name',
            pk: false,
            url: '',
          },
          content_type: {
            type: 'string',
            label: 'Content type',
            pk: false,
            url: '',
          },
        },
      },
    },
  },
  {
    name: 'enlisted-persons',
    title: 'Enlisted persons',
    singular_name: 'enlisted person',
    path: 'enlisted-persons',
    backend_url: 'internal/enlisted-persons/',
    table_columns: ['id', 'email', 'promo_code', 'status'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'internal/enlisted-persons/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'internal/enlisted-persons/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'add',
        default_value: 'List name',
        input_key: 'list_name',
        input_label: 'Add to list',
        url: 'internal/enlisted-persons/',
        end_url: 'actions/add-to-list/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
      {
        httpMethod: 'POST',
        type: 'action',
        name: 'remove',
        default_value: 'List name',
        input_key: 'list_name',
        input_label: 'Remove from list',
        url: 'internal/enlisted-persons/',
        end_url: 'actions/remove-from-list/',
        key: 'id',
        header_action: false,
        row_action: false,
        description:
          'Unix-like operating systems identify a user by a value called a user identifier, often access',
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        label: 'Id',
      },
      lists: {
        type: 'field',
        required: false,
        read_only: true,
        label: 'Lists',
      },
      created_at: {
        type: 'datetime',
        required: false,
        read_only: true,
        label: 'Created at',
      },
      user_id: {
        type: 'string',
        required: false,
        read_only: false,
        label: 'User id',
      },
      email: {
        type: 'email',
        required: false,
        read_only: false,
        create: true,
        label: 'Email',
        max_length: 254,
      },
      promo_code: {
        type: 'string',
        required: false,
        read_only: false,
        label: 'Promo code',
        max_length: 255,
      },
      status: {
        type: 'choice',
        required: false,
        read_only: false,
        create: true,
        label: 'Status',
        choices: [
          {
            value: 'active',
            display_name: 'active',
          },
          {
            value: 'inactive',
            display_name: 'inactive',
          },
        ],
      },
    },
  },
  {
    name: 'people-lists',
    title: 'People lists',
    singular_name: 'group',
    path: 'people-lists',
    backend_url: 'internal/people-lists/',
    table_columns: ['name', 'id', 'title', 'null'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'internal/people-lists/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'internal/people-lists/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Id',
        create: false,
        max_length: 255,
      },
      name: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Name',
        create: false,
        max_length: 255,
      },
      title: {
        type: 'string',
        required: true,
        read_only: true,
        label: 'Title',
        create: false,
        max_length: 255,
      },
      description: {
        type: 'description',
        required: true,
        read_only: true,
        label: 'Description',
        create: false,
        max_length: 255,
      },
    },
  },
  {
    name: 'subscription-topics',
    title: 'Subscription topics',
    singular_name: 'Subscription topic',
    path: 'subscription-topics',
    backend_url: 'internal/subscription-topics/',
    table_columns: ['id', 'topic', 'description', 'restricted'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'group',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'internal/subscription-topics/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'internal/subscription-topics/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'internal/subscription-topics/',
        key: '',
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'internal/subscription-topics/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'internal/subscription-topics/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
    ],
    fields: {
      id: {
        type: 'string',
        required: false,
        read_only: true,
        create: true,
        label: 'Id',
      },
      topic: {
        type: 'string',
        required: true,
        read_only: false,
        create: true,
        label: 'Topic',
        max_length: 255,
      },
      description: {
        type: 'texttarea',
        required: true,
        read_only: false,
        create: true,
        label: 'Description',
      },
      restricted: {
        type: 'boolean',
        required: true,
        read_only: false,
        create: true,
        label: 'Restricted',
      },
    },
  },
  {
    name: 'strativersity-contents',
    title: 'Strativersity contents',
    singular_name: 'strativersity content',
    path: 'strativersity-contents',
    backend_url: 'internal/strativersity-contents/',
    table_columns: ['id', 'on_display', 'created_at', 'updated_at'],
    pagination_enabled: true,
    filters: [
      {
        field: 'id',
        type: 'exact',
      },
      {
        field: 'group',
        type: 'exact',
      },
    ],
    actions: [
      {
        httpMethod: 'GET',
        type: 'list',
        name: 'list',
        url: 'internal/strativersity-contents/',
        key: '',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'GET',
        type: 'retrieve',
        name: 'retrieve',
        url: 'internal/strativersity-contents/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
      {
        httpMethod: 'POST',
        type: 'create',
        name: 'create',
        url: 'internal/strativersity-contents/',
        key: '',
        json: true,
        header_action: true,
        row_action: false,
      },
      {
        httpMethod: 'PATCH',
        type: 'update',
        name: 'update',
        url: 'internal/strativersity-contents/',
        key: 'id',
        json: true,
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'DELETE',
        type: 'delete',
        name: 'delete',
        url: 'internal/strativersity-contents/',
        key: 'id',
        header_action: false,
        row_action: true,
      },
      {
        httpMethod: 'POST',
        type: 'button_action',
        name: 'put on display',
        url: 'internal/strativersity-contents/',
        end_url: 'actions/put-on-display/',
        key: 'id',
        header_action: false,
        row_action: false,
      },
    ],
    fields: {
      json_text: {
        type: 'json',
        required: false,
        read_only: false,
        create: true,
        label: 'Content (JSON format)',
      },
    },
  },
];
