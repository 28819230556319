import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import {
  buttonActionReducer,
  cancelReducer,
  createReducer,
  deleteReducer,
  elementByIdReducer,
  fieldActionReducer,
  listReducer,
  updateReducer,
} from './reducers/actions';
import { getFieldsReducer } from './reducers/options';
import { schemaReducer } from './reducers/tables';
import { userLoginReducer, userTokenReducer } from './reducers/user';

const reducer = combineReducers({
  // user
  user: userLoginReducer,
  userToken: userTokenReducer,

  // tables
  schema: schemaReducer,

  // actions
  list: listReducer,
  elementById: elementByIdReducer,
  update: updateReducer,
  create: createReducer,
  cancel: cancelReducer,
  delete: deleteReducer,
  fieldAction: fieldActionReducer,
  buttonAction: buttonActionReducer,

  // for options
  fields: getFieldsReducer,
});

const userFromStorage = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user') || '')
  : {};

const userTokenFromStorage = localStorage.getItem('token')
  ? JSON.parse(localStorage.getItem('token') || '')
  : null;

const initialState = {
  user: { userInfo: { ...userFromStorage } },
  userToken: { token: userTokenFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
