import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { getFieldObj } from '../../utils/utils';

import './ObjectCard.css';

interface Props {
  fieldFromSchema: any;
  data: any;
}

const ObjectCard: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();

  const nestedNavigation = (url: string, key: any) => {
    navigate(`../../${url}${key}`, { replace: false });
  };

  return (
    <div className="cardLink_component">
      <div className="cardLink_container">
        {Object.keys(props.fieldFromSchema.children).map((key: any) => {
          const nestedField = getFieldObj(props.fieldFromSchema.children, key);
          return (
            <div className="cardLink" key={nestedField.label}>
              <p className="cardLink_key">{`${nestedField.label}:`}</p>
              {nestedField.pk ? (
                <p
                  className="pk_link"
                  onClick={() =>
                    nestedNavigation(nestedField.url, props.data[key])
                  }
                >
                  {props.data[key]}
                </p>
              ) : (
                <p className="cardLink_value">
                  {props.data[key] ? props.data[key] : 'null'}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ObjectCard;
