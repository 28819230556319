import React, { FunctionComponent } from 'react';

import FormControl from '@mui/material/FormControl';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const containerStyle = {
  width: '74%',
  margin: 0,
  padding: 0,
};

const inputStyle = {
  width: '100%',
  borderRadius: 6,
  border: 'none',
  padding: 10,
  fontSize: 13,
  fontWeight: '550',

  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",

  '& .MuiOutlinedInputRoot': {
    '&.Mui-focused fieldset': {
      borderColor: '#fff',
    },
  },
};

interface Props {
  className: string;
  value: any;
  onChange: any;
}

const JsonInput: FunctionComponent<Props> = (props) => {
  let inputBackgroundColor: string;

  if (props.className === 'read_only_input') {
    inputBackgroundColor = '#dddddd';
  } else {
    inputBackgroundColor = '#f4f2ff';
  }

  return (
    <FormControl sx={{ ...containerStyle }}>
      <TextareaAutosize
        aria-label='minimum height'
        cols={50}
        minRows={40}
        onChange={props.onChange}
        placeholder='Write'
        style={{ ...inputStyle, backgroundColor: inputBackgroundColor }}
        value={props.value}
      />
    </FormControl>
  );
};

export default JsonInput;
