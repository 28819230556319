import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Auth0Provider } from '@auth0/auth0-react';

interface Props {
  children: React.ReactNode;
}

export const domain: string = process.env.REACT_APP_AUTH0_DOMAIN
  ? process.env.REACT_APP_AUTH0_DOMAIN
  : '';
export const audience: string = process.env.REACT_APP_AUTH0_AUDIENCE
  ? process.env.REACT_APP_AUTH0_AUDIENCE
  : '';
export const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID
  ? process.env.REACT_APP_AUTH0_CLIENT_ID
  : '';
export const scope: string = process.env.REACT_APP_AUTH0_SCOPE
  ? process.env.REACT_APP_AUTH0_SCOPE
  : '';

const Auth0ProviderWithHistory: FunctionComponent<Props> = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  };

  return (
    <Auth0Provider
      audience={audience}
      clientId={clientId}
      domain={domain}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
      scope={scope}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
