import React, { FunctionComponent } from "react";
import "./TableColumn.css";

interface Props {
  columnName: string;
}

const TableColumn: FunctionComponent<Props> = (props) => (
    <div className="column">
      <p className="column_text">{props.columnName}</p>
    </div>
  );

export default TableColumn;
