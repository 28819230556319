import React, { FunctionComponent } from "react";

import "./Welcome.css";

interface Props {
  image: any;
  title: string;
  description: string;
}

const Welcome: FunctionComponent<Props> = (props) => (
    <div className="welcome_page">
      <h1 className="welcome_title">{`Welcome to ${props.title}`}</h1>
      <div className="welcome_text_container">
        <p className="welcome_text">{props.description}</p>
      </div>
      <div className="welcome_img_container">
        <img alt="search" className="filter_warning_img" src={props.image} />
      </div>
    </div>
  );

export default Welcome;
